import React from 'react';
import { MdDateRange } from 'react-icons/md';
import { useMobileScreen } from '../../hooks/useMobileScreen';

import './community-card.styles.scss';

interface props {
    eventData: any,
}

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

const CommunityCard = ({eventData}: props) => {

    const mobileScreen = useMobileScreen();

    let urlImg = 'https://cdn.bemaster.com/media/images/community/event-'+eventData.idAd+'.png';

    if (mobileScreen) {
        urlImg = 'https://cdn.bemaster.com/media/images/community/event-'+eventData.idAd+'-sm.png';
    }

    let dateEvent: Date = new Date(eventData.date);
    let date = dateEvent ? dateEvent.getDate() + " de " + monthNames[dateEvent.getMonth()] : "";

    const handleEventClick = (url: string, target: string) => {
        if (url !== null && url !== "#" && url !== "") {            
            window.open(url, target);
        }
    }

    return (
        <div className={`event-card-container my-2`} style={{backgroundImage: `url(${urlImg})`}}
            onClick={() => handleEventClick(eventData.url, eventData.targetUrl)} >
            <div className={`is-date-area junior`} style={{display: 'none'}}>
                <div className="date-area is-size-5">
                    {date}
                    <div className="is-icon-area">
                        <MdDateRange />
                    </div>
                </div>
            </div>
        </div> 
    );
};

export default CommunityCard;