import React from 'react';

import './class-button.styles.scss';

interface props {
    height?: number,
    width?: number,
    text: string,
    colorButton?: string,
    onClick?: React.MouseEventHandler,
    marginTop?: number,
    fontSize?: number,
    disabled?: boolean,
}

const ClassButton = ({height=25, width, text, fontSize=7, colorButton='green-petroleum', marginTop=30, onClick, disabled}: props) => {
    return (
        <button
            className={`button class-button has-text-weight-bold ${colorButton}`}
            style={{height: height, width: width, marginTop: marginTop}} onClick={onClick} disabled={disabled}>
            <h1 className={`has-text-white has-text-weight-bold is-size-${fontSize}`}>{text.toLocaleUpperCase()}</h1>
        </button>
    );
};

export default ClassButton;