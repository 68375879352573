import React from 'react';
import Triumph from '../triumph/triumph.component';

import './award-card.styles.scss';

interface props {
    awards: string[]
}

const AwardCard = ({ awards }: props) => {
    
    return (
        <div className="awards-container ml-4 is-mobile">
            {awards
            .sort((a: any,b: any) => { return (a.earned === b.earned) ? 0 : a.earned ? -1 : 1;  })
            .map((item: any, i) => {
                return (
                    <div className="award-card is-mobile mr-5 mb-3 p-5" key={i}>
                        <div className="icon-award is-flex">
                            <Triumph tData={{ idAward: item.idAward, earned: item.earned }} />
                        </div>
                        <div className="award-content">
                            <div className="name-award mb-2">
                                <h1>{item.name}</h1>
                            </div>
                            <div className="description-award">
                                <h2 className="is-size-6">{item.description}</h2>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default AwardCard;