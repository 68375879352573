import React, { useContext } from 'react';
import { AppContext } from '../../../../context/context';

import './black-logo.styles.scss';

export const BlackLogo = () => {
    
    const appContext = useContext(AppContext);
    const infoCareer: any = appContext.loginInfo.infoCareers;
    const careerId = infoCareer.careerList[infoCareer.selectedCareer].idCareer;
    const eMMCareerId = 3;

    let BlackLogo = 'https://cdn.bemaster.com/media/images/login/Logo-BeMaster.png';
    let EliteMasterMind = 'https://cdn.bemaster.com/media/images/classroom/elite-mastermind-logo.png';

    return (
        <div>
            <figure className={ 'is-black-logo' }>
                <img src={ careerId===eMMCareerId? EliteMasterMind : BlackLogo } alt="is-black-logo" />
            </figure>
        </div>
    );
};