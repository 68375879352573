import { useState } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import toast from "react-hot-toast";

import "./popup.styles.scss";
import ButtonPersonalized from "../../../shared/components/buttons/button-personalized/button-personalized.component";
import AxiosConfig from "../../../axios/axiosConfig";
import {
  errorToast,
  successToast,
  validatePassword,
  warningToast,
} from "../../../shared/helpers";

type props = {
  closeModal: Function;
};

interface Form {
  password: string;
  confirmPassword: string;
}

export const Popup = ({ closeModal }: props) => {
  const [form, setForm] = useState<Form>({
    confirmPassword: "",
    password: "",
  });
  const [open, setOpen] = useState(true);

  const close = () => {
    setOpen(false);
    closeModal();
  };

  const submit = async () => {
    const passwordValidation = validatePassword(form.password.trim());
    if (passwordValidation !== "") {
      return toast.error(passwordValidation);
    }
    if (form.confirmPassword.trim() !== form.password.trim()) {
      return toast.error("Las contraseñas no coinciden");
    }

    const response = await AxiosConfig.post(
      "/Career/ActivateMastertoolsAccount",
      {
        strParam: form.password,
      }
    );

    if (response.data.result === "success") {
      if (
        response.data.message === "Ya tienes una cuenta activa de Mastertools"
      ) {
        warningToast(response.data.message);
      } else if (response.data.message === "No tienes acceso a esta oferta") {
        errorToast(response.data.message);
      } else {
        successToast(response.data.message);
      }
      window.open(response.data.data, "_blank");
    } else {
      errorToast("Ha sucedido un error, por favor intente más tarde");
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="modal-container">
      <Modal isOpen={open} portalClassName="modal-container">
        <div className="close-button">
          <MdClose onClick={close} />
        </div>
        <div className="title">Crea tu cuenta de Mastertools</div>
        <div className="mastertools-form">
          <label>Contraseña:</label>
          <input
            type="password"
            placeholder="Ingresa tu contraseña"
            name="password"
            onChange={onChange}
          />
          <label>Confirma tu contraseña:</label>
          <input
            type="password"
            placeholder="Confirma tu contraseña"
            name="confirmPassword"
            onChange={onChange}
          />
          <div className="button-container">
            <ButtonPersonalized
              label="Crear cuenta"
              onClick={submit}
              width="100%"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
