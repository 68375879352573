import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from "../../axios/axiosConfig";
import CustomToggle from "../custom-checkbok/custom-toggle.component";

import "./preferences.styles.scss";

type props = {
  updatePreferenceState: (preference: any) => void;
  preferences: any;
};

const Preferences = ({ updatePreferenceState, preferences }: props) => {
  const [preference, setPreference] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    let data = { ...preferences };
    setPreference(preferences);
    return () => {
      isSubscribed = false;
    };
  }, [preferences]);

  const toggleCheck = async (i: number, active: boolean) => {
    let data: any = [...preference];
    data[i].userPreference = [{ active: active }];
    setPreference(data);

    if (i == 2) {
      document.documentElement.className = "";
      document.documentElement.classList.add(
        `theme-${active ? "dark" : "light"}`
      );
    }
    await AxiosConfig.put("/Account/ChangePreference", {
      id: data[i].idPreference,
      active,
    })
      .then((response) => {
        if (response.data.result === "success") {
          updatePreferenceState(data);
          toast.success("Se ha guardado sus preferencias correctamente", {
            style: {
              color: "#966c2e",
            },
            position: "bottom-left",
          });
        } else if (response.data.result === "error") {
          toast.error("Ha ocurrido un error al guardar sus preferencias", {
            duration: 6000,
            style: {
              color: "#966c2e",
            },
            position: "bottom-left",
          });
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error, intente mas tarde", {
          duration: 6000,
          style: {
            color: "#966c2e",
          },
          position: "bottom-left",
        });
      });
  };

  return (
    <div className="preferences">
      <Scrollbar style={{ height: 470, width: "113%" }}>
        <div className="top-section pb-5">
          {preference.map((item: any, i: number) => {
            let check =
              item.userPreference.length === 0
                ? item.defaultChecked
                : item.userPreference[0].active;

            return (
              <div key={item.idPreference} className="check-div mb-2 px-2">
                <div className="custom-toggle is-flex mr-4">
                  <CustomToggle
                    id={item.idPreference}
                    checked={check}
                    onChange={() => toggleCheck(i, !check)}
                  />
                </div>
                <div className="text">
                  <h1>{item.description}</h1>
                </div>
              </div>
            );
          })}
        </div>

        <div className="p-bottom-section pt-4">
          <div className="data mb-2">
            <h3 className="is-size-3"> POLÍTICAS </h3>
          </div>
          <div className="preferences">
            <div className="top-section pb-5">
              <div className="check-div mb-2 px-2">
                <div className="custom-toggle is-flex mr-4">
                  <CustomToggle id="010" checked={true} disabled={true} />
                </div>
                <div className="link">
                  <h1>
                    Acepto las{" "}
                    <a
                      href="https://bemaster.com/politica-privacidad"
                      target="_blank"
                    >
                      Políticas de Privacidad
                    </a>
                  </h1>
                </div>
              </div>
              <div className="check-div mb-2 px-2">
                <div className="custom-toggle is-flex mr-4">
                  <CustomToggle id="011" checked={true} disabled={true} />
                </div>
                <div className="link">
                  <h1>
                    Acepto los{" "}
                    <a href="https://bemaster.com/politica-uso" target="_blank">
                      Términos de Uso
                    </a>
                  </h1>
                </div>
              </div>
              <div className="terms-div">
                <h1>
                  <a
                    href="https://bemaster.com/politica-servicio"
                    target="_blank"
                  >
                    Términos de Servicio
                  </a>{" "}
                  -{" "}
                  <a
                    href="https://bemaster.com/politica-notificaciones"
                    target="_blank"
                  >
                    Políticas de Notificaciones
                  </a>{" "}
                  -{" "}
                  <a href="#" target="_blank">
                    Políticas de API
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};

export default Preferences;
