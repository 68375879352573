export const BalanceAuthorization = (path: string) => {
  return (
    path == "/shopping" ||
    path === "/home" ||
    path == "/mentortalks" ||
    path == "/powerclass" ||
    path == "/mentortalks/:id" ||
    path == "/powerclass/:id" ||
    path == "/firesShop"
  );
};
