import { useEffect, useState, useContext } from "react";
import Scrollbar from "react-scrollbars-custom";
import ButtonLogoDefault from "../../shared/components/buttons/button-default/button-logo-default.component";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import { AdContent } from "../../interfaces/adContent";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import "./news.styles.scss";

const News = () => {
  const mobileScreen = useMobileScreen();

  const [news, setNews] = useState<AdContent[]>([]);
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idCareer = career.career.idCareer;

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      await AxiosConfig.get("/Post/GetCareerNews/" + idCareer).then(
        (result) => {
          if (isSubscribed) {
            if (result.data.result === "success") {
              setNews(result.data.data.listCareerNews);
            }
          }
        }
      );
    };

    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, [idCareer]);

  const handleNotificationClick = (url: string, target: string) => {
    if (url !== null && url !== "#" && url !== "") {
      window.open(url, target);
    }
  };

  const getTime = (date: Date) => {
    let d = new Date(date);
    return d != null ? d.valueOf() : 0;
  };
  return (
    <div className="is-news-section">
      <div className="news-header center">
        <h1
          className="has-text-white has-text-weight-bold is-size-5"
          style={{ letterSpacing: 5 }}
        >
          NOTICIAS
        </h1>
      </div>
      <Scrollbar style={{ height: 390 }}>
        <div className="news-cards-container">
          {news.length > 0 ?
          news
            .sort((a: any, b: any) => getTime(a.date) - getTime(b.date))
            .sort((a: AdContent, b: AdContent) => a.order - b.order)
            .map((item: any, i) => {
              let urlImg =
                "https://cdn.bemaster.com/media/images/home/New-" +
                item.idAd +
                ".png";

              if (mobileScreen) {
                urlImg =
                  "https://cdn.bemaster.com/media/images/home/New-" +
                  item.idAd +
                  "-sm.png";
              }
              return (
                <div key={i} className="news-card-section mt-4 pb-4">
                  <div className="news-card md-3">
                    <div
                      className="is-card-container"
                      style={{ backgroundImage: `url(${urlImg})` }}
                    >
                      <ButtonLogoDefault
                        label={"Saber más"}
                        buttonWidth={"110px"}
                        isBold={true}
                        handleClick={() =>
                          handleNotificationClick(item.url, item.targetUrl)
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })
              :
              <p className="news-no-content">No hay noticias en el momento.</p> 
            }
        </div>
      </Scrollbar>
    </div>
  );
};

export default News;
