import React, { useEffect, useState } from "react";
import { ReactComponent as Fire } from "../../assets/images/profile/fire.svg";
import Modal from "react-modal";
import AxiosConfig from "../../axios/axiosConfig";
import { useHistory } from "react-router-dom";
import ButtonPersonalized from "../../shared/components/buttons/button-personalized/button-personalized.component";
import { MdClose } from "react-icons/md";
import { GlobalStyles } from "../../shared/global-styles/global-styles";
import "./power-purchase.styles.scss";
import toast from "react-hot-toast";
import { config } from "../../config";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";

interface props {
  powerId?: string | number;
  showModal?: boolean;
  onClick: (active: boolean) => void;
  listContent: any;
  balance: number;
  updateContentBought: (bought: boolean) => void;
  idCareer?: number;
  idContentCategory: number;
  video: boolean;
}

const PowerPurchase = ({
  powerId = "",
  showModal = false,
  onClick,
  listContent,
  balance,
  updateContentBought,
  idCareer,
  idContentCategory,
  video,
}: props) => {
  const [visible, setVisible] = useState(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [urlVideo, setUrlVideo] = useState<string>();
  const [localVideo, setLocalVideo] = useState<boolean>();

  const history = useHistory();
  const mobileScreen = useMobileScreen();

  const urlImage =
    "https://cdn.bemaster.com/media/images/powerclasses/pclass0" +
    powerId +
    ".png";

  useEffect(() => {
    setVisible(showModal);
    (async () => {
      const response = await fetch(
        `${config.url.videos.modal_pclass}${idContentCategory}.mp4`
      );
      if (response.status === 200) {
        setUrlVideo(
          `${config.url.videos.modal_pclass}${idContentCategory}.mp4`
        );
        setLocalVideo(true);
      } else {
        setLocalVideo(false);
      }
    })();
  }, [showModal]);

  const handleExit = () => {
    onClick(!visible);
    setVisible(!visible);
  };

  const handleButtonClick = async (buyFires: boolean) => {
    if (buyFires) {
      history.push("/firesShop/");
    } else {
      setDisableButton(true);
      await AxiosConfig.post("/Content/BuyContentCategory/", {
        idContentCategory: idContentCategory,
        idCareer: idCareer,
      })
        .then((result) => {
          if (result.data.result == "success") {
            setVisible(!visible);
            setDisableButton(false);
            updateContentBought(true);
            toast.success("¡Compra realizada con éxito!", {
              style: {
                color: "#966c2e",
              },
              position: "bottom-right",
            });
            history.push(`/video?id=${powerId}`);
          }
          if (result.data.result == "error") {
            toast.error("Ha ocurrido un problema al realizar la compra", {
              duration: 6000,
              style: {
                color: "#966c2e",
              },
              position: "bottom-right",
            });
            setDisableButton(false);
          }
        })
        .catch((e) => {
          setDisableButton(false);
        });
    }
  };

  return (
    <div className="dark-layer">
      <Modal isOpen={visible} className={"purchase-modal"}>
        {listContent
          .filter(function (content: any) {
            return content.idContent === powerId;
          })
          .map((data: any, i: number) => {
            return (
              <div className="power-purchase-container" key={i}>
                <span
                  className="close-icon exit-button-section"
                  onClick={handleExit}
                >
                  <MdClose />
                </span>
                <div className="modal-content">
                  {urlVideo ? (
                    <div className="video-space-section">
                      <video src={urlVideo} autoPlay loop></video>
                    </div>
                  ) : urlImage ? (
                    <div
                      className="img-space-section"
                      style={{ backgroundImage: `url(${urlImage})` }}
                    />
                  ) : (
                    <div className="bemaster-loader-container">
                      <img src={BeMasterLoader} alt="Loader" />
                    </div>
                  )}

                  <div className="power-purchase-resume">
                    <div
                      className="power-title mt-2"
                      style={{ display: mobileScreen ? "flex" : "none" }}
                    >
                      <h1>{data.name.toLocaleUpperCase()}</h1>
                    </div>
                    <div className="purchase-details mt-3">
                      <h2
                        style={{
                          color:
                            balance < data.value ? GlobalStyles.colors.red : "",
                        }}
                      >
                        {balance >= data.value
                          ? "VER ESTE CONTENIDO POR:"
                          : "SALDO INSUFICIENTE"}
                      </h2>
                      <div className="price-mini-region">
                        <div className="p-details">
                          <Fire width={80} />
                          <h1
                            className="is-size-3 price"
                            style={{
                              color: balance < data.value ? "#b73f39" : "",
                            }}
                          >
                            {data.value}
                          </h1>
                        </div>
                        <ButtonPersonalized
                          label={
                            balance >= data.value
                              ? "CONFIRMAR"
                              : "COMPRAR FUEGOS"
                          }
                          width={balance >= data.value ? 150 : 210}
                          fontSize={20}
                          onClick={() =>
                            handleButtonClick(balance < data.value)
                          }
                          disabled={disableButton}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Modal>
    </div>
  );
};

export default PowerPurchase;
