import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';

import './shop-modal.styles.scss';

interface props {
    showModal: boolean,
    onClick: (idProduct: number) => void
}

const ShopModal = ({showModal, onClick}: props) => {

    const [shopModal, setShopModal] = useState(true);

    useEffect(()=> {
        setShopModal(showModal);
    },[showModal])

    const handleModalBehavior = () => {
        setShopModal(!shopModal);
    }

    return (
        <div className="shop-modal">
            <Modal isOpen={shopModal} portalClassName={'shop-modal'} ariaHideApp={false}>
                <div className="close-button" onClick={handleModalBehavior}>
                    <MdClose onClick={() => onClick(0)} />
                </div>
                <h1 className="shop-text is-size-3">PRÓXIMAMENTE</h1>
                <h1 className="content-text is-size-5 mb-5">PODRÁS COMPRAR ESTE ARTÍCULO</h1>
            </Modal>
        </div>
    );
};

export default ShopModal;