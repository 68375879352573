import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import AxiosConfig from '../../axios/axiosConfig';
import ClassButton from '../../shared/components/class-button/class-button.component';
import FireRecords from '../fire-records/fire-records.component';
import BeMasterLoader from '../../assets/gifs/loader/bemaster_loader.gif'
import './fires.styles.scss';


const Fires = () => {

    // This must be get it from fetch request
    const [balance, setBalance] = useState(0);
    const [histTransaction, setHistTransaction] = useState([]);
    let history = useHistory();

    useEffect(() => {
        let isSubscribed = true
        const fetchData = () => {
            AxiosConfig.get('/Account/GetFiresTransactionsUser').then(result => {
                if (isSubscribed) {
                    if (result.data.result === "success") {
                        setHistTransaction(result.data.data.listTransactions);
                        setBalance(result.data.data.balance);
                    }
                }
            });
        };
        fetchData();
        return () => { isSubscribed = false }
    }, []);

    const getTime = (date: Date) => {
        let d = new Date(date);
        return d != null ? d.getTime() : 0;
    }

    const handleBuyFires = () => {
        history.push('/firesShop/');
    }


    let flame1 = 'https://cdn.bemaster.com/media/images/profile/flame.svg';
    return (
        <div className="main-fires-container">
            <div className="top-fires-container mb-3 pl-6">
                <div className="left-top-elements">
                    <div className="fires-icon mr-2" >
                        <figure className="image">
                            <img
                                src={flame1}
                                alt="Flame"
                                className="image-this" />
                        </figure>
                    </div>
                    <div className="titles-top-fires">
                        <div className="fires-top-title">
                            <h1 className="is-size-4">FUEGOS</h1>
                        </div>
                        <div className="balance">
                            <h1 className="is-size-6">MI SALDO: </h1>
                            <h1 className="is-size-4">{new Intl.NumberFormat("en-IN").format(balance)}</h1>
                        </div>
                    </div>
                </div>
                <div className="right-top-button">
                    <ClassButton text={'COMPRAR FUEGOS'} fontSize={6} colorButton={'red'} height={40} width={210} onClick={() => handleBuyFires()} />
                </div>
            </div>
            <div className="movements-section pt-4">
                <div className="has-bottom-movements">
                    <div className="title-top-section is-flex mb-2">
                        <h1 className="historical is-size-6">HISTORIAL</h1>
                    </div>
                    <div className="movements-block">
                        {/* height: 390,  */}
                        <Scrollbar style={{ width: '103%' }} height={'100%'}>
                            {histTransaction.length > 0 ?
                            histTransaction
                                .sort((a: any, b: any) => getTime(b.date) - getTime(a.date))
                                .map((record: any, i) => {
                                    return (
                                        <div className="" key={i}>
                                            <FireRecords historical={record} />
                                        </div>
                                    )
                                })
                                :
                                <div style={{margin:"0 auto", width:"150px", paddingTop:"30px"}}>
                                    <img src={BeMasterLoader} alt="" />
                                </div>
                            }
                        </Scrollbar>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default Fires;