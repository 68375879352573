import { useContext } from "react";
import { CareerContext } from "../context/careerContext";

const useCareerMissionState = () => {
    const careerContext = useContext(CareerContext);
    const careerData = careerContext.careerInfo.missionsCompleted;
    
    return careerData;
}

export { useCareerMissionState };