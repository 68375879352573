import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import "./input-password.styles.scss";

type props = {
  placeholder: string;
  onChange?: React.ChangeEventHandler;
  keyPressed?: (keyPressed: any) => void;
  required?: boolean;
  value?: any;
  style?: React.CSSProperties;
  theme?: "dark" | "light";
};

export const InputPassword = ({
  placeholder,
  value,
  style,
  required,
  theme = "dark",
  onChange,
  keyPressed,
}: props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");

  const changeVisibilityHandler = () => {
    if (showPassword) {
      setInputType("password");
      return setShowPassword(false);
    }
    setInputType("text");
    setShowPassword(true);
  };

  return (
    <div
      className={`input-password-container ${theme}`}
      // style={{
      //   backgroundColor: theme === "dark" ? "white" : "rgb(240,240,240)",
      // }}
    >
      <input
        type={inputType}
        placeholder={placeholder}
        value={value}
        style={style}
        required={required}
        onChange={onChange}
        onKeyPress={keyPressed}
      />
      {showPassword ? (
        <AiOutlineEyeInvisible
          className="icon"
          onClick={changeVisibilityHandler}
          size={24}
        />
      ) : (
        <AiOutlineEye
          className="icon"
          onClick={changeVisibilityHandler}
          size={24}
        />
      )}
    </div>
  );
};
