import React, { useEffect, useState } from "react";

interface props {
  isVideoChild: boolean;
  src: string;
  video: any;
  autoplay?: boolean;
  resolution: number;
  setResolutions: Function;
  startTime: number;
  updateProgressVideo: Function;
  progress?: number;
}

const HlsSource = ({
  isVideoChild,
  src,
  video,
  autoplay = true,
  resolution,
  setResolutions,
  startTime = 0,
  updateProgressVideo,
  progress,
}: props) => {
  const [progressLoaded, setProgressLoaded] = useState(false);

  const hls = new (window as any).Hls();

  const destroyHLS = () => {
    (window as any).hls.stopLoad();
    (window as any).hls.detachMedia();
    (window as any).hls.destroy();
  };

  useEffect(() => {
    return () => {
      let percent = (video.currentTime * 100) / video.duration;
      updateProgressVideo(percent);
    };
  }, [video]);

  useEffect(() => {
    if (
      !isNaN(video.duration) &&
      progress &&
      !progressLoaded &&
      progress < 95
    ) {
      return () => {
        setProgressLoaded(true);
        video.currentTime = (video.duration * progress) / 100;
      };
    }
  });

  useEffect(() => {
    let ignore = false;

    if ((window as any).Hls.isSupported()) {
      if ((window as any).hls) destroyHLS();

      hls.attachMedia(video);

      hls.on(
        (window as any).Hls.Events.MEDIA_ATTACHED,
        function (event: any, data: any) {
          hls.loadSource(src);
          hls.on(
            (window as any).Hls.Events.MANIFEST_PARSED,
            (event: any, data: any) => {
              const { levels } = data;
              setResolutions(levels);

              if (autoplay) {
                video.play();
              }
              video.currentTime = startTime;
              hls.currentLevel = resolution;
            }
          );
        }
      );

      hls.on(
        (window as any).Hls.Events.ERROR,
        function (event: any, data: any) {
          if (data.fatal) {
            switch (data.type) {
              case (window as any).Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error
                console.log("fatal network error encountered, try to recover");
                //hls.startLoad();
                break;
              case (window as any).Hls.ErrorTypes.MEDIA_ERROR:
                console.log("fatal media error encountered, try to recover");
                //hls.recoverMediaError();
                break;
              default:
                // cannot recover
                hls.destroy();
                break;
            }
          }
        }
      );
    }

    if (ignore) video.destroy();

    return () => {
      ignore = true;
    };
  }, [src, resolution]);

  useEffect(() => {
    return () => {
      hls.destroy();
    };
  }, [src]);

  return (
    <source
      src={src}
      // type={'application/dash+xml'}
      type={"application/x-mpegURL"}
    />
  );
};

export default HlsSource;
