import AxiosConfig from "../../axios/axiosConfig";

export interface IBannersData {
    careerId: number;
    bannersUrl: string[];
    bannersDesktopImages: string[];
    bannersMobileImages: string[];
}

export const getBanners = async () => {
  const {data} = await AxiosConfig.get("https://cdn.bemaster.com/media/files/bannersData/banners.json");
  return data;
}