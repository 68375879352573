import React, { Context, useReducer, useState } from "react";
import { createContext } from "react";

export interface CareerInfo {
  careerProgress: [
    {
      careerId: number | null;
      careerProgress: number | null;
    }
  ];
  missionsCompleted: boolean
}

export const CAREER_INFO: CareerInfo = {
  careerProgress: [
    {
      careerId: null,
      careerProgress: null,
    },
  ],
  missionsCompleted: false,
};

interface ICareerContext {
  careerInfo: CareerInfo;
  dispatchCareerInfo: React.Dispatch<any>;
}

export const CareerReducer = (
  careerInfo: CareerInfo,
  newCareerInfo: CareerInfo
) => {
  return {
    ...careerInfo,
    ...newCareerInfo,
  };
};

export const CareerContext: Context<ICareerContext> = createContext({
  careerInfo: CAREER_INFO,
} as ICareerContext);
