import axios from "axios";

export const handleMttoolsTrial = async (userId: number, careerId: number) => {
  return await masterToolsApi(userId, careerId);
};

export const masterToolsApi = async (userId: number, careerId: number) => {
  const response = await axios
    .post(
      `${process.env.REACT_APP_URL_API_NEW}/career/activateMttoolsAccount`,
      {
        idUser: userId,
        idCareer: careerId,
      }
    )
    .catch((err) => {
      if (err.response) {
        return err.response.status;
      }
    });

  return response;
};
