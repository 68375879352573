import { floatSidebarData } from "./float-sidebar-data";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/context";
import "./float-sidebar.styles.scss";

const FloatSidebar = () => {
  const appContext = useContext(AppContext);
  const [showFloatSidebar, setShowFloatSidebar] = useState<boolean>();
  const [floatSidebarFilteredData, setFloatSidebarFilteredData] =
    useState<any[]>();
  const baseIconsUrl = config.url.images.homeIcons;
  let history = useHistory();
  
  const infoCareer = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  
  useEffect(() => {
    let shapedData = [];
    
    //conditioning sidebarData by career id
    if (career.idCareer !== 11 && career.idCareer !== 12) {
      shapedData = [...floatSidebarData];
      shapedData.splice(1, 2);
      shapedData.pop();
      setFloatSidebarFilteredData(shapedData);
    } else {
      setFloatSidebarFilteredData([...floatSidebarData]);
    }
  }, [career.idCareer, career.statusCert]);

  return (
    <div
      className="main-float-sidebar-container"
      onMouseLeave={() => setShowFloatSidebar(false)}
    >
      <div
        className="float-sidebar-container"
        style={{ marginLeft: `${showFloatSidebar ? "15px" : "-100px"}` }}
      >
        {floatSidebarFilteredData?.map((item: any, i: number) => {
          return (
            <div
              className="float-sidebar-icon"
              key={i}
              onClick={() => history.push(item.url)}
            >
              <img src={`${baseIconsUrl}${item.icon}`} alt="" />
            </div>
          );
        })}
      </div>
      <div
        className={`trigger-sidebar-container ${
          showFloatSidebar ? "hidden" : ""
        }`}
        onMouseEnter={() => setShowFloatSidebar(true)}
        style={{
          left: `${showFloatSidebar ? "-100px" : "0"}`,
        }}
      >
        <IoIosArrowForward color="white" size={25} />
      </div>
    </div>
  );
};

export default FloatSidebar;
