import toast, { ToastPosition } from "react-hot-toast";
import { config } from "../../config";

export const formatInt = (int: number | string) => {
  if (typeof int === "string") {
    int = parseFloat(int);
  }
  const numberFormat = new Intl.NumberFormat('en-US');
  return numberFormat.format(int);
}

export const intApproach = (int: number) => {

  if ((int+"").length < 4) {
    int += 600;
    return int+"";
  }

  let size = "K";
  return parseFloat((int/1000).toFixed(1))+size;
}

export const validatePassword = (password: string) => {

  if (password === "") {
    return "Debe ingresar una contraseña";
  }
  if (password.length < 9) {
    return "Su contraseña debe tener al menos 8 caracteres";
  }
  if (!config.regex.hasLowercase.test(password)) {
    return "Su contraseña debe tener al menos una minúscula";
  }
  if (!config.regex.hasUppercase.test(password)) {
    return "Su contraseña debe tener al menos una mayúscula";
  }
  if (!config.regex.hasNumber.test(password)) {
    return "Su contraseña debe tener al menos un número";
  }

  return "";
}

type options = {
  duration?: number;
  position?: ToastPosition;
};

export const successToast = (message: string, options?: options) => {
  toast.success(
    message,
    {
      duration: options?.duration || 3000,
      position: options?.position || "bottom-left",
      style: {
        color: "#966c2e"
      }
    }
  );
};

export const errorToast = (message: string, options?: options) => {
  toast.error(
    message,
    {
      duration: options?.duration || 5000,
      position: options?.position || "bottom-left",
      style: {
        color: "#966c2e"
      }
    }
  );
};

export const warningToast = (message: string, options?: options) => {
  toast.success(message , {
    icon: "⚠️",
    duration: options?.duration || 5000,
    position: options?.position || "bottom-left",
    style: {
      color: "#966c2e"
    }
  })
}