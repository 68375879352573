import { useState, useEffect } from "react";
import AxiosConfig from "../../axios/axiosConfig";
import { BsFillHeartFill } from "react-icons/bs";
import ReactStars from "react-stars";

import "./rating-video.styles.scss";
import { AiFillPlayCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { errorToast, successToast } from "../../shared/helpers";
import { UserContent } from "../../interfaces/userContent";
import { Video } from "../../interfaces/video";

interface props {
  visible: boolean;
  video: Video;
  nextVideoName: string;
}

type LocationState = {
  from?: string;
};

const RatingVideo = ({ visible, video, nextVideoName }: props) => {
  const content = video.userContent[0];

  const history = useHistory<LocationState>();
  const fromClassroom = history.location.state?.from === "classroom";
  const [favorite, setFavorite] = useState(
    content ? content.isFavourite === 1 : false
  );
  let rating = content ? content.reviewContent : 0;

  const handleFav = async () => {
    const message = !favorite
      ? "Se guardó como favoritos correctamente"
      : "Se ha eliminado de favoritos correctamente";
    setFavorite(!favorite);
    try {
      await AxiosConfig.post("/Content/SetFavoriteContent", {
        id: video.idContent,
        boolParam: !favorite,
      });
      successToast(message, {
        position: "top-center",
      });
    } catch (error) {
      errorToast(
        "No se pudo guardar como favorito, por favor intenta más tarde",
        {
          position: "top-center",
        }
      );
    }
  };

  const ratingChanged = async (newValue: any) => {
    try {
      await AxiosConfig.post("/Content/SetReviewContent", {
        listInts: [video.idContent, newValue],
      });
      successToast("¡Gracias por tu calificación!", {
        position: "top-center",
      });
    } catch (error) {
      errorToast(
        "No se pudo guardar tu calificación, por favor intenta más tarde",
        {
          position: "top-center",
        }
      );
    }
  };
  const [RatingVisibility, setRatingVisibility] = useState(true);

  useEffect(() => {
    setRatingVisibility(visible);
  }, [visible]);

  const handleNextVideo = () => {
    if (video.idNextVideo) {
      window.location.replace(`/video?id=${video.idNextVideo}`);
    }
  };

  const returnToClassroom = () => {
    history.push("/classroom");
  };

  return (
    <div
      className="main-rating-container"
      style={{ display: RatingVisibility ? "block" : "none" }}
    >
      <div className="is-rating-section">
        <h1 className="has-text-white is-size-4">CALIFICA ESTE VIDEO</h1>
        <ReactStars
          count={5}
          value={rating}
          onChange={(newValue) => {
            ratingChanged(newValue);
          }}
          size={40}
          half={false}
          color2={"#c19656"}
        />
        <div className="next-video">
          <div className="favorite-mini-section">
            <h1 onClick={handleFav}>+ Agrega a favoritos</h1>
            <BsFillHeartFill
              className="favorite-icon"
              onClick={handleFav}
              style={{ color: favorite ? "red" : "white" }}
            />
          </div>
          {fromClassroom ? (
            <>
              <div className="next-video-title" onClick={returnToClassroom}>
                Volver al classroom
              </div>
              <div
                className="return-classroom"
                onClick={returnToClassroom}
                style={{
                  backgroundImage:
                    "url('https://cdn.bemaster.com/media/icons/home/Sitiobemaster36.svg')",
                }}
              ></div>
            </>
          ) : (
            <>
              <div className="next-video-title" onClick={handleNextVideo}>
                {nextVideoName}
              </div>
              <div className="next-subtitle">
                <span>Siguiente video</span>
              </div>
              {/* <div className="autoplay-animation">
                  <AiFillPlayCircle />
                </div> */}
              <AiFillPlayCircle
                className="next-video-icon"
                color="white"
                size={64}
                onClick={handleNextVideo}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingVideo;
