import Quiz from "../media-resources/quiz.component";
import Workbook from "../media-resources/workbook.component";
import VideoContainer from "../video-class-container/video-container.component";
import CommunityMessage from "../community-message/community-message.component";
import LevelFinishedMessage from "../level-finished-message/level-finished-message.component";

type props = {
  mission: any;
  indexMission: number;
  changeCheckedMission: (mission: any, index: number) => void;
  idCareerModule: number;
  handleLoader: (state: boolean) => void;
};

const InternalClassroomLayout = ({
  mission,
  indexMission,
  changeCheckedMission,
  idCareerModule,
  handleLoader,
}: props) => {
  switch (mission.type) {
    case "Video":
      return (
        <div className="component-section mx-5">
          <VideoContainer
            mission={mission}
            indexMission={indexMission}
            changeCheckedMission={changeCheckedMission}
            idCareerModule={idCareerModule}
          />
        </div>
      );
      break;
    case "Workbook":
      return (
        <div className="component-section mr-5">
          <Workbook
            mission={mission}
            indexMission={indexMission}
            changeCheckedMission={changeCheckedMission}
            idCareerModule={idCareerModule}
            handleLoader={handleLoader}
          />
        </div>
      );
      break;
    case "Quiz":
      return (
        <div className="component-section mr-5">
          <Quiz
            mission={mission}
            indexMission={indexMission}
            changeCheckedMission={changeCheckedMission}
            idCareerModule={idCareerModule}
            handleLoader={handleLoader}
          />
        </div>
      );
      break;
    case "Comment":
      return (
        <div className="component-section mr-5">
          {/* <LevelFinishedMessage mission={mission} />                */}
          <CommunityMessage mission={mission} />
        </div>
      );
      break;
    case "Certification":
      return (
        <div className="component-section mr-5">
          <LevelFinishedMessage mission={mission} />
        </div>
      );
      break;

    default:
      return <div className="component-section mr-5"></div>;
      break;
  }
};

export default InternalClassroomLayout;
