import React, { useEffect, useState } from 'react';
import NotiCard from '../auth/components/notification-card/noti-card.component';
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from '../../axios/axiosConfig';
import toast from 'react-hot-toast';

import './notification-container.styles.scss';

type props = {
    allowShowComp?: Boolean,
    showNewNot: (show: boolean) => void,
    notAmount: (i: number) => void
}

const NotificationContainer = ({ allowShowComp = false, showNewNot, notAmount }: props) => {

    const [notifications, setNotifications] = useState([]);
    const [showed, setShowed] = useState(allowShowComp);

    useEffect(() => {

        let isSubscribed = true;

        const fetchData = async () => {
            await AxiosConfig.get('/Notification').then(result => {
                if (isSubscribed) {
                    if (result.data.result == "success") {
                        setNotifications(result.data.data.listUserNotification);
                        notAmount(result.data.data.listUserNotification.length);

                        let pendingNotifications = result.data.data.listUserNotification.filter((item:any) => item.viewed === 0)

                        pendingNotifications.length !== 0 && toast.success(`¡Tienes ${pendingNotifications.length} notificaciones por revisar!`, {
                            style: {
                                color: "#966c2e",
                            },
                            position: "bottom-left",
                        });


                        let not = [...result.data.data.listUserNotification];
                        const idsNoti = [...not.filter((n: any) => n.viewed == 0).map((a: any) => a.idNotification)];

                        if (idsNoti.length > 0) {
                            showNewNot(true);
                        }
                    }
                }
            });
        };

        fetchData();

        return () => { isSubscribed = false };
    }, []);

    const deleteHandleClick = async (id: number, i: number) => {
        const noti = [...notifications];
        noti.splice(i, 1);
        setNotifications(noti);
        await AxiosConfig.delete('/Notification/' + id);;
    }

    useEffect(() => {

        let isSubscribed = true;
        let displayed = showed;

        if (!allowShowComp && displayed) {
            const idsNoti = [...notifications.filter((n: any) => n.viewed == 0).map((a: any) => a.idNotification)];

            if (idsNoti.length > 0) {

                AxiosConfig.post('/Notification/MarkViewedNotifications', {
                    listInts: idsNoti
                }).then(response => {
                    if (response.data.result == "success") {
                        const notiUpdated: any = [...notifications];
                        idsNoti.map(item => {
                            let indexNot = notiUpdated.findIndex((f: any) => f.idNotification == item);
                            notiUpdated[indexNot].viewed = 1;
                            showNewNot(false);
                        });
                        setNotifications(notiUpdated);
                    }
                });
            }
        }

        setShowed(allowShowComp);

        return () => { isSubscribed = false };
    });

    return (
        <div className="notification-container py-4 is-flex"
            style={{ zIndex: allowShowComp ? 101 : 0, opacity: allowShowComp ? '1' : '0', transition: "opacity .25s", pointerEvents: allowShowComp ? "unset" : "none" }}>

            <h1 className="" style={{ letterSpacing: 2 }}>NOTIFICACIONES</h1>

            {
                notifications.length === 0
                    ? <div className="no-notifications">
                        <hr style={{ backgroundColor: "gray", height: "1px", margin: "8px 0" }} />
                        No tienes notificaciones pendientes ✓
                    </div>
                    : <Scrollbar style={{ height: 120, marginTop: 25, marginRight: -20, position: 'inherit', width: 244 }}>
                        <div className="notifications-list is-flex">
                            <div className="notification-content">
                                {
                                    notifications
                                        .sort((a: any, b: any) => b.idNotification - a.idNotification)
                                        .map((nData: any, i: number) => {
                                            return <NotiCard key={nData.idNotification} nData={nData}
                                                deleteHandleClick={() => deleteHandleClick(nData.idNotification, i)}
                                            />
                                        })
                                }
                            </div>
                        </div>
                    </Scrollbar>
            }
        </div>
    );
};

export default NotificationContainer;