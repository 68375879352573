import React from 'react';

import './form-button.styles.scss';

interface props {
    height?: number,
    width?: string,
    color?: string,
    bold?: boolean,
    text: string,
    onClick?: React.MouseEventHandler,
    disabled?: boolean
}

const FormButton = ({height=35, width, color='brown', bold=false, text, onClick, disabled = false}: props) => {
    return (
        <div className="form-button">
            <button className={`button ${color}`} onClick={onClick} disabled={disabled} style={{width: width}}>
                <h1 className="has-text-white" >{text.toLocaleUpperCase()}</h1>
            </button>
        </div>
    );
};

export default FormButton;