import { useState, useContext } from "react";
import ClassButton from "../../shared/components/class-button/class-button.component";
import { Popup } from "./popups/popup.component";
import { errorToast, successToast, warningToast } from "../../shared/helpers";
import { AppContext } from "../../context/context";
import { useHistory } from "react-router";
import { ClassMission } from "../../interfaces/classMission";
import { User } from "../../interfaces/user";
import {
  handleMttoolsTrial,
  masterToolsApi,
} from "../../services/community.service";
import "./community-message.styles.scss";
import BasicPopUp from "../../shared/components/basic-modal/basic-popup.component";

type props = {
  mission: ClassMission;
};

const CommunityMessage = ({ mission }: props) => {
  const history = useHistory();

  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  const dataUser: User | undefined = appContext?.userInfo?.userInfo;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    description: string;
    type: string;
    title: string;
  }>({ type: "", title: "", description: "" });

  let canToHaveMtoolsTrial =
    career.canToHaveMtoolsTrial == null ? 0 : career.canToHaveMtoolsTrial;
  let idUserMasterTools = appContext.loginInfo.idUserMasterTools;

  const [popup, setPopup] = useState({
    active: false,
    Comp: <></>,
  });

  const closeModal = () => {
    setPopup({
      active: false,
      Comp: <></>,
    });
  };
  const openModal = async () => {
    if (mission.targetUrl === "popup") {
      if (canToHaveMtoolsTrial !== 1) {
        errorToast("No tienes acceso a esta oferta");
        window.open("https://mastertools.com", "_blank");
      } else if (idUserMasterTools) {
        warningToast("Ya tienes una cuenta activa de Mastertools");
        window.open("https://app.mastertools.com", "_blank");
      } else {
        setPopup({
          active: true,
          Comp: <Popup closeModal={closeModal} />,
        });
      }
    }
    else if (mission.targetUrl === "apiCallMttools") {
      if (canToHaveMtoolsTrial !== 1) {
        errorToast("No tienes acceso a esta oferta");
        return window.open("https://mastertools.com", "_blank");
      }

      const res = await handleMttoolsTrial(dataUser!.idUser, career.idCareer);
      
      if (res === 460) {
        setModalData({
          title: "ATENCIÓN",
          description: "No tienes acceso a esta oferta.",
          type: "info-fail",
        });
        setShowModal(true);
        window.open("https://mastertools.com", "_blank");
      } else if (res === 461) {
        setModalData({
          title: "ATENCIÓN",
          description:
            `Vimos que ya tienes una cuenta creada en MasterTools asociada al correo: ${dataUser?.email}, tuvimos un problema aplicando el benficio de los 3 meses gratis. Por favor, comunicate con nuestro equipo de soporte`,
          type: "info-info",
        });
        setShowModal(true);
      } else if (res === 462) {
        setModalData({
          title: "ATENCIÓN",
          description:
            `Ya usaste los 3 meses gratis en tu cuenta de MasterTools asociada al correo: ${dataUser?.email}, este beneficio sólo puede ser activado una vez`,
          type: "info-fail",
        });
        setShowModal(true);
      } else if (res.data.result === "UserCanHaveMttoolsTrial") {
        setModalData({
          title: `¡HEY, ${dataUser?.firstName.toUpperCase()}!`,
          description:
            "Hemos abierto una nueva pestaña para actives tu cuenta en MasterTools",
          type: "info-success",
        });
        setShowModal(true);
        window.open(res.data.data.urlRedirect, "_blank");
      } else if (res.data.result === "CouponApplied") {
        setModalData({
          title: "FELICIDADES",
          description:
            `Hemos aplicado un cupon de descuento por 3 meses a tu cuenta de mastertools asociada al correo: ${dataUser?.email}`,
          type: "info-success",
        });
        setShowModal(true);
      }
    } else {
      const domain = "https://app.bemaster.com";
      if (mission.url.includes(domain)) {
        const url = mission.url
          .replace(domain, "")
          .replace("?from=classroom", "");
        return history.push(url, {
          from: "classroom",
        });
      }
      window.open(mission.url, mission.targetUrl);
    }
  };

  const handleCloseButton = (active: boolean) => {
    setShowModal(active);
  };

  return (
    <>
      <BasicPopUp
        showModal={showModal}
        modalData={modalData}
        onClick={handleCloseButton}
      />
      <div className="community-message-container">
        <div className="main-community-modal-container">
          <h1 className="is-size-4">{mission.description}</h1>
          <ClassButton
            text={mission.textButton}
            fontSize={5}
            colorButton={"blue"}
            height={40}
            onClick={openModal}
          />
        </div>
        {popup.active && popup.Comp}
      </div>
    </>
  );
};

export default CommunityMessage;
