import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import "./reminder-message.styles.scss";

interface props {
  showModal: boolean;
  handleCloseButton: (active: boolean) => void;
}

const ReminderMessage = ({ showModal = false, handleCloseButton }: props) => {
  const [activeModal, setActiveModal] = useState(true);

  useEffect(() => {
    setActiveModal(showModal);
  }, [showModal]);

  const handleCloseModal = () => {
    handleCloseButton(!activeModal);
    setActiveModal(!activeModal);
  };

  return (
    <div className="reminder-modal">
      <div className="internal-content">
        <Modal isOpen={activeModal} portalClassName={"reminder-modal"}>
          <div className="close-button" onClick={handleCloseModal}>
            <MdClose onClick={handleCloseModal} />
          </div>
          <h1 className="reminder is-size-4 my-2">¡ALGO HACE FALTA!</h1>
          <div className="bottom-text">
            <h1 className="">
              Aún debes completar algunas clases para obtener tu diplomaF
            </h1>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ReminderMessage;
