import React, { useContext } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { AppContext } from "../context/context";
import MainLayout from "../components/layout/main-layout.component";

interface props {
  path: string;
  Comp: React.ComponentType<RouteComponentProps> | any;
  hasLayout: boolean;
  NavbarComponent: any;
  backgroundClass: string;
  navbarColor?: boolean;
  comVisible?: boolean;
  breadcrumb?: string;
}

const AppAuth = ({
  path,
  Comp,
  hasLayout,
  NavbarComponent,
  backgroundClass,
  navbarColor = false,
  comVisible,
  breadcrumb = "",
}: props) => {
  const appContext = useContext(AppContext);
  const isLoggedIn = appContext.loginInfo.authenticated;
  const careerId = appContext.loginInfo.infoCareers.careerId;
  const allowedPathsBeMasterPass = [
    "/home",
    "/powerclass",
    "/powerclass/:id",
    "/careers",
    "/firesShop",
    "/community",
    "/marketplace",
    "/support",
    "/profile",
  ];

  const allowedPathsChallenge = [
    "/home",
    "/powerclass",
    "/powerclass/:id",
    "/classroom",
    "/careers",
    "/career",
    "/diploma",
    "/firesShop",
    "/community",
    "/marketplace",
    "/support",
    "/profile",
  ];

  if (
    isLoggedIn ||
    path === "/" ||
    path === "/login" ||
    path === "/begin" ||
    path !== "/mentortalks"
  ) {
    if (hasLayout) {
      if (careerId === 10) {
        if (allowedPathsBeMasterPass.includes(path)) {
          return (
            <Route
              exact
              render={(props: any) => (
                <MainLayout
                  children={<Comp {...props} />}
                  path={path}
                  navbarComponent={
                    <NavbarComponent
                      path={path}
                      backgroundColor={navbarColor}
                      comVisible={comVisible}
                      breadcrumb={breadcrumb}
                    />
                  }
                  backgroundClass={backgroundClass}
                  contentMargin={true}
                />
              )}
            />
          );
        } else {
          return <Redirect to="/powerclass" />;
        }
      } else if (careerId === 11 || careerId === 12) {
        if (allowedPathsChallenge.includes(path)) {
          return (
            <Route
              exact
              render={(props: any) => (
                <MainLayout
                  children={<Comp {...props} />}
                  path={path}
                  navbarComponent={
                    <NavbarComponent
                      path={path}
                      backgroundColor={navbarColor}
                      comVisible={comVisible}
                      breadcrumb={breadcrumb}
                    />
                  }
                  backgroundClass={backgroundClass}
                  contentMargin={true}
                />
              )}
            />
          );
        } else {
          return <Redirect to="/home" />;
        }
      } else if (careerId !== 4) {
        if (path === "/marketplace") {
          return <Redirect to="/home" />;
        } else {
          return (
            <Route
              exact
              render={(props: any) => (
                <MainLayout
                  children={<Comp {...props} />}
                  path={path}
                  navbarComponent={
                    <NavbarComponent
                      path={path}
                      backgroundColor={navbarColor}
                      comVisible={comVisible}
                      breadcrumb={breadcrumb}
                    />
                  }
                  backgroundClass={backgroundClass}
                  contentMargin={true}
                />
              )}
            />
          );
        }
      }

      return (
        <Route
          exact
          render={(props: any) => (
            <MainLayout
              children={<Comp {...props} />}
              path={path}
              navbarComponent={
                <NavbarComponent
                  path={path}
                  backgroundColor={navbarColor}
                  comVisible={comVisible}
                  breadcrumb={breadcrumb}
                />
              }
              backgroundClass={backgroundClass}
              contentMargin={path === "/classroom" ? false : true}
            />
          )}
        />
      );
    } else {
      return <Route exact path={path} component={Comp} />;
    }
  } else {
    return <Redirect to="/" />;
  }
};

export default AppAuth;
