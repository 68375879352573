import React from 'react';

import './button-default.styles.scss';

type props = {
    label: string,
    buttonHeight?: string, 
    buttonWidth?: string,
    isBold?: Boolean,
    handleClick?: React.MouseEventHandler
}

const ButtonLogoDefault = ({ label, buttonWidth = "200px", buttonHeight = "33px", isBold = false, handleClick }: props) => {
    return (
        <div className="button-default">
            <button className="button is-small has-text-black"
            style={{ width: buttonWidth, height: buttonHeight, fontWeight: isBold ? 'bold' : 'normal' }}
                onClick={handleClick}>   
                { label } 
            </button>
        </div>
    );
};

export default ButtonLogoDefault;