import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { useHistory } from 'react-router';

import './request-sent-modal.styles.scss';

interface props {
    showModal: boolean
}

const RequestSentModal = ({showModal}: props) => {
    
    const [calmModal, setCalmModal] = useState(true);
    let history = useHistory();

    useEffect(()=> {
        setCalmModal(showModal);
    },[showModal])

    const handleModalBehavior = () => {
        setCalmModal(!calmModal);
    }

    return (
        <div className="request-sent-modal">
            <Modal isOpen={calmModal} portalClassName={'request-sent-modal'}>
                <div className="close-button" onClick={handleModalBehavior}>
                    <MdClose onClick={() => handleModalBehavior} />
                </div>
                <h1 className="sent-text is-size-2">¡HECHO!</h1>
                <h1 className="content-text is-size-2 mb-3">Tu evidencia ha sido enviada con éxito.</h1>
            </Modal>
        </div>
    );
};

export default RequestSentModal;