import * as typeformEmbed from "@typeform/embed";
import { useContext, useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import ClassButton from "../../shared/components/class-button/class-button.component";
import VideoModal from "../video-modal/video-modal.component";
import VideoReact from "../video-react/video-react.component";

import "./request-affiliation.styles.scss";

const RequestAffiliation = () => {
  const appContext = useContext(AppContext);
  const idUserAff = appContext.loginInfo.idUserAff;

  const mainTitle = "¡CONVIÉRTETE EN UN AFILIADO BEMASTER!";
  const [activeModal, setActiveModal] = useState(false);
  const [urlTypeform, setUrlTypeform] = useState<any>();
  const [urlVideo, setUrlVideo] = useState("");
  const [urlThumbnail, setUrlThumbnail] = useState(
    "https://cdn.bemaster.com/media/thumbnail/afiliadomaster/global/218_request_affiliation.jpg"
  );

  let dataUser = [];

  const typeFormButton = typeformEmbed.makePopup(urlTypeform, {
    mode: "popup",
    autoClose: 3,
    hideScrollbars: true,
    onSubmit: function (event) {
      let responseId = event;
    },
  });

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = () => {
      AxiosConfig.get("/Account/GetInfoUser").then((result) => {
        if (isSubscribed) {
          if (result.data.result == "success") {
            dataUser = { ...result.data.data.user };
            setUrlTypeform(
              "https://form.typeform.com/to/mPnFQLRb?typeform-medium=embed-snippet#iduser=" +
                dataUser?.idUser +
                "&email=" +
                dataUser?.email +
                "&phone=" +
                dataUser?.phone
            );
            setUrlVideo(result.data.data.urlRequestAffiliation);
          }
        }
      });
    };
    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleTypePopUp = () => {
    window.open("https://app.comizzion.com", "_blank");
  };

  return (
    <div className="main-certification-video-container">
      <VideoModal showModal={activeModal} />
      <div className="mega-title">
        <h1 className="is-size-3">{mainTitle}</h1>
      </div>
      <Scrollbar>
        <div className="bottom-video-certification-section">
          <h1 className="celebration-text has-text-white mb-2">
            Para solicitar afiliación mira este video con atención
          </h1>
          <div className="video-player">
            <VideoReact
              src={urlVideo}
              autoplay={false}
              srcPoster={urlThumbnail}
            />
          </div>
          <h1 className="last-text has-text-white my-2">
            Toca el siguiente botón y completa los <b>3 pasos</b> del proceso
          </h1>
          <ClassButton
            onClick={handleTypePopUp}
            text={"IR A COMIZZION"}
            colorButton={"brown"}
            fontSize={16}
            height={35}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

export default RequestAffiliation;
