import * as typeformEmbed from "@typeform/embed";
import { useContext, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import { successToast } from "../../shared/helpers";

import "./quiz.styles.scss";

type props = {
  mission: any;
  indexMission: number;
  changeCheckedMission: (mission: any, index: number) => void;
  idCareerModule: number;
  handleLoader: (state: boolean) => void;
};

const Quiz = ({
  mission,
  indexMission,
  changeCheckedMission,
  idCareerModule,
  handleLoader,
}: props) => {
  const typeformRef = useRef<HTMLDivElement>(null);
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idUserCareer = career.idUserCareer;
  let loginInfo = appContext.loginInfo;

  let [userCareerProgress] = [...mission.userCareerProgress];
  let completed =
    userCareerProgress == undefined ? false : !!userCareerProgress.completed;

  let urlQuiz =
    mission.url +
    "#idclassmission=" +
    mission.idClassMission +
    "&idusercareer=" +
    idUserCareer;

  useEffect(() => {
    if (typeformRef.current != null) {
      typeformEmbed.makeWidget(typeformRef.current, urlQuiz, {
        hideFooter: true,
        hideHeaders: true,
        opacity: 100,
        onSubmit: function () {
          onSubmitTypeForm(indexMission);
        },
      });
    }
  }, [typeformRef, indexMission]);

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onSubmitTypeForm = async (ixSubmit: number) => {
    handleLoader(true);
    
    sleep(3000).then(async () => {
      await AxiosConfig.post("/Career/GetScoreQuiz", {
        idUserCareer,
        idClassMission: mission.idClassMission,
      }).then((response) => {
        if (response.data.result === "success") {
          mission.userCareerProgress = [
            response.data.data.userCareerProgress,
          ];
          changeCheckedMission(mission, ixSubmit);

          successToast("Has completado el Quiz exitosamente");

          infoCareer.careerList[selectedCareer].porcProgressLevel =
            response.data.data.porcProgressLevel;
          infoCareer.careerList[selectedCareer].idCurrentModule =
            idCareerModule;
          infoCareer.careerList[selectedCareer].idCurrentClass =
            mission.idModuleClass;
          infoCareer.careerList[selectedCareer].idCurrentClassMission =
            mission.idClassMission;
          appContext.dispatch(loginInfo);
        }
      });
    });
  };

  return (
    <div className="quiz-container">
      <div className="title-area">
        <div className="has-text-weight-bold has-text-white">
          {mission.action}
        </div>
      </div>
      <div ref={typeformRef} className="quiz-content-section"></div>
    </div>
  );
};

export default Quiz;
