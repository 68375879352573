import { useEffect, useState } from 'react';
import AxiosConfig from '../../axios/axiosConfig';
import { useMobileScreen } from '../../hooks/useMobileScreen';
import ClassButton from '../../shared/components/class-button/class-button.component';
import Products from '../product/products.component';
import ShopModal from '../shop-modal/shop-modal.component';

import './shopping.styles.scss';

const Shopping = () => {

    const [activeModal, setActiveModal] = useState(false);
    const [products, setProducts] = useState([]);

    const mobileScreen = useMobileScreen();

    const handleModal = (idProduct: number) => {

        if (!activeModal) {
            CountClick(idProduct);
        }

        setActiveModal(!activeModal);
    }

    const CountClick = async (idProduct: number) => {
        await AxiosConfig.put('/Product/CountClick/', {
            id: idProduct
        });
    }

    useEffect(() => {

        let isSubscribed = true

        const fetchData = async () => {
            await AxiosConfig.get('/Product/GetProducts/Store').then(result => {
                if (isSubscribed) {
                    if (result.data.result === "success") {
                        setProducts(result.data.data.listProducts);
                    }
                }
            });
        };

        fetchData();

        return () => { isSubscribed = false }
    }, []);

    return (
        <div className="main-shopping-container">
            <ShopModal showModal={activeModal} onClick={handleModal} />
            <div className="mega-banner product0">
                {products.filter(function (productBanner: any) {
                    return productBanner.type === 'banner'
                }).map((product: any, i: number) => {
                    return (
                        <div key={i} className="content-area ml-6">
                            <h1 className="banner-title is-size-1 has-text-white my-4">
                                {product.name.toLocaleUpperCase()}
                            </h1>
                            <ClassButton marginTop={mobileScreen ? 0 : 30} height={30} width={140} text={'Comprar'} colorButton={'red'} onClick={() => handleModal(product.idProduct)} />
                        </div>
                    )
                })}
            </div>
            <div className="bottom-shop-section">
                <Products onClick={handleModal} products={products} />
            </div>
        </div>
    );
};


export default Shopping;