import React, { useEffect, useState } from "react";
import PasswordAssignment from "../password-assignment/password-assignment.component";
import { useLocation } from "react-router-dom";
import AxiosConfig from "../../axios/axiosConfig";
import { useHistory } from "react-router-dom";

import "../../shared/sass/essentials.styles.scss";
import "./begining.styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Begining = () => {
  let query = useQuery();
  let email = query.get("param");
  let history = useHistory();
  const [showEmail, setShowEmail] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      localStorage.clear();
      delete AxiosConfig.defaults.headers.common["Authorization"];
      if (email != null) {
        await AxiosConfig.post("/Account/CheckEmail", {
          strParam: email,
        })
          .then((result) => {
            if (isSubscribed) {
              if (result.data.result == "success") {
                let token = result.data.data.token;
                localStorage.setItem("token", token);
                AxiosConfig.defaults.headers.common["Authorization"] = token;
              } else if (result.data.result == "error") {
                let messageError = result.data.message;
                history.push("/");
              }
            }
          })
          .catch((e) => {
            let erorr = e;
          });
      } else {
        setShowEmail(true);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className="main-begining-container">
      <div className="is-back-layer hero is-fullheight has-background-image center">
        {" "}
      </div>
      <div className="is-front-layout hero is-fullheight">
        <PasswordAssignment showEmail={showEmail} />
      </div>
    </div>
  );
};

export default Begining;

// 03127389707
