import { useContext, useRef, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Scrollbar from "react-scrollbars-custom";
import { StudyCase } from "../../interfaces/studyCase";
import { AppContext } from "../../context/context";
import PowerclassCardMaster from "../powerclass-card-master/powerclass-card-master.component";

import "./video-classification.styles.scss";

interface Props {
  classifications: any[];
  totalClasses: string[];
  totalDuration: string[];
  pclassesCat: StudyCase[];
  type?: string;
  favoriteContent?: any[];
}

const VideoClassification = ({ classifications, totalClasses, totalDuration, pclassesCat, type = "powerclass", favoriteContent }: Props) => {

  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;

  const scrollable = useRef<any>([]);
  scrollable.current = [];

  const addToRefs = (item: any) => {
    if (item && !scrollable.current.includes(item)) scrollable.current.push(item);
  }

  const scroll = (scrollOffset: number, i: number) => {
    scrollable.current[i].scrollLeft += scrollOffset;
  };

  return (
    <Scrollbar style={{ zIndex: (tourName === "POWERCLASS" || tourName === "MENTORTALKS") ? 105 : "unset" }}
      thumbYProps={{ className: "thumbY" }}
      trackYProps={{ className: "trackY" }}
    >
      {classifications.map((category: string, i: number) => {
        const pContent: StudyCase[] = pclassesCat.filter((item: StudyCase) => item.classification == category).sort((a: StudyCase, b: StudyCase) => { return a.order - b.order });

        return (
          <div className="main-powerclass-row" >
            <div className="powerclass-title">
              {category}
            </div>
            <div className="powerclass-masters-container">
              <button className="scroll-arrow is-hidden-mobile" onClick={() => scroll(-900, i)}
                style={{ marginRight: -75, zIndex: 1 }} >
                <IoIosArrowBack />
              </button>
              <div className="powerclass-masters-list" ref={addToRefs} key={i}>
                {pContent.map((cont: StudyCase) =>
                  <PowerclassCardMaster
                    category={cont}
                    payed={cont.value > 0 && cont.userContentCategory && cont.userContentCategory.length > 0 ? true : false}
                    key={cont.idContentCategory}
                    tags={cont.tags}
                    totalClasses={cont.totalClasses}
                    totalDuration={cont.totalDuration}
                    type={type}
                  />)}
              </div>
              <button className="scroll-arrow is-hidden-mobile" onClick={() => scroll(900, i)}
                style={{ marginLeft: -75, zIndex: 1 }} >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        )
      })
      }

      {(favoriteContent?.length !== 0 && favoriteContent?.length !== undefined) &&
        <div className="main-powerclass-row" >
          <div className="powerclass-title"> FAVORITOS </div>
          <div className="powerclass-masters-container">
            <button className="scroll-arrow is-hidden-mobile" onClick={() => scroll(-900, 2)}
              style={{ marginRight: -75, zIndex: 1 }} >
              <IoIosArrowBack />
            </button>
            <div className="powerclass-masters-list" ref={addToRefs} key={99}>
              {favoriteContent?.map((cont: StudyCase) =>
                <PowerclassCardMaster
                  category={cont}
                  payed={cont.value > 0 && cont.userContentCategory && cont.userContentCategory.length > 0 ? true : false}
                  key={cont.idContentCategory}

                  tags={cont.tags}
                  totalClasses={cont.totalClasses}
                  totalDuration={cont.totalDuration}
                  type={type}
                />)}
            </div>
            <button className="scroll-arrow is-hidden-mobile" onClick={() => scroll(900, 2)}
              style={{ marginLeft: -75, zIndex: 1 }} >
              <IoIosArrowForward />
            </button>
          </div>
        </div>

      }
    </Scrollbar>
  )
}

export default VideoClassification;