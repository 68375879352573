import React, { ReactNode, useEffect, useState } from 'react';

import './input.styles.scss';

interface props {
    className: string,
    refs: (item: any, id: string) => void,
    id: string,
    type: string,
    disabled: boolean,
    placeHolder: string,
    required: boolean,
    value: string,
    validationMessage?: string,
    validate: boolean,
    setValid: (state: boolean) => void
}

const InputCalculator = ({ id, disabled, className, type, placeHolder, value, required, validationMessage = 'Este campo es obligatorio', refs, validate, setValid }: props) => {

    const [localValue, setLocalValue] = useState<any>(value);
    const [activeAlert, setActiveAlert] = useState<boolean>(false);
    const options = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options);
    
    useEffect(() => {
        if ((localValue === '' || localValue === null) && !disabled) {
            validate && setActiveAlert(true);
            setValid(false);
        } else {
            !validate && setActiveAlert(false);
        }
    }, [validate])

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value != '' || e.target.value != null) {
            setLocalValue(e.target.value)
            setActiveAlert(false);
        } else {
            setLocalValue(null)
        }
    }

    return (
        <>
            <input
                className={`${className} ${disabled && 'success'} ${activeAlert && 'error'} ${!disabled && 'common-input'}`}
                ref={(item: any) => refs(item, id)}
                id={id}
                type={type}
                disabled={disabled}
                placeholder={placeHolder}
                value={localValue ? localValue : ''}
                required={required}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
            />

            {/* <p className="field-required is-size-7 has-text-weight-bold" style={{ display: activeAlert ? 'block' : 'none' }}>
                {validationMessage}
            </p> */}
        </>
    );
};

export default InputCalculator;