import React, { useState, useEffect } from 'react';
import FormButton from '../../shared/components/form-button/form-button.component';
import FormDropdown from '../../shared/components/form-dropdown/form-dropdown.component';
import FormInput from '../../shared/components/form-input/form-input.component';
import { User, userInitialState } from '../../interfaces/user';
import CountriesData from '../countries/countries.data';
import AxiosConfig from '../../axios/axiosConfig';
import { format } from "date-fns";

import './basic-info.styles.scss';
import { errorToast, successToast } from '../../shared/helpers';
import { InputPassword } from '../../shared/components/input-password/input-password.component';
import Scrollbar from 'react-scrollbars-custom';

type props = {
    infoUser: User,
    updateInfoUserState: (user: User) => void
}

const BasicInfo = ({ infoUser, updateInfoUserState }: props) => {

    const [dataUser, setDataUser] = useState<User>(userInitialState);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const [validatePass, setValidatePass] = useState<boolean>(false);
    const [passHasChanges, setPassHasChanges] = useState<boolean>(false);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confNewPassword, setConfNewPassword] = useState("");
    const [passValidMessage, setPassValidMessage] = useState("La contraseña actual es necesaria");
    const [newPassValidMessage, setNewPassValidMessage] = useState("La nueva contraseña es necesaria");
    const [confValidMessage, setConfValidMessage] = useState("La confirmación de la nueva contraseña es necesaria");
    const [invalidPass, setInvalidPass] = useState<boolean>(false);
    const [invalidNewPass, setInvalidNewPass] = useState<boolean>(false);
    const [passNotMatch, setPassNotMatch] = useState<boolean>(false);

    const genre = [
        { label: "Femenino", value: "Femenino" },
        { label: "Masculino", value: "Masculino" },
        { label: "Otro", value: "Otro" },
    ];

    useEffect(() => {
        let isSubscribed = true;
        let data = { ...infoUser };
        setDataUser(data);
        return () => { isSubscribed = false }
    }, [infoUser]);

    const handleChangePass = async () => {

        setPassValidMessage("La contraseña actual es necesaria");
        setNewPassValidMessage("La nueva contraseña es necesaria");
        setConfValidMessage("La confirmación de la nueva contraseña es necesaria");

        setValidatePass(true);

        if (passHasChanges) {
            let IsPassEmpty = password == null || password.trim() == "";
            let IsNewPassEmpty = newPassword == null || newPassword.trim() == "";
            let IsConfNeePassEmpty = confNewPassword == null || confNewPassword.trim() == "";
            const expNum = /[0-9]/;
            const expMin = /[a-z]/;
            const expMay = /[A-Z]/;

            if (!IsPassEmpty && !IsNewPassEmpty && !IsConfNeePassEmpty) {
                setInvalidNewPass(false);
                setValidatePass(false);
                setPassNotMatch(false);
                setInvalidPass(false);
                if (newPassword.length < 8) {
                    setInvalidNewPass(true);
                    setNewPassValidMessage("La nueva contraseña debe tener mínimo 8 caractares");
                }
                else if (newPassword != confNewPassword) {
                    setPassNotMatch(true);
                    setConfValidMessage("Las contraseñas no coinciden");
                }
                else if (!expNum.test(newPassword)) {
                    setInvalidNewPass(true);
                    setNewPassValidMessage("La contraseña debe contener al menos un número");
                }
                else if (!expMin.test(newPassword)) {
                    setInvalidNewPass(true);
                    setNewPassValidMessage("La contraseña debe contener al menos una letra minúscula");
                }
                else if (!expMay.test(newPassword)) {
                    setInvalidNewPass(true);
                    setNewPassValidMessage("La contraseña debe contener al menos una letra mayúscula");
                }
                else {
                    setDisableButton(true);

                    AxiosConfig.put('/Account/ChangePassword', {
                        idUser: dataUser.idUser,
                        password: password,
                        newPassword: newPassword
                    }).then(response => {
                        if (response.data.result == "success") {
                            setDisableButton(false);
                            successToast("Se ha cambiado su contraseña correctamente");
                        }
                        else if (response.data.result == "error") {
                            setDisableButton(false);
                            errorToast("Ha ocurrido un error al guardar su contraseña");
                        }
                        else if (response.data.result == "invalidPass") {
                            setInvalidPass(true);
                            setPassValidMessage("Password incorrecto");
                            setDisableButton(false);
                        }
                    }).catch(e => {
                        setDisableButton(false);
                        errorToast("Ha ocurrido un error, intente mas tarde");
                    });
                }
            }
        }
    }

    const handleSaveBasicInfo = async () => {
        setValidate(true);

        if (hasChanges) {

            let IsNameEmpty = dataUser.firstName == null || dataUser.firstName.trim() == "";
            let IsLastNameEmpty = dataUser.lastName == null || dataUser.lastName.trim() == "";
            let IsGenderEmpty = dataUser.gender == null || dataUser.gender.trim() == "";
            let IsPhoneEmpty = dataUser.phone == null || dataUser.phone.trim() == "";
            let IsAddressEmpty = dataUser.address == null || dataUser.address.trim() == "";
            let IsCountryEmpty = dataUser.country == null || dataUser.country.trim() == "";
            let IsStateEmpty = dataUser.state == null || dataUser.state.trim() == "";
            let IsCityEmpty = dataUser.city == null || dataUser.city.trim() == "";

            if (!IsNameEmpty && !IsLastNameEmpty && !IsPhoneEmpty && !IsAddressEmpty
                && !IsStateEmpty && !IsCityEmpty && !IsGenderEmpty && !IsCountryEmpty) {

                setDisableButton(true);
                AxiosConfig.put('/Account/UpdateBasicInfo', {
                    idUser: dataUser.idUser,
                    firstName: dataUser.firstName,
                    lastName: dataUser.lastName,
                    gender: dataUser.gender,
                    phone: dataUser.phone,
                    address: dataUser.address,
                    country: dataUser.country,
                    state: dataUser.state,
                    city: dataUser.city,
                    birthDay: dataUser.birthday
                }).then(response => {
                    if (response.data.result == "success") {
                        setDisableButton(false);
                        let data = { ...dataUser };
                        updateInfoUserState(data);
                        successToast("Se ha guardado su información correctamente");
                    } else if (response.data.result == "error") {
                        setDisableButton(false);
                        errorToast("Ha ocurrido un error al guardar su información, intente mas tarde");
                    }
                    setValidate(false);
                }).catch(e => {
                    setDisableButton(false);
                    setValidate(false);
                    errorToast("Ha ocurrido un error, intente mas tarde");
                });
            }
        }
    }

    const propsInputBirthDate = {
        type: 'date',
        required: true,
        iWidth: 250,
        value: dataUser.birthday ? (dataUser.birthday.length > 10 ? format(new Date(dataUser.birthday), "yyyy-MM-dd") : dataUser.birthday) : "",
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                birthday: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        },
        validate
    }

    const propsInputName = {
        type: 'text',
        placeHolder: 'Tu nombre',
        required: true,
        iWidth: 250,
        validationMessage: "Tu nombre es necesario",
        value: dataUser.firstName || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                firstName: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        },
        validate: validate
    }

    const propsInputLastName = {
        type: 'text',
        placeHolder: 'Tus apellidos',
        required: true,
        iWidth: 250,
        validationMessage: "Tu apellidos son necesarios",
        value: dataUser.lastName || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                lastName: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        },
        validate: validate
    }

    const propsInputGenre = {
        type: 'text',
        placeHolder: 'Elige género',
        required: true,
        iWidth: 250,
        validationMessage: "Tu género es necesario",
        value: dataUser.gender || '',
        onChange: (sValue: any) => {
            let data = {
                ...dataUser,
                gender: sValue
            }
            setDataUser(data);
            setHasChanges(true);
        },
        items: genre,
        validate: validate
    }

    const propsInputPhone = {
        type: 'tel',
        placeHolder: 'Escribe tu teléfono',
        required: true,
        iWidth: 250,
        validationMessage: "Tu teléfono es necesario",
        value: dataUser.phone || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                phone: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        },
        validate: validate
    }

    const propsInputAddress = {
        type: 'text',
        placeHolder: 'Escribir dirección',
        required: true,
        iWidth: 500,
        validationMessage: "Tu direción es necesaria",
        value: dataUser.address || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                address: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        },
        validate: validate
    }

    const propsInputEmail = {
        type: 'email',
        placeHolder: 'ejemplo@correo.com',
        required: true,
        iWidth: 250,
        value: dataUser.email || '',
        readOnly: true
    }

    const propsInputCountry = {
        type: 'text',
        placeHolder: 'Elige tu país',
        required: true,
        iWidth: 250,
        validationMessage: "Tu país es necesario",
        value: dataUser.country || '',
        onChange: (sValue: any) => {
            let data = {
                ...dataUser,
                country: sValue
            }
            setDataUser(data);
            setHasChanges(true);
        },
        items: CountriesData,
        validate: validate
    }

    const propsInputState = {
        type: 'text',
        placeHolder: 'Escribe tu estado',
        required: true,
        iWidth: 220,
        validationMessage: "El estado es necesario",
        value: dataUser.state || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                state: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        },
        validate: validate
    }

    const propsInputCity = {
        type: 'text',
        placeHolder: 'Escribe tu ciudad',
        required: true,
        iWidth: 220,
        validationMessage: "Tu ciudad es necesaria",
        value: dataUser.city || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                city: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        },
        validate: validate
    }

    const propsInputPassword = {
        type: 'password',
        placeHolder: '••••••••',
        required: true,
        iWidth: 400,
        validationMessage: passValidMessage,
        value: password,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
            setPassHasChanges(true);
        },
        validate: validatePass,
        activeAlert: invalidPass
    }

    const propsInputNewPassword = {
        type: 'password',
        placeHolder: '••••••••',
        required: true,
        iWidth: 400,
        validationMessage: newPassValidMessage,
        value: newPassword,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setNewPassword(e.target.value);
            setPassHasChanges(true);
        },
        validate: validatePass,
        activeAlert: invalidNewPass
    }

    const propsInputConfNewPassword = {
        type: 'password',
        placeHolder: '••••••••',
        required: true,
        iWidth: 400,
        validationMessage: confValidMessage,
        value: confNewPassword,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setConfNewPassword(e.target.value);
            setPassHasChanges(true);
        },
        validate: validatePass,
        activeAlert: passNotMatch
    }

    const scrollHeight = window.innerHeight - (window.innerHeight * 0.1);

    return (
        <div className="basic-info-container" >
            <Scrollbar noScrollX={true} style={{ height: scrollHeight, width: '120%' }}>
                <div className="top-form-section pb-6">
                    <div className="columns ">
                        <div className="column">
                            <div className="name-section">
                                <h1>Nombre*</h1>
                            </div>
                            <div className="cn-input">
                                <FormInput {...propsInputName} />
                            </div>
                        </div>
                        <div className="column">
                            <div className="lastName-section">
                                <h1>Apellidos*</h1>
                            </div>
                            <div className="cn-input">
                                <FormInput {...propsInputLastName} />
                            </div>
                        </div>
                        <div className="column">
                            <div className="genre-section">
                                <div className="genre">
                                    <h1>Género*</h1>
                                </div>
                                <FormDropdown {...propsInputGenre} />
                            </div>
                        </div>
                        <div className="column">
                            <div className="phone-section">
                                <div className="phone">
                                    <h1>Teléfono*</h1>
                                </div>
                                <div className="p-input">
                                    <FormInput {...propsInputPhone} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <div className="address">
                                <h1>Dirección*</h1>
                            </div>
                            <div className="a-input">
                                <FormInput {...propsInputAddress} />
                            </div>
                        </div>
                        <div className="column">
                            <div className="email-section">
                                <div className="email">
                                    <h1>Email*</h1>
                                </div>
                                <div className="e-input">
                                    <FormInput {...propsInputEmail} />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="email-section">
                                <div className="birthDate">
                                    <h1>Fecha de nacimiento</h1>
                                </div>
                                <div className="a-input">
                                    <FormInput {
                                        ...propsInputBirthDate
                                    } />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <div className="country">
                                <h1>País*</h1>
                            </div>
                            <div className="c-input">
                                <FormDropdown {...propsInputCountry} />
                                {/* <Flag country={flag} handlerFlag={propsInputCountry.onChange} /> */}
                            </div>
                        </div>
                        <div className="column">
                            <div className="state-section">
                                <div className="state">
                                    <h1>Departamento / Estado / Provincia*</h1>
                                </div>
                                <div className="e-input">
                                    <FormInput {...propsInputState} />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="city-section">
                                <div className="city">
                                    <h1>Ciudad*</h1>
                                </div>
                                <div className="ct-input">
                                    <FormInput {...propsInputCity} />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="save-button mt-4">
                                <FormButton
                                    text={'Guardar'}
                                    onClick={() => handleSaveBasicInfo()}
                                    disabled={disableButton}
                                    color={'red'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-form-section pt-4 mb-6">
                    <h1 className="change-password-title">Cambiar Contraseña:</h1>
                    <div className="columns">
                        <div className="column">
                            <div className="password">
                                <h1>Contraseña Actual</h1>
                            </div>
                            <div className="ct-input">
                                <FormInput {...propsInputPassword} />
                                {/* <InputPassword 
                                    placeholder={propsInputPassword.placeHolder}
                                    value={propsInputPassword.value}
                                    onChange={propsInputPassword.onChange}
                                    theme="light"
                                /> */}
                            </div>
                        </div>
                        <div className="column">
                            <div className="new-password">
                                <h1>Nueva contraseña</h1>
                            </div>
                            <div className="ct-input">
                                <FormInput {...propsInputNewPassword} />
                                {/* <InputPassword 
                                    placeholder={propsInputNewPassword.placeHolder}
                                    value={propsInputNewPassword.value}
                                    onChange={propsInputNewPassword.onChange}
                                    theme="light"
                                /> */}
                            </div>
                            <div className="column confirm-pass-container">
                                <div className="confirm-new-password mt-4">
                                    <h1>Confirma tu nueva contraseña</h1>
                                </div>
                                <div className="ct-input">
                                    <FormInput {...propsInputConfNewPassword} />
                                    {/* <InputPassword 
                                    placeholder={propsInputConfNewPassword.placeHolder}
                                    value={propsInputConfNewPassword.value}
                                    onChange={propsInputConfNewPassword.onChange}
                                    theme="light" 
                                /> */}
                                </div>

                                <div className="change-password mt-4">
                                    <FormButton
                                        text={'Cambiar Contraseña'}
                                        onClick={() => handleChangePass()}
                                        disabled={disableButton}
                                        color={'red'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbar>
        </div>
    );
};

export default BasicInfo;