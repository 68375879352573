import React from 'react';

import './custom-toggle.styles.scss';

interface props {
  id?: string,
  onChange?: React.ChangeEventHandler,
  checked?: boolean,
  disabled?: boolean
}

const CustomToggle = ({id='new-switch', onChange, checked, disabled}: props) => {
    return (
        <>
          <input className="react-switch-checkbox" id={id} type="checkbox" onChange={onChange} checked={checked} disabled={disabled} />
          <label className="react-switch-label" htmlFor={id} >
            <span className={`react-switch-button`} />
          </label>
        </>
      );
};

export default CustomToggle;