import React, { useState, useEffect } from "react";
import MissionData from "./mission.data";
import Check from "../../../check/check.component";
import Scrollbar from "react-scrollbars-custom";

import "./mission-item.styles.scss";

type props = {
  onClick: React.MouseEventHandler;
  id: string;
  completed: Boolean;
  title: string;
  checkable: Boolean;
  changeCheckedMission: (mission: any, index: number) => void;
  idCareerModule: number;
  idModuleClass: number;
  mission: any;
  missionChecked: () => void;
  indexMission: number;
  careerId: number;
  eMMCareerId: number;
};

const MissionItem = ({
  id,
  completed,
  title,
  checkable,
  onClick,
  changeCheckedMission,
  idCareerModule,
  idModuleClass,
  mission,
  missionChecked,
  indexMission,
  careerId,
  eMMCareerId,
}: props) => {
  const [missionComplete, setMissionCompleted] = useState(completed);
  useEffect(() => {
    setMissionCompleted(completed);
  }, [completed]);

  const handleCheck = (chState: boolean) => {
    setMissionCompleted(chState);
  };

  return (
    <div className="m-item is-flex my-4 pl-5 pr-2" key={id}>
      <div className="radio-check" key={id}>
        <Check
          id={id}
          checkAllow={checkable}
          completed={completed}
          changeCheckedMission={changeCheckedMission}
          handleCheck={handleCheck}
          idCareerModule={idCareerModule}
          idModuleClass={idModuleClass}
          mission={mission}
          indexMission={indexMission}
          missionChecked={missionChecked}
        />
      </div>
      <h1
        className={`ml-3 ${missionComplete ? "m-completed" : ""}`}
        onClick={onClick}
      >
        {title.toLocaleUpperCase()}
      </h1>
    </div>
  );
};

export default MissionItem;
