import ClassButton from "../../shared/components/class-button/class-button.component";

import "./fires-store-card.styles.scss";

interface props {
  onClick?: () => void;
  fires: any;
}

const FiresStoreCard = ({ fires }: props) => {
  const coinsImages = [
    "https://cdn.bemaster.com/media/images/fires-store/100 Flame Coins.png",
    "https://cdn.bemaster.com/media/images/fires-store/500 Flame Coins.png",
    "https://cdn.bemaster.com/media/images/fires-store/2000 Flame Coins.png",
  ];

  const handleClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="main-product-container">
      {fires.map((fire: any, i: number) => {
        return (
          <div className="product-container mr-4">
            <div
              className={`p-card fire-bag`}
              style={{
                backgroundImage: `url('${coinsImages[i]}')`,
              }}
            >
              {" "}
            </div>
            <div className="details-container">
              <h1 className="is-size-2 has-text-weight-bold">
                {fire.details.toLocaleUpperCase()}
              </h1>
              <h1 className="is-size-6 mt-2">FUEGOS</h1>
              <div className="mini-price my-2">
                <h1 className="is-size-6 has-text-weight-bold">$</h1>
                <h1 className="is-size-6">{fire.value}</h1>
                <h1 className="is-size-6 ml-1">USD</h1>
              </div>
              <ClassButton
                height={30}
                width={140}
                text={"Comprar"}
                colorButton={"red"}
                onClick={() => handleClick(fire.urlCheckout)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FiresStoreCard;
