
const formData = [
    {
        id: 'a',
        table: [
            {
                group: 'group1',
                title: 'INFORMACIÓN DEL NEGOCIO DEL CLIENTE',
                fields: [
                    {
                        id: 0,
                        type: 'number',
                        editable: true,
                        placeHolder: '$',
                        prevLabel: 'Ticket medio del producto',
                        value: null,
                        disabled: false,
                        format: 'currency'
                    },
                    {
                        id: 1,
                        type: 'number',
                        editable: false,
                        placeHolder: '$',
                        prevLabel: 'Ganancia bruta por venta',
                        value: null,
                        disabled: false,
                        format: 'currency'
                    },
                    {
                        id: 2,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Ventas por mes',
                        prevLabel: 'Ventas/Mes',
                        value: null,    
                        disabled: false,
                        format: null
                    },
                    {
                        id: 3,
                        type: 'number',
                        editable: false,
                        placeHolder: '$',
                        prevLabel: 'Facturación Promedio',
                        value: null,
                        formula: { 
                            variables: [
                                { 
                                    input: '0'
                                },
                                {
                                    input: '2'
                                }
                            ], 
                            operation: 'input0*input2'
                        },
                        disabled: true,
                        format: 'currency'
                    },
                    {
                        id: 4,
                        type: 'number',
                        editable: false,
                        placeHolder: '$',
                        prevLabel: 'Lucro Medio',
                        value: null,
                        formula: { 
                            variables: [
                                { 
                                    input: '1'
                                },
                                {
                                    input: '2'
                                }
                            ], 
                            operation: 'input1*input2'
                        },
                        disabled: true,
                        format: 'currency'
                    },
                    {
                        id: 5,
                        type: 'number',
                        editable: false,
                        placeHolder: '$',
                        prevLabel: 'Inversión en divulgación/medios',
                        value: null,
                        disabled: false,
                        format: 'currency'
                    },
                    {
                        id: 6,
                        type: 'number',
                        editable: false,
                        placeHolder: 'CAC',
                        prevLabel: 'CAC',
                        value: null,
                        formula: {
                            variables: [
                                { 
                                    input: '2'
                                },
                                {
                                    input: '5'
                                }
                            ], 
                            operation: 'input5/input2'
                        },
                        disabled: true,
                        format: 'currency'
                    }
                ]
            }
        ]
    },
    {
        id: 'b',
        table: [
            {
                group: 'group2',
                title: 'PLANEACIÓN ADS',
                fields: [
                    {
                        id: 7,
                        type: 'number',
                        editable: true,
                        placeHolder: 'Facturación',
                        prevLabel: 'Facturación deseada',
                        value: null,
                        disabled: false,
                        format: 'currency'
                    },
                    {
                        id: 8,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Ventas',
                        prevLabel: 'Ventas necesarias',
                        value: null,
                        formula: { 
                            variables: [
                                {
                                    input: '0', 
                                },
                                {
                                    input: '7', 
                                }
                            ], 
                            operation: 'input7/input0'    
                        },
                        disabled: true,
                        format: null
                    },
                    {
                        id: 9,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Ventas con ADS',
                        prevLabel: 'Ventas con ADS',
                        value: null,
                        formula: { 
                            variables: [
                                {
                                    input: '8' 
                                },
                                {
                                    input: '2', 
                                }
                            ], 
                            operation: 'input8-input2'    
                        },
                        disabled: true,
                        format: 'currency'
                    },
                    {
                        id: 10,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Inversión con ADS',
                        prevLabel: 'Inversión con ADS',
                        value: null,
                        formula: { 
                            variables: [
                                {
                                    input: '9', 
                                },
                                {
                                    input: '0',
                                }
                            ], 
                            operation: 'input9*(input0*0.1)'    
                        },
                        disabled: true,
                        format: 'currency'
                    },
                ]
            }
        ]
    },
    {
        id: 'd',
        table: [
            {
                group: 'group3',
                title: 'PLANEACIÓN DE PRESUPUESTO',
                fields: [
                    {
                        id: 11,
                        type: 'number',
                        editable: true,
                        placeHolder: 'Inversión deseada',
                        prevLabel: 'Inversión deseada',
                        value: null,
                        disabled: false,
                        format: 'currency'
                    },
                    {
                        id: 12,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Ventas medias esperadas',
                        prevLabel: 'Ventas medias esperadas',
                        value: null,
                        formula: { 
                            variables: [
                                {
                                    input: '11', 
                                },
                                {
                                    input: '0', 
                                }
                            ], 
                            operation: 'input11/(input0*0.1)'    
                        },
                        disabled: true,
                        format: null
                    },
                    {
                        id: 13,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Facturación esperada',
                        prevLabel: 'Facturación esperada',
                        value: null,    
                        formula: { 
                            variables: [
                                { 
                                    input: '12'
                                },
                                {
                                    input: '0', 
                                }
                            ], 
                            operation: 'input12*input0'    
                        },
                        disabled: true,
                        format: null
                    }
                ]
            }
        ]
    },
    {
        id: 'c',
        table: [
            {
                group: 'group2',
                title: 'PRESUPUESTO GENERAL',
                fields: [
                    {
                        id: 15,
                        type: 'number',
                        editable: true,
                        placeHolder: '$',
                        prevLabel: 'Presupuesto de ADS',
                        value: null,
                        formula: { 
                            variables: [
                                { 
                                    input: '10'
                                }
                            ], 
                            operation: 'input10'    
                        },
                        disabled: true,
                        format: 'currency'
                    },
                    {
                        id: 16,
                        type: 'number',
                        editable: false,
                        placeHolder: '$',
                        prevLabel: 'Presupuesto de servicio',
                        value: null,
                        disabled: false,
                        format: 'currency'
                    },
                    {
                        id: 17,
                        type: 'number',
                        editable: false,
                        placeHolder: '0%',
                        prevLabel: 'Porcentaje',
                        value: null,
                        format: null
                    },
                    {
                        id: 18,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Total',
                        prevLabel: 'Total',
                        value: null,
                        formula: { 
                            variables: [
                                { 
                                    input: '15'
                                },
                                {
                                    input: '16', 
                                },
                                {
                                    input: '17', 
                                },
                                {
                                    input: '7', 
                                }
                            ], 
                            operation: '(input15+input16)+((input17/100)*input7)'    
                        },
                        disabled: true,
                        format: 'currency'
                    },
                ]
            }
        ]
    },
    {
        id: 'e',
        table: [
            {
                group: 'group3',
                title: 'PRESUPUESTO GENERAL',
                fields: [
                    {
                        id: 19,
                        type: 'number',
                        editable: true,
                        placeHolder: '$',
                        prevLabel: 'Presupuesto de ADS',
                        value: null,formula: { 
                            variables: [
                                { 
                                    input: '11'
                                }
                            ], 
                            operation: 'input11'    
                        },
                        disabled: true,
                        format: 'currency'
                    },
                    {
                        id: 20,
                        type: 'number',
                        editable: false,
                        placeHolder: '$',
                        prevLabel: 'Presupuesto de servicio',
                        value: null,
                        disabled: false,
                        format: 'currency'
                    },
                    {
                        id: 21,
                        type: 'number',
                        editable: false,
                        placeHolder: '0%',
                        prevLabel: 'Porcentaje',
                        value: null,    
                        disabled: false,
                        format: null
                    },
                    {
                        id: 22,
                        type: 'number',
                        editable: false,
                        placeHolder: 'Total',
                        prevLabel: 'Total',
                        value: null,
                        formula: { 
                            variables: [
                                { 
                                    input: '19'
                                },
                                {
                                    input: '20', 
                                },
                                {
                                    input: '21', 
                                },
                                {
                                    input: '13', 
                                }
                            ], 
                            operation: '(input19+input20)+((input21/100)*input13)'    
                        },
                        disabled: true,
                        format: 'currency'
                    },
                ]
            }
        ]
    }
];

export default formData;