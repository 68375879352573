import { AiOutlineWarning } from 'react-icons/ai';
import Select from 'react-select';

import './form-dropdown.styles.scss';

interface props {
    text?: string,
    items: any,
    onChange: (sValue: any) => void,
    readOnly?: boolean,
    validationMessage?: string,
    validate?: boolean,
    value?: any,
    required?: boolean,
    activeAlert?: boolean,
}

const FormDropdown = ({ text = 'Elige una opción...', items, onChange, readOnly, validationMessage, validate, value,
    activeAlert = false, required = false }: props) => {
    let index = items.findIndex((e: any) => e.value === value);
    if (validate) {
        if (required) {
            let valid = value.trim() !== "";
            activeAlert = !valid;
        }
    }

    // const [selectedValue, setSelectedValue] = useState(''); 
    const handleChange = (e: any) => {
        // setSelectedValue(e.value);
        onChange(e.value);
    }

    return (

        <div className="main-dropdown-container">
            <Select options={items} onChange={handleChange} value={items[index]} />
            <div className="alert-area mt-1" style={{ display: activeAlert ? 'flex' : 'none' }}>
                <div className="alert-icon">
                    <AiOutlineWarning />
                </div>
                <h1 className="ml-1 is-size-7">{validationMessage}</h1>
            </div>
        </div>

    );
};

export default FormDropdown;