import { ReactComponent as Fire } from '../../assets/images/profile/fire.svg';
import ClassButton from '../../shared/components/class-button/class-button.component';

import './product.styles.scss';

interface props {
    onClick: (idProduct: number) => void,
    products: any,
}

const Products = ({onClick, products}: props) => {
    return (
        <div className="main-product-container">
            {products.filter(function(productsFiltered: any) {
                return productsFiltered.type == 'card'
            }).map((product: any, i: number)=> {
                return(
                    <div key={i} className="product-container mr-4">
                        <div className={`p-card product0${product.idProduct}`}> </div>
                        <div className="details-container">
                            <h1 className="is-size-5 has-text-weight-bold">{product.name.toLocaleUpperCase()}</h1>
                            <div className="mini-price my-2">
                                <Fire height={20} />
                                <h1 className="is-size-6 ml-2">{product.value}</h1>
                            </div>
                            <ClassButton height={30} width={140} text={'Comprar'} colorButton={'red'} onClick={() => onClick(product.idProduct)} />
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default Products;