import React, { useEffect, useRef, useState } from 'react';
import toast from "react-hot-toast";
import { FaYoutube } from "react-icons/fa";
import { ImFacebook } from "react-icons/im";
import { SiInstagram } from "react-icons/si";
import Scrollbar from 'react-scrollbars-custom';
import AxiosConfig from '../../axios/axiosConfig';
import { config } from '../../config';
import { useMobileScreen } from '../../hooks/useMobileScreen';
import { User, userInitialState } from '../../interfaces/user';
import FormButton from '../../shared/components/form-button/form-button.component';
import FormInput from '../../shared/components/form-input/form-input.component';

import './public-profile.styles.scss';

type props = {
    infoUser: User,
    updateInfoUserState: (user: any) => void
}

const PublicProfile = ({ infoUser, updateInfoUserState }: props) => {

    const defaultPhoto = config.url.images.defaultProfilePhoto;
    const UploadImageRef = useRef<HTMLInputElement>(null);
    const PhotoRef = useRef<HTMLImageElement>(null);
    const [dataUser, setDataUser] = useState<User>(userInitialState);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [changePhoto, setChangePhoto] = useState<boolean>(false);
    const [invalidAlias, setInvalidAlias] = useState<boolean>(false);
    const [urlPhoto, setUrlPhoto] = useState<string>(defaultPhoto);
    const [photo, setPhoto] = useState<any>();

    useEffect(() => {
        let isSubscribed = true;
        let data = { ...infoUser };
        setDataUser(data);
        setUrlPhoto(data.photo ?? defaultPhoto);
        return () => { isSubscribed = false }
    }, [infoUser]);

    const handleSavePublicInfo = async () => {
        setValidate(true);

        if (hasChanges) {
            setDisableButton(true);

            await AxiosConfig.put('/Account/UpdatePublicInfo', {
                idUser: dataUser.idUser,
                alias: dataUser.alias,
                about: dataUser.about,
                youtube: dataUser.youtube,
                facebook: dataUser.facebook,
                instagram: dataUser.instagram,
                photo: dataUser.photo
            }).then(response => {
                if (response.data.result == "success") {
                    setDisableButton(false);
                    let data = { ...dataUser };
                    updateInfoUserState(data);
                    toast.success("Información guardada con éxito!", {
                        style: {
                            color: "#966c2e",
                        },
                        position: "bottom-left",
                    });
                }
                else if (response.data.result == "error") {
                    setDisableButton(false);
                    toast.error("Ha ocurrido un error al guardar los cambios", {
                        duration: 6000,
                        style: {
                            color: "#966c2e",
                        },
                        position: "bottom-left",
                    });
                }
                else if (response.data.result == "invalidAlias") {
                    setInvalidAlias(true);
                    setDisableButton(false);
                    toast.error("El Alias ingresado es inválido", {
                        duration: 6000,
                        style: {
                            color: "#966c2e",
                        },
                        position: "bottom-left",
                    });
                }
                setValidate(false);
            }).catch(e => {
                setDisableButton(false);
                setValidate(false);
            });
        }

        if (changePhoto) {
            setDisableButton(true);
            // Create an object of formData 
            const formData = new FormData();
            formData.append("formFile", photo);

            await AxiosConfig.post('/Account/UploadPhoto',
                formData
            ).then(response => {
                if (response.data.result === "success") {
                    setDisableButton(false);
                    let data = { ...dataUser };
                    data.photo = response.data.data.urlPhoto;
                    updateInfoUserState(data);
                    setUrlPhoto(response.data.data.urlPhoto);
                }
                else if (response.data.result === "error") {
                    setDisableButton(false);
                }
            }).catch(e => {
                setDisableButton(false);
            });
        }
    }

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != undefined && event.target.files?.length > 0) {
            let url = (URL || webkitURL).createObjectURL(event.target.files[0]);
            setUrlPhoto(url);
            setHasChanges(true);
            setPhoto(event.target.files[0]);
            setChangePhoto(true);
        }
    }

    const handleRemovePhoto = () => {
        if (dataUser.photo !== null && dataUser.photo !== "") {
            dataUser.photo = null;
            setHasChanges(true);
            setUrlPhoto(defaultPhoto);
            setPhoto(null);
        }
    }

    const propsInputAlias = {
        type: 'text',
        placeHolder: '¿Cómo te gusta que te llamen?',
        required: false,
        iWidth: 350,
        validationMessage: 'Alias no disponible',
        activeAlert: invalidAlias,
        value: dataUser.alias || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                alias: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
            setInvalidAlias(false);
        }
    }

    const propsInputFacebook = {
        type: 'url',
        placeHolder: 'Link a tu perfil de Facebook',
        required: false,
        iWidth: 350,
        value: dataUser.facebook || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                facebook: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        }
    }

    const propsInputYoutube = {
        type: 'url',
        placeHolder: 'Link a tu perfil de Youtube',
        required: true,
        iWidth: 350,
        value: dataUser.youtube || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                youtube: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        }
    }

    const propsInputInstagram = {
        type: 'url',
        placeHolder: 'Link a tu perfil de Instagram',
        required: true,
        iWidth: 350,
        value: dataUser.instagram || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            let data = {
                ...dataUser,
                instagram: e.target.value
            }
            setDataUser(data);
            setHasChanges(true);
        }
    }

    const mobileScreen = useMobileScreen();

    //TODO: Optimize duplicate containers
    return (
        <>
            <div className="public-profile columns mobile" id="custom-grid" style={{ display: mobileScreen ? 'flex' : 'none' }}>
                <Scrollbar noScrollX={true} style={{ height: 670, width: '100%' }}>
                    <div className="photo-section column mr-4">
                        <div className="photo-circle">
                            <img ref={PhotoRef} width="100%" src={urlPhoto} alt="Photo" className="img-photo" />
                        </div>
                        <div className="links-area is-flex my-3">
                            <div className="change-photo">
                                <input
                                    accept="image/png, image/jpeg"
                                    ref={UploadImageRef}
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={onFileChange} />
                                <h1 className="is-size-7" onClick={() => UploadImageRef.current?.click()}>Cambiar foto</h1>
                            </div>
                            <div className="mx-1">/</div>
                            <div className="delete-photo">
                                <h1 className="is-size-7" onClick={() => handleRemovePhoto()}>Eliminar foto</h1>
                            </div>
                        </div>
                    </div>
                    <div className="is-settings-section column">
                        <div className="public-name mb-4">
                            <h1>Nombre público / Alias</h1>
                            <div className="alias-input">
                                <FormInput {...propsInputAlias} />
                            </div>
                        </div>

                        <div className="description ">
                            <h1>Descripción sobre mi</h1>
                            <div className="description-input">
                                <textarea className="textarea" placeholder="Escribe algo sobre ti" value={dataUser.about || ''}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        let data = {
                                            ...dataUser,
                                            about: e.target.value
                                        }
                                        setDataUser(data);
                                        setHasChanges(true);
                                    }}></textarea>
                            </div>

                        </div>

                    </div>
                    <div className="networks-section column">
                        <h1>Redes sociales</h1>
                        <div className="youtube is-flex">
                            <div className="icon-area">
                                <FaYoutube />
                            </div>
                            <div className="networks-input mb-2">
                                <FormInput {...propsInputYoutube} />
                            </div>
                        </div>
                        <div className="facebook is-flex">
                            <div className="icon-area">
                                <ImFacebook />
                            </div>
                            <div className="networks-input mb-2">
                                <FormInput {...propsInputFacebook} />
                            </div>
                        </div>
                        <div className="instagram is-flex">
                            <div className="icon-area">
                                <SiInstagram />
                            </div>
                            <div className="networks-input mb-2">
                                <FormInput {...propsInputInstagram} />
                            </div>
                        </div>
                        <div className="d-save my-2">
                            <FormButton
                                text={'Guardar'}
                                onClick={() => handleSavePublicInfo()}
                                disabled={disableButton}
                                color={'red'} />
                        </div>
                    </div>
                </Scrollbar>
            </div>


            <div className="public-profile columns desktop" id="custom-grid" style={{ display: mobileScreen ? 'none' : 'flex' }}>
                <div className="photo-section column mr-4">
                    <div className="photo-circle">
                        <img ref={PhotoRef} width="100%" src={urlPhoto} alt="Photo" className="img-photo" />
                    </div>
                    <div className="links-area is-flex my-3">
                        <div className="change-photo">
                            <input
                                accept="image/png, image/jpeg"
                                ref={UploadImageRef}
                                type="file"
                                style={{ display: "none" }}
                                onChange={onFileChange} />
                            <h1 className="is-size-7" onClick={() => UploadImageRef.current?.click()}>Cambiar foto</h1>
                        </div>
                        <div className="mx-1">/</div>
                        <div className="delete-photo">
                            <h1 className="is-size-7" onClick={() => handleRemovePhoto()}>Eliminar foto</h1>
                        </div>
                    </div>
                </div>
                <div className="is-settings-section column">
                    <div className="public-name mb-4">
                        <h1>Nombre público / Alias</h1>
                        <div className="alias-input">
                            <FormInput {...propsInputAlias} />
                        </div>
                    </div>

                    <div className="description ">
                        <h1>Descripción sobre mi</h1>
                        <div className="description-input">
                            <textarea className="textarea" placeholder="Escribe algo sobre ti" value={dataUser.about || ''}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    let data = {
                                        ...dataUser,
                                        about: e.target.value
                                    }
                                    setDataUser(data);
                                    setHasChanges(true);
                                }}></textarea>
                        </div>
                        <div className="d-save my-2">
                            <FormButton
                                text={'Guardar'}
                                onClick={() => handleSavePublicInfo()}
                                disabled={disableButton}
                                color={'red'}
                                width={'100%'} />
                        </div>
                    </div>

                </div>
                <div className="networks-section column">
                    <h1>Redes sociales</h1>
                    <div className="youtube is-flex">
                        <div className="icon-area">
                            <FaYoutube />
                        </div>
                        <div className="networks-input mb-2">
                            <FormInput {...propsInputYoutube} />
                        </div>
                    </div>
                    <div className="facebook is-flex">
                        <div className="icon-area">
                            <ImFacebook />
                        </div>
                        <div className="networks-input mb-2">
                            <FormInput {...propsInputFacebook} />
                        </div>
                    </div>
                    <div className="instagram is-flex">
                        <div className="icon-area">
                            <SiInstagram />
                        </div>
                        <div className="networks-input mb-2">
                            <FormInput {...propsInputInstagram} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PublicProfile;