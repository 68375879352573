import React, { useState, useRef, useEffect, useContext } from 'react';
import { BsPaperclip } from 'react-icons/bs';
import ClassButton from '../../../shared/components/class-button/class-button.component';
import FormInput from '../../../shared/components/form-input/form-input.component';
import { AiOutlineWarning } from 'react-icons/ai';
import AxiosConfig from '../../../axios/axiosConfig';
import './mail.styles.scss';

import { AppContext } from '../../../context/context';

const Mail = () => {

    const appContext = useContext(AppContext);
    const infoCareer: any = appContext.loginInfo.infoCareers;
    const selectedCareer = infoCareer.selectedCareer;
    const career = infoCareer.careerList[selectedCareer];
    let idUserCareer = career.idUserCareer;

    const UploadFileRef = useRef<HTMLInputElement>(null);
    const [confState, setConfState] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);
    const [validQuestion, setValidQuestion] = useState<boolean>(true);
    const [validFiles, setValidFiles] = useState<boolean>(true);
    const [subject, setSubject] = useState<string>("");
    const [question, setQuestion] = useState<string>("");
    const [attachments, setAttachments] = useState<any>([]);

    useEffect(() => {
        var Tawk_API: any = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/5fc9079b920fc91564cd2858/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode?.insertBefore(s1, s0);
        })();
    }, []);
    
    const handleSupport = async () => {
        setValidate(true);

        let IsSubjectEmpty = subject === null || subject.trim() === "";
        let IsQuestionEmpty = question === null || question.trim() === "";
        setValidQuestion(!IsQuestionEmpty);

        if (!IsSubjectEmpty && !IsQuestionEmpty && validFiles) {
            setDisableButton(true);
            // Create an object of formData 
            const formData = new FormData();
            //formData.append("formFile", photo);
            formData.append("subject", subject);
            formData.append("question", question);
            formData.append("idUserCareer", idUserCareer);

            Array.from(attachments).forEach((file: any) => {
                formData.append("formFile", file);
            });

            await AxiosConfig.post('/Support/CreateCase',
                formData
            ).then(response => {
                if (response.data.result == "success") {
                    setDisableButton(false);
                    setConfState(true);
                }
                else if (response.data.result == "error") {
                    setDisableButton(false);
                }
            }).catch(e => {
                setDisableButton(false);
            });
        }
    }

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != undefined && event.target.files?.length > 0) {
            setValidFiles(true);
            let files = event.target.files;
            var items = Array.from(files).slice(0, 3);
            setAttachments(items);

            Array.from(items).map((file: any, i: number) => {
                if ((file.size / 1024) > 2000) {
                    setValidFiles(false);
                }
            })
        }
    }

    return (
        <div className="mail-support-container">
            <div className="has-form-content-area" style={{ display: !confState ? 'block' : 'none' }}>
                <div className="subject">
                    <h1 className="has-text-weight-bold is-size-5 my-2">Asunto:</h1>
                    <FormInput placeHolder={'Escribe el asunto'} backgroundColor={'#f0f0f0'} color={'#212121 !important'}
                        fullWidth={true} validationMessage={"El asunto es necesario"} value={subject}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubject(e.target.value)}
                        required={true} validate={validate} />
                </div>
                <div className="question-area mt-3">
                    <h1 className="has-text-weight-bold is-size-5 my-2">¿En qué podemos ayudarte?</h1>
                    <textarea className="textarea" placeholder="Por favor se detallado en tu solicitud" value={question}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setQuestion(e.target.value)}></textarea>
                    <div className="alert-area mt-1" style={{ display: !validQuestion ? 'flex' : 'none' }}>
                        <div className="alert-icon">
                            <AiOutlineWarning />
                        </div>
                        <h1 className="ml-1 is-size-7">La pregunta es necesaria</h1>
                    </div>

                </div>
                <h1 className="has-text-weight-bold is-size-5 my-2">Archivos adjuntos: {(attachments != undefined && attachments?.length > 0) ? attachments?.length : ""}</h1>
                {
                    Array.from(attachments).map((file: any, i: number) => {
                        return (
                            <span key={i} style={{ color: (file.size / 1024) > 2000 ? "#b73f39" : "" }}>{file.name + " "}</span>
                        )
                    })
                }
                <div className="attachment-area mt-4" onClick={() => UploadFileRef.current?.click()}>
                    <input
                        accept="image/png, image/jpeg"
                        ref={UploadFileRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={onFileChange}
                        multiple={true} />
                    <BsPaperclip />
                    <h1 className="is-size-6">Agregar hasta 3 archivos</h1>
                </div>
                <div className="alert-area mt-1" style={{ display: !validFiles ? 'flex' : 'none' }}>
                    <div className="alert-icon">
                        <AiOutlineWarning />
                    </div>
                    <h1 className="ml-1 is-size-7">Hay archivos que pensan más de 2 MB</h1>
                </div>
                <div className="btn-area">
                    <ClassButton text={'ENVIAR'} height={40} width={150} colorButton={'red'} onClick={handleSupport} disabled={disableButton} />
                </div>
            </div>
            <div className="confirmation-modal" style={{ display: confState ? 'flex' : 'none' }}>
                <h1 className="has-text-white is-size-5">¡PRONTO ESTAREMOS RESPONDIENDO A TU SOLICITUD!</h1>
            </div>
        </div>
    );
};

export default Mail;