import { Widget } from "@typeform/embed-react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/context";
import { AuthHelper } from "../../shared/helpers/auth.helper";

import "./typeform.styles.scss";

interface ITypeForm {
  idTypeForm: string;
  origin?: string;
  onFormSubmit?: (state: boolean) => void;
}

const Typeform = ({ idTypeForm, origin, onFormSubmit }: ITypeForm) => {
  const appContext = useContext(AppContext);
  let loginInfo = appContext.loginInfo;
  let history = useHistory();

  const email = localStorage.getItem("email");
  const authHelper = new AuthHelper();

  const onSubmit = (event: any) => {
    onFormSubmit && onFormSubmit(false);
    if (origin !== "/profile") {
      email && authHelper.updateShowForm(email);
      loginInfo.showForm = false;
      appContext.dispatch(loginInfo);
    }
    origin ? history.push(origin) : history.push("/home");
  };

  return (
    <div className="typeform-main-container">
      <Widget
        id={idTypeForm}
        style={{ width: "100%", height: "100%", borderRadius: "none" }}
        onSubmit={onSubmit}
        hidden={{ email: email ? email : "" }}
      />
    </div>
  );
};

export default Typeform;
