import { useState, useEffect, useContext } from "react";
import NavbarProfile from "../navbar-profile/navbar-profile.component";
import ProfileRouter from "../profile-router/profile-router.component";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";
import { User } from "../../interfaces/user";
import { AppContext } from "../../context/context";

import "./profile.styles.scss";

interface props {
  collapse:any
}

const Profile = ({collapse}:props) => {
  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;
  const careerId = appContext.loginInfo.infoCareers.careerId;
  const [activeComponent, setActiveComponent] = useState(1);
  const [infoUser, setInfoUser] = useState<User>();
  const [preferences, setPreferences] = useState<any>([]);

  const handleLinks = (child: number) => {
    setActiveComponent(child);
  };

  const updateInfoUserState = (user: User) => {
    setInfoUser(user);
  };

  const updatePreferenceState = (preference: any) => {
    setPreferences(preference);
  };

  useEffect(() => {
    setInfoUser(appContext.userInfo.userInfo);
    setPreferences(appContext.userInfo.preferences);
  }, [appContext]);

  return (
    <div className="main-community-container">
      <div
        className="top-section"
        style={{
          zIndex: tourName === "MI PERFIL" ? 105 : "unset",
          position: tourName === "MI PERFIL" ? "relative" : "unset",
          background: tourName === "MI PERFIL" ? "white" : "unset",
        }}
      >
        <div className={`profile-title is-flex ml-2 is-hidden-mobile ${careerId === 3 && 'change-color'}`}>
          <p className="is-size-2">M I </p>
          <p className="is-size-2 ml-5">P E R F I L</p>
        </div>
        <div className="navbar-profile my-4">
          <NavbarProfile handleLinks={handleLinks} collapse={collapse}/>
        </div>
      </div>

      <div
        className="bottom-section"
        style={{
          padding: activeComponent == 4 || activeComponent == 5 ? 0 : "",
        }}
      >
        {infoUser ? (
          <ProfileRouter
            childComponent={activeComponent}
            infoUser={infoUser}
            updateInfoUserState={updateInfoUserState}
            updatePreferenceState={updatePreferenceState}
            preferences={preferences}
          />
        ) : (
          <div className="has-loader">
            <img src={BeMasterLoader} alt="BeMasterLoader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
