import React, { useState, useEffect, useContext } from "react";
import { BsCheck } from "react-icons/bs";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";

import "./check.styles.scss";
import { successToast } from "../../shared/helpers";

interface props {
  id: string;
  checkAllow: Boolean;
  completed: Boolean;
  changeCheckedMission: (mission: any, index: number) => void;
  handleCheck: (chState: boolean) => void;
  idCareerModule: number;
  idModuleClass: number;
  mission: any;
  indexMission: number;
  missionChecked: () => void;
}

const Check = ({
  checkAllow,
  completed,
  changeCheckedMission,
  id,
  handleCheck,
  idCareerModule,
  idModuleClass,
  mission,
  indexMission,
  missionChecked,
}: props) => {
  const [missionComplete, setMissionCompleted] = useState(completed);
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idUserCareer = career.idUserCareer;

  const [unCheckedColor, setUnCheckedColor] = useState("unchecked");
  const [checkedColor, setCheckedColor] = useState("checked");
  const eMMCareerId = 1;

  useEffect(() => {
    setMissionCompleted(completed);
    career === eMMCareerId
      ? setCheckedColor("checked-elite")
      : setCheckedColor("checked");
    career === eMMCareerId
      ? setUnCheckedColor("unchecked-elite")
      : setUnCheckedColor("unchecked");
  }, [completed]);

  const handleClick = async () => {
    // missionChecked();
    if (checkAllow) {
      setMissionCompleted(!missionComplete);
      handleCheck(!missionComplete);

      if (!missionComplete) {
        successToast("¡Has completado la misión!");
      }

      await AxiosConfig.post("/Career/SetCompletedClassMission", {
        idUserCareer,
        idClassMission: id,
        completed: !missionComplete,
      }).then((response) => {
        if (response.data.result == "success") {
          mission.userCareerProgress = [response.data.data.userCareerProgress];
          changeCheckedMission(mission, indexMission);

          let loginInfo: any = appContext.loginInfo;
          loginInfo.infoCareers.careerList[selectedCareer].porcProgressLevel =
            response.data.data.porcProgressLevel;

          loginInfo.infoCareers.careerList[selectedCareer].idCurrentModule =
            idCareerModule;
          loginInfo.infoCareers.careerList[selectedCareer].idCurrentClass =
            idModuleClass;
          loginInfo.infoCareers.careerList[
            selectedCareer
          ].idCurrentClassMission = id;
          appContext.dispatch(loginInfo);
        }
      });
    }
  };

  return (
    <div
      className={`main ${missionComplete ? checkedColor : unCheckedColor}`}
      onClick={handleClick}
    >
      <div
        className="check-icon has-text-white is-size-4"
        style={{ display: missionComplete ? "flex" : "none" }}
      >
        <BsCheck />
      </div>
    </div>
  );
};

export default Check;
