import React, { KeyboardEventHandler } from 'react';

import './input-box.styles.scss';

type props = {
    type?: string,
    placeHolder: string,
    onChange?: React.ChangeEventHandler,
    keyPressed?: (keyPressed: any) => void,
    required?: boolean,
    value?: any,
    styles?: React.CSSProperties,
}

const InputBox = ({type='text', placeHolder, onChange, keyPressed, required=false, value, styles}: props) => {
    
    const handleOnKeyPress = (e: KeyboardEventHandler) => {
        if (keyPressed) {
            keyPressed(e);
        }
    }

    return (
        <div>
            <input className="input is-rounded is-default-textbox mb-1"
                type={ type }
                placeholder={ placeHolder }   
                onChange={onChange}  
                onKeyPress={(e: any) => handleOnKeyPress(e)}
                required={required}    
                value={value}      
                style={styles}                    
            />
        </div>
    );
};

export default InputBox;