import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { config } from "../../config";
import { AppContext } from "../../context/context";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import { IBannersData, getBanners } from "./banners.data";
import "./video.styles.scss";

type props = {
  careerName?: string;
  careerDetails?: string;
  videoUrl?: string;
};

const VideoComponent = ({ careerName = "", careerDetails = "" }: props) => {
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const careerId = infoCareer.careerList[infoCareer.selectedCareer].idCareer;
  const mobileScreen = useMobileScreen();
  const [bannersData, setBannersData] = useState<IBannersData[]>();

  let history = useHistory();

  let bannerSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000,
  };

  useEffect(() => {
    (async () => {
      const banners = await getBanners();
      setBannersData(banners);
    })();
  }, []);

  return (
    <div className="home-carousel">
      <Slider {...bannerSettings}>
        {bannersData &&
          bannersData.filter(
            (bannerInfo: IBannersData) => bannerInfo.careerId === careerId
          ).map((banner) => {
            return (
              mobileScreen
                ? banner.bannersMobileImages
                : banner.bannersDesktopImages
            ).map((img, j) => {
              return (
                <div className="wrap" key={j}>
                  <div
                    onClick={() =>
                      banner.bannersUrl[j][0] === "/"
                        ? history.push(banner.bannersUrl[j])
                        : window.open(banner.bannersUrl[j])
                    }
                    className="has-background-video has-down-info"
                    style={{
                      backgroundImage: `url(${config.url.images.homeBanner}${img})`,
                      backgroundPositionY: `${j === 0 ? "top" : "bottom"}`,
                    }}
                  />
                </div>
              );
            });
          })}
      </Slider>
    </div>
  );
};

export default VideoComponent;
