import React from 'react';
import {BsPlay} from 'react-icons/bs';

import './button-personalized.styles.scss';

type props = {
    borderRadius?: string;
    icon?: string,
    label: string,
    onClick?: React.MouseEventHandler,
    disabled?: boolean,
    hasIcon?: boolean,
    fontSize?: number,
    iconSize?: number, 
    width?: string | number,
    height?: number,
    color?: boolean,
    hasBorder?: boolean,
    hasShadow?: boolean,
    padding?: string;
    margin?: string,
}

const ButtonPersonalized = ({ borderRadius, label, onClick, disabled = false, hasIcon = false, fontSize, iconSize, height, width=250, color = true,
        hasBorder = false, hasShadow = true, padding, margin="0 0 10px 0" }: props) => {
    return (
        <div className="is-custom-button" style={{ margin: margin }}>
            <button 
                className="button is-primary is-fullwidth is-size-7 hover"
                onClick={onClick}
                disabled={disabled}
                style={{width: width, borderRadius: borderRadius ? borderRadius : '', backgroundColor: !color ? 'transparent' : '',
                    border: hasBorder? 'solid 2px white' : '', height: height,
                    boxShadow: !hasShadow? 'none !important' : '', padding: padding && padding}} >

                <div className="icon-section" style = {{display: hasIcon? 'flex' : 'none', fontSize: iconSize }}> 
                    <BsPlay />
                </div>
                <div className="label-section" style={{fontSize: fontSize}}>
                    {label}
                </div>    
            </button>
        </div>
    );
};

export default ButtonPersonalized;