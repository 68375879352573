import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import './button-back.styles.scss';

interface Props {
    collapse?:boolean;
}

const ButtonBack = ({collapse}:Props) => {

    let history = useHistory<any>();
    
    const goBack = () => { 
        history.goBack(); 
    }
    
    return (
        <div className={`back-button-main-container ${collapse && 'hide'}`}>
            <AiOutlineArrowLeft onClick={goBack} className={'back-arrow'} />
        </div>
    );
};

export default ButtonBack;