import { useContext } from "react";
import { BlackLogo } from "../../shared/components/brand/black-logo/black-logo.component";
import { AppContext } from "../../context/context";
import ProgressBar from "../progress-bar/progress-bar.component";

import "./navbar-classroom.styles.scss";

type props = {
  path?: string;
  breadcrumb?: string;
  visible: boolean;
  sideBarCollapse?: () => void;
  collapse: boolean;
};

const NavbarClassroom = ({
  path = "/ classroom",
  breadcrumb = "",
  visible = false,
  sideBarCollapse,
  collapse,
}: props) => {
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];

  let level: string = career.level;

  return (
    <div
      className={`classroom-navbar ${
        path == "/classroom" ? "is-classroom-navbar" : ""
      }
            ${path == "/support" ? "support-nav-fixed" : ""}`}
      style={{ display: visible ? "flex" : "none" }}
    >
      <div
        className={`is-nav-section ${path == "/profile" ? "align-arrow" : ""}`}
      >
        <div
          className={`top-nav-section ${
            path == "/classroom" ? "classroom-navbar-fixed" : ""
          } ${path == "/profile" ? "profile-navbar-fixed" : ""}
                    ${path == "/support" ? "support-navbar-fixed" : ""}`}
        >
          {/* <div className="is-arrow-icon" onClick={sideBarCollapse}
                        style={{ display: collapse ? 'flex' : 'none', transform: 'rotate(0deg)', marginLeft: window.innerWidth == 375 ? '0% !important' : '' }}></div> */}
          <div
            className={`is-brand-section mr-4 ${
              path == "/classroom" ? "adjust-brand" : ""
            }`}
          >
            <BlackLogo />
          </div>
        </div>
        {(career.idCareer === 1 ||
          career.idCareer === 2 ||
          career.idCareer === 6) && (
          <div className="navbar-start">
            {/* <a className="navbar-item dark is-hidden-mobile"> {breadcrumb.toUpperCase().replace('/', '/ ')} </a> */}
            <div
              className="navbar-item ml-6"
              style={{ display: path === "/classroom" ? "flex" : "none" }}
            >
              {/* <ProgressBar /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarClassroom;
