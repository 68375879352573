import React, { useContext, useEffect, useState } from "react";
import AxiosConfig from "../../axios/axiosConfig";
import Typeform from "../../components/typeform/typeform.component";
import { config } from "../../config";
import { CareerContext } from "../../context/careerContext";
import { AppContext } from "../../context/context";
import SideBar from "../../shared/components/side-bar/sidebar.component";
import { CareerService } from "../../shared/helpers/career.helper";
import NavbarClassroom from "../navbar-classroom/navbar-classroom.component";
import Navbar from "../navbar/navbar.component";
import TourLayout from "./tour/tour.component";

import { NavbarAuthorization } from "../../shared/helpers/main-layout.helper";
import FloatSidebar from "../float-sidebar/float-sidebar.component";
import NavbarMobile from "../navbar/navbar-mobile/navbar-mobile.component";
import "./main-layout.styles.scss";

type props = {
  children: React.ReactNode;
  path: string;
  navbarComponent: React.ReactNode;
  backgroundClass: string;
  contentMargin?: Boolean;
};

const MainLayout = ({
  children,
  path,
  navbarComponent,
  backgroundClass = "has-background-image is-back-layer",
  contentMargin = true,
}: props) => {
  const appContext = useContext(AppContext);
  const careerContext = useContext(CareerContext);
  const careerData = careerContext.careerInfo.careerProgress;
  const showForm = appContext.loginInfo.showForm;
  const typeFormUrls = appContext.loginInfo.initialForm;
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [typeformId, setTypeformId] = useState<any>();

  useEffect(() => {
    let formByCareer = typeFormUrls
      .filter((forms: any) => forms.idCareer === careerId)
      .map((form: any) => form.url);
    formByCareer =
      formByCareer?.length === 0
        ? formByCareer
            .filter((form: any) => form.idCareer === null)
            .map((item: any) => item.url)
        : formByCareer;
    formByCareer && setTypeformId(formByCareer[0]);
  }, [appContext.loginInfo.initialFormId]);

  const infoCareer: any = appContext.loginInfo.infoCareers;
  const eMMCareerId = 3;

  const careerId = appContext.loginInfo.infoCareers.careerId;

  let frontLayerClass = "",
    mainSectionClass = "";

  const [sidebarState, setSideBarState] = useState(true);

  const sideBarCollapse = () => {
    setSideBarState(!sidebarState);
  };

  const handlecollapse = (state: boolean) => {
    setSideBarState(state);
  };

  // useEffect(() => {
  //   (async () => {
  //     if (!careerData.find((career) => career.careerId === careerId)) {
  //       const percProgress = await objCareerService.getCareerProgress();
  //       careerData.push({
  //         careerId: careerId,
  //         careerProgress: percProgress,
  //       });
  //       careerContext.dispatchCareerInfo(careerData);
  //     }
  //   })();
  // }, [careerId]);

  useEffect(() => {
    let userInfo = appContext.userInfo;
    const fetchData = () => {
      AxiosConfig.get("/Account/GetInfoUser").then((result) => {
        if (result.data.result === "success") {
          userInfo.userInfo = result.data.data.user;
          userInfo.preferences = result.data.data.preferences;

          let preference = result.data.data.preferences;
          let darkMode = preference.filter(
            (preference: any) => preference.idPreference === 5
          );

          if (darkMode.length !== 0) {
            document.documentElement.className = "";
            document.documentElement.classList.add(
              `theme-${
                darkMode[0].userPreference[0]?.active
                  ? "dark"
                  : careerId === 10
                  ? "prime"
                  : "light"
              }`
            );
          }

          appContext.dispatchUserInfo(userInfo);
        }
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    setShowOnboarding(appContext.loginInfo.showOnboarding);
    if (appContext.loginInfo.showOnboarding) {
      const fetchData = async () => {
        await AxiosConfig.put("/Account/CompleteOnboarding").then((result) => {
          if (isSubscribed) {
            if (result.data.result === "success") {
              let loginInfo = appContext.loginInfo;
              loginInfo.showOnboarding = false;
              appContext.dispatch(loginInfo);
            }
          }
        });
      };
      fetchData();
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  if (backgroundClass === "has-custom-background") {
    frontLayerClass = "hero";
  } else {
    frontLayerClass = "is-front-layer hero";
  }

  if (contentMargin) {
    mainSectionClass = "is-content-section";
  } else {
    mainSectionClass = "is-class-content-section";
  }

  return (
    <>
      {showForm && typeformId ? (
        <Typeform idTypeForm={typeformId} origin={"/powerclass"} />
      ) : (
        <div
          className={`${
            careerId === eMMCareerId && path !== "/classroom"
              ? "has-elite-background"
              : backgroundClass
          }
            ${backgroundClass === "tools-layer" ? backgroundClass : ""} `}
          style={{
            backgroundImage:
              backgroundClass === "tools-layer"
                ? `url(${config.url.images.tools}tools-background.jpg)`
                : "",
          }}
        >
          <div
            className={`${frontLayerClass} ${
              backgroundClass === "is-support-layer" ? "support-layer" : ""
            } `}
          >
            {careerId !== 10 && careerId !== 11 && careerId !== 12 ? (
              <SideBar
                collapse={sidebarState}
                handlecollapse={handlecollapse}
              ></SideBar>
            ) : (
              <FloatSidebar />
            )}
            {(showOnboarding || appContext.tourInfo.onTour) &&
              careerId !== 10 &&
              careerId !== 11 &&
              careerId !== 12 && <TourLayout />}

            <div className="is-home-container">
              <Navbar
                sideBarCollapse={sideBarCollapse}
                visible={NavbarAuthorization(path)}
                collapse={sidebarState}
                path={path}
              />
              <NavbarClassroom
                sideBarCollapse={sideBarCollapse}
                visible={path === "/classroom" || path === "/form"}
                collapse={sidebarState}
                path={path}
              />

              <div
                style={{
                  padding:
                    careerId === 10 && path === "/support"
                      ? "0 !important"
                      : "0 0 0 4% !important",
                }}
                className={`${mainSectionClass} ${
                  path.toLowerCase() == "/community"
                    ? "community-custom-class"
                    : ""
                } ${
                  path.toLowerCase() === "/powerclass"
                    ? "is-pclass-content-section"
                    : ""
                }
                        ${
                          path.toLowerCase() === "/mentortalks"
                            ? "is-mentor-content-section"
                            : ""
                        } ${
                  path.toLowerCase() === "/home"
                    ? "is-home-content-section"
                    : ""
                }
                        ${
                          path.toLowerCase() === "/profile"
                            ? "is-profile-content-section"
                            : ""
                        } ${
                  path.toLowerCase() === "/shopping"
                    ? "is-shopping-content-section"
                    : ""
                }
            ${
              path.toLowerCase() === "/support"
                ? "is-support-content-section"
                : ""
            }
                        ${
                          path.toLowerCase() === "/firesShop"
                            ? "is-fire-store-content-section"
                            : ""
                        }
                        ${
                          path.toLowerCase() === "/afiliacion"
                            ? "is-affiliates-content-section"
                            : ""
                        } ${
                  path.toLowerCase() === "/tools"
                    ? "is-tools-content-section"
                    : ""
                }
                        ${
                          path.toLowerCase() === "/lista-herramientas/:id"
                            ? "is-tools-content-section"
                            : ""
                        } ${
                  path.toLowerCase() === "/calculator/:id"
                    ? "is-tools-content-section"
                    : ""
                }
                        ${
                          path.toLowerCase() === "/powerclass/:id"
                            ? "is-videolist-content-section"
                            : ""
                        }`}
              >
                <NavbarMobile />
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
