import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext, startInfo } from "../../../context/context";
import "./settings-container.styles.scss";

type props = {
  showContent: Boolean;
};

const SettingsContainer = ({ showContent }: props) => {
  const [itemAddedAnimation, setItemAddedAnimation] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const careerList: any = appContext.loginInfo.infoCareers.careerList;
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idCareer = career.career.idCareer;
  let history = useHistory();
  
  useEffect(() => {
    setItemAddedAnimation(true);
  }, [careerList]);  

  const handleClick = () => {
    localStorage.clear();
    appContext.dispatch(startInfo());
    history.push("/");
  };

  const supportLink = () => {
    history.push("/support");
  };

  const accountLink = () => {
    history.push("/profile");
  };

  const careersList = () => {
    history.push("/careers");
  };

  const showTour = () => {
    const tourInfo = appContext.tourInfo;
    tourInfo.onTour = true;
    appContext.dispatchTour(tourInfo);
  };

  return (
    <div
      className={`settings-container p-3 ${itemAddedAnimation ? 'heartbeat' : ''}`}
      style={{
        opacity: showContent ? "1" : "0",
        transition: "opacity .25s",
        pointerEvents: showContent ? "unset" : "none",
      }}
    >
      <div className="links-section">
        <div className="account-link mb-2">
          <span className="profile-link is-size-6" onClick={accountLink}>
            <p className="link">Mi perfil</p>
          </span>
        </div>
        {careerList.length > 1 && (
          <span className="careers-link" onClick={careersList}>
            <p className="link">Carreras</p>
          </span>
        )}
        {idCareer !== 9 && idCareer !== 10 && idCareer !== 11 && idCareer !== 12 ? (
          <span className="help-link" onClick={showTour}>
            <p className="link">Realizar tour</p>
          </span>
        ) : null}
        {idCareer !== 9 ? (
          <>
            <span className="help-link" onClick={supportLink}>
              <p className="link">Ayuda BeMaster</p>
            </span>
          </>
        ) : null}
        <span className="logout-link" onClick={handleClick}>
          <p className="link">Cerrar sesión</p>
        </span>
      </div>
    </div>
  );
};

export default SettingsContainer;
