import Vimeo from "@vimeo/player";
import { useContext, useEffect, useRef } from "react";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import { successToast } from "../../shared/helpers";
import VideoReact from "../video-react/video-react.component";

import "./class-video.styles.scss";

type props = {
  mission: any;
  indexMission: number;
  changeCheckedMission: (mission: any, index: number) => void;
  idCareerModule: number;
};

const ClassVideo = ({
  mission,
  indexMission,
  changeCheckedMission,
  idCareerModule,
}: props) => {
  const vimeoRef = useRef<HTMLIFrameElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const vdocipherRef = useRef<HTMLDivElement>(null);
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idUserCareer = career.idUserCareer;

  let [userCareerProgress] = [...mission.userCareerProgress];
  let completed =
    userCareerProgress == undefined ? false : !!userCareerProgress.completed;

  useEffect(() => {
    if (vimeoRef.current != null && !completed) {
      let vimeoPlayer = new Vimeo(vimeoRef.current);

      vimeoPlayer.on("progress", function (data) {
        if (data.percent * 100 > 98) {
          vimeoPlayer.off("progress");
          markMissionCompleted();
        }
      });
    }
  }, [vimeoRef, indexMission]);

  useEffect(() => {
    if (vdocipherRef.current != null) {
      let video = new (window as any).VdoPlayer({
        //otp: "20160313versASE323L1t2tffqc5AekG6tLK82OPHrGC8vkVIfR6x7WsztpwivjG",
        //playbackInfo: "eyJ2aWRlb0lkIjoiZGEzZWE4ZWRjZDc2MjQ4ZDJlZWZlYTk3OWYzNGFiNjgifQ==",
        otp: mission.otp,
        playbackInfo: mission.playbackInfo,
        theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
        // the container can be any DOM element on website
        container: document.querySelector("#vdocipherRef"),
      });
    }
  }, [vdocipherRef, indexMission]);

  var mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
  var totalSegments = 5;
  var segmentLength = 0;
  var segmentDuration = 0;
  var bytesFetched = 0;
  var requestedSegments: any = [];
  var mediaSource: MediaSource;

  useEffect(() => {
    if (videoRef.current != null) {
      for (var i = 0; i < totalSegments; ++i) requestedSegments[i] = false;

      if ("MediaSource" in window && MediaSource.isTypeSupported(mimeCodec)) {
        mediaSource = new MediaSource();
        videoRef.current.src = URL.createObjectURL(mediaSource);
        mediaSource.addEventListener("sourceopen", sourceOpen);
      } else {
        console.error("Unsupported MIME type or codec: ", mimeCodec);
      }
    }
  }, [videoRef]);

  var sourceBuffer: any;
  function sourceOpen(_: any) {
    if (videoRef.current != null && videoRef.current != undefined) {
      sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
      getFileLength(mission.url, function (fileLength: any) {
        //totalLength = fileLength;
        segmentLength = Math.round(fileLength / totalSegments);
        fetchRange(mission.url, 0, segmentLength, appendSegment);
        requestedSegments[0] = true;
        videoRef.current?.addEventListener("timeupdate", checkBuffer);
        videoRef.current?.addEventListener("canplay", function () {
          segmentDuration =
            videoRef.current == undefined
              ? 0
              : videoRef.current?.duration / totalSegments;
          videoRef.current?.play();
        });
        videoRef.current?.addEventListener("seeking", seek);
      });
    }
  }

  function getFileLength(url: string, cb: any) {
    var xhr = new XMLHttpRequest();
    xhr.open("head", url);
    xhr.onload = function () {
      cb(xhr.getResponseHeader("content-length"));
    };
    xhr.send();
  }

  function fetchRange(url: string, start: number, end: number, cb: any) {
    var xhr = new XMLHttpRequest();
    xhr.open("get", url);
    xhr.responseType = "arraybuffer";
    xhr.setRequestHeader("Range", "bytes=" + start + "-" + end);
    xhr.onload = function () {
      bytesFetched += end - start + 1;
      cb(xhr.response);
    };
    xhr.send();
  }

  function appendSegment(chunk: any) {
    sourceBuffer.appendBuffer(chunk);
  }

  function checkBuffer(_: any) {
    var currentSegment = getCurrentSegment();
    if (currentSegment === totalSegments && haveAllSegments()) {
      mediaSource.endOfStream();
      videoRef.current?.removeEventListener("timeupdate", checkBuffer);
    } else if (shouldFetchNextSegment(currentSegment)) {
      requestedSegments[currentSegment] = true;
      fetchRange(
        mission.url,
        bytesFetched,
        bytesFetched + segmentLength,
        appendSegment
      );
    }
  }

  function seek(e: any) {
    if (mediaSource.readyState === "open") {
      sourceBuffer.abort();
    }
  }

  function getCurrentSegment() {
    return (
      ((videoRef.current == undefined
        ? 0
        : videoRef.current?.currentTime / segmentDuration) |
        0) +
      1
    );
  }

  function haveAllSegments() {
    return requestedSegments.every(function (val: any) {
      return !!val;
    });
  }

  function shouldFetchNextSegment(currentSegment: any) {
    return videoRef.current == undefined
      ? 0
      : videoRef.current?.currentTime >
          segmentDuration * currentSegment * 0.8 &&
          !requestedSegments[currentSegment];
  }

  const markMissionCompleted = async () => {
    await AxiosConfig.post("/Career/SetCompletedClassMission", {
      idUserCareer,
      idClassMission: mission.idClassMission,
      completed: true,
    }).then((response) => {
      if (response.data.result == "success") {
        mission.userCareerProgress = [response.data.data.userCareerProgress];
        changeCheckedMission(mission, indexMission);
        successToast("Has completado la misión exitosamente");

        let loginInfo: any = appContext.loginInfo;
        loginInfo.infoCareer.careerList[selectedCareer].porcProgressLevel =
          response.data.data.porcProgressLevel;

        loginInfo.infoCareer.careerList[selectedCareer].idCurrentModule =
          idCareerModule;
        loginInfo.infoCareer.careerList[selectedCareer].idCurrentClass =
          mission.idModuleClass;
        loginInfo.infoCareer.careerList[selectedCareer].idCurrentClassMission =
          mission.idClassMission;
        appContext.dispatch(loginInfo);
      }
    });
  };

  switch (mission.videoPlayer) {
    case "Vimeo":
      return (
        <div className="class-video-container">
          <iframe
            ref={vimeoRef}
            src={mission.url}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
        </div>
      );
      break;
    case "Facebook":
      return (
        <div className="class-video-container">
          <iframe
            src={mission.url}
            allowFullScreen
            width="100%"
            height="100%"
            scrolling="no"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      );
      break;
    case "Vdocipher":
      return (
        <div className="class-video-container">
          <div
            ref={vdocipherRef}
            id="vdocipherRef"
            style={{ width: "100%", maxWidth: "100%", height: "100%" }}
          ></div>
        </div>
      );
      break;
    case "Custom":
      return (
        <div className="class-video-container">
          <VideoReact
            src={mission.url}
            autoplay={false}
            srcPoster={mission.urlThumbnail}
            buttonClass={"custom"}
          />
        </div>
      );
      break;

    default:
      return (
        <div className="class-video-container">
          <VideoReact
            src={mission.url}
            autoplay={false}
            buttonClass={"custom"}
          />
        </div>
      );
      break;
  }
};

export default ClassVideo;
