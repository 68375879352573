import React, { useEffect, useContext } from "react";
import GlobalSlider from "../global-slider/globalSlider";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";

import "./triumphs-list.styles.scss";

//The API must to return a JSON with all user's triumph inside
const TriumphsList = () => {
  const appContext = useContext(AppContext);
  let loginInfo = appContext.loginInfo;
  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      await AxiosConfig.get("/Triumph/GetUserTriumphs").then((result) => {
        if (isSubscribed) {
          if (result.data.result === "success") {
            loginInfo.infoTriumphs = {
              listUserTriumphs: result.data.data.listUserTriumphs,
            };
            appContext.dispatch(loginInfo);
          }
        }
      });
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className="is-triumph-list-section">
      <div className="is-content-section">
        <GlobalSlider />
      </div>
    </div>
  );
};

export default TriumphsList;
