import { useContext, useState, useEffect } from "react";
import { CgPlayTrackNext } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/context";
import { useCurrentCareerId } from "../../../hooks/useCareer";
import { useMobileScreen } from "../../../hooks/useMobileScreen";
import { Video } from "../../../interfaces/video";
import ButtonPersonalized from "../../../shared/components/buttons/button-personalized/button-personalized.component";
import "./sidebar.styles.scss";

type props = {
  video: Video;
  nextVideoName: string;
  idModuleClass?: number;
  idToolCategory?: number;
  idCareerModule?: number;
  idClassMission?: number;
};

export const Sidebar = ({
  video,
  nextVideoName,
  idModuleClass,
  idToolCategory,
  idCareerModule,
  idClassMission,
}: props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const careerId = useCurrentCareerId();
  const mobileScreen = useMobileScreen();

  const [show, setShow] = useState(false);

  const playNextVideo = () => {
    window.location.replace(`/video?id=${video.idNextVideo}`);
  };

  const goToTool = () => {
    // history.push(`/lista-herramientas/${idToolCategory}`);
    window.open(video.urlResource, "_blank");
  };

  const goToClassroom = () => {
    if (idModuleClass) {
      const loginInfo = appContext.loginInfo;
      const selectedCareer = loginInfo.infoCareers.selectedCareer;
      loginInfo.infoCareers.careerList[selectedCareer].idCurrentClass =
        idModuleClass;
      loginInfo.infoCareers.careerList[selectedCareer].idCurrentModule =
        idCareerModule;
      loginInfo.infoCareers.careerList[selectedCareer].idCurrentClassMission =
        idClassMission;

      appContext.dispatch(loginInfo);
    }
    history.push("/classroom");
  };

  return (
    <>
      <div
        className="click-away"
        style={{
          pointerEvents: show ? "auto" : "none",
          opacity: show ? ".5" : "0",
        }}
        onClick={() => setShow(false)}
      />

      <div
        className="video-menu"
        onMouseLeave={() => setShow(false)}
        style={{
          right: show ? "0" : "-400px",
        }}
      >
        <div className="video-title">{video.name}</div>
        <div className="video-subtitle">{video.category}</div>

        <div className="video-description">{video.description}</div>

        {video.urlResource && (
          <div className="video-button-container" onClick={goToTool}>
            <ButtonPersonalized label="RECURSOS" />
          </div>
        )}

        {video.idNextVideo && (
          <div className="next-video-container" onClick={playNextVideo}>
            <h5>{nextVideoName}</h5>
            <CgPlayTrackNext size={32} />
          </div>
        )}
        {careerId !== 10 ? (
          <div className="return-classroom" onClick={goToClassroom}>
            <div className="return-title">IR AL CLASSROOM</div>
            <div
              className="return-icon"
              style={{
                backgroundImage:
                  "url('https://cdn.bemaster.com/media/icons/home/Sitiobemaster36.svg')",
              }}
            ></div>
          </div>
        ) : null}
      </div>
      <div
        className="video-sidebar"
        onMouseEnter={() => setShow(true)}
        style={{
          right: show ? "-100px" : "0",
        }}
      >
        <img
          className="icon"
          src="https://cdn.bemaster.com/media/icons/home/Sitiobemaster34.svg"
          alt=""
          style={{ transform: "rotate(180deg)" }}
        />
      </div>
    </>
  );
};
