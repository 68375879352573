import { useRef, useState, useEffect } from "react";
import ResolutionButton from "./buttons/resolution-button/resolution-button.component";
import HlsSource from "../video-react-hls-source/video-react-hls-source.component";
import { BiSkipNext } from "react-icons/bi";
import { useBeforeunload } from "react-beforeunload";
import AxiosConfig from "../../axios/axiosConfig";

import "video-react/dist/video-react.css";
import "./video-react.styles.scss";

import ReactPlayer from "react-player";
import { IoMdReturnLeft } from "react-icons/io";
import { useHistory } from "react-router";

// const ReactPlayer = require("video-react");

const {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton,
} = require("video-react");

interface props {
  src: string;
  autoplay?: boolean;
  onVideoEnd?: () => void;
  onProgress?: (percent: number) => void;
  onVideoPaused?: () => void;
  onVideoPlayed?: () => void;
  volume?: boolean;
  buttonClass?: string;
  srcPoster?: any;
  idNextVideo?: string | number | undefined;
  idContent?: number;
  progress?: number;
}

type LocationState = {
  from?: string;
};

const VideoReact = ({
  src,
  autoplay = true,
  onVideoEnd = () => {},
  onProgress = (percent: number) => {},
  onVideoPaused = () => {},
  onVideoPlayed = () => {},
  volume = true,
  srcPoster = "",
  buttonClass = "center",
  idNextVideo,
  idContent,
  progress,
}: props) => {
  let duration;
  const videoPlayer = useRef<any>();
  const [videoState, setVideoState] = useState<any>(null);
  const [hasEnded, setHasEnded] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const [urlThumbnail, setUrlThumbnail] = useState("");
  const [resolution, setResolution] = useState(-1);
  const [resolutions, setResolutions] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const history = useHistory<LocationState>();
  const fromClassroom = history.location.state?.from === "classroom";

  useEffect(() => {
    setUrlVideo(src);
    setUrlThumbnail(srcPoster);
    if (videoPlayer.current != null) {
      setUrlVideo(src);
      setUrlThumbnail(srcPoster);
      videoPlayer.current.load();
    }
  }, [src, srcPoster]);

  const handleVideoState = (state: any) => {
    setVideoState(state);
  };

  const handleNextVideo = () => {
    if (fromClassroom) {
      return history.push("/classroom");
    }

    if (idNextVideo) {
      window.location.replace(`/video?id=${idNextVideo}`);
    }
  };

  useEffect(() => {
    if (videoPlayer.current != null) {
      videoPlayer.current.subscribeToStateChange(handleVideoState);
    }
  });

  // Checking if the video has ended on every state update
  useEffect(() => {
    if (videoState && videoState.ended && !hasEnded) {
      // Preventing onVideoEnd from being called multiple times
      setHasEnded(true);
      onVideoEnd();
      duration = videoState.duration;
    }

    if (videoState && !hasEnded) {
      duration = videoState.duration;
      let currentTime = videoState.currentTime;

      if (duration > 0) {
        let percent = (currentTime * 100) / duration;
        setCurrentTime(percent);
        onProgress(percent);
      }
    }
  }, [videoState, hasEnded, setHasEnded, onVideoEnd, onProgress]);

  const fileExtension = (url: string) => {
    let fileSplit = url.split("?")[0];
    let fileExt = fileSplit.substr(fileSplit.lastIndexOf(".") + 1);
    return fileExt;
  };

  const updateProgressVideo = async (percent: number) => {
    await AxiosConfig.post("/Content/UpdateProgressVideo", {
      idContent,
      contentProgress: percent,
    });
  };

  useBeforeunload((event: any) => {
    updateProgressVideo(currentTime);
  });

  switch (fileExtension(urlVideo)) {
    case "mp4":
      return (
        <div className="video-react-player">
          {/* <Player ref={videoPlayer} autoplay={autoplay} poster={urlThumbnail}>
                        <BigPlayButton position={buttonClass} />
                        <source src={urlVideo} />
                        <ControlBar className="controlBar">
                            <ReplayControl seconds={10} order={1.1} />
                            <ForwardControl seconds={30} order={1.2} />
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <VolumeMenuButton vertical={true} />
                            <BiSkipNext size={28} order={7} style={{marginTop: "2px"}} onClick={handleNextVideo}/>
                            <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.5, 0.25]} order={7.1} />
                        </ControlBar>
                    </Player> */}
        </div>
      );
      break;
    case "m3u8":
      return (
        <div className="video-react-player">
          <Player
            ref={videoPlayer}
            autoplay={autoplay}
            poster={urlThumbnail}
            onPause={() => onVideoPaused()}
            onPlay={() => onVideoPlayed()}
          >
            <BigPlayButton position={buttonClass} />

            {videoPlayer && (
              <HlsSource
                progress={progress}
                updateProgressVideo={updateProgressVideo}
                startTime={startTime}
                isVideoChild={true}
                src={urlVideo}
                video={videoPlayer}
                autoplay={autoplay}
                resolution={resolution}
                setResolutions={setResolutions}
              />
            )}

            <ControlBar className="controlBar">
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl
                className="is-hidden-mobile"
                seconds={30}
                order={1.2}
              />
              <CurrentTimeDisplay className="is-hidden-mobile" order={4.1} />
              <TimeDivider order={4.2} />
              <VolumeMenuButton vertical={true} />

              <BiSkipNext
                size={28}
                order={7}
                style={{ marginTop: "2px" }}
                onClick={handleNextVideo}
                className="nextVideoButton is-hidden-mobile"
              />

              <PlaybackRateMenuButton
                rates={[2, 1.5, 1.25, 1, 0.5, 0.25]}
                order={7.1}
              />
              <ResolutionButton
                setStartTime={setStartTime}
                video={videoState}
                setResolution={setResolution}
                resolutions={resolutions}
                order={7}
              />
            </ControlBar>
          </Player>
        </div>
      );
      break;
    default:
      return <div></div>;
      break;
  }
};

export default VideoReact;
