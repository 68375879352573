import AxiosConfig from "../axios/axiosConfig";
import { AuthContext } from "../context/context";

export const updateCareers = async (appContext: AuthContext) => {
  let loginInfo = appContext.loginInfo;
  const SELECTED_CAREER = loginInfo.infoCareers.selectedCareer;
  const CAREER_ID = loginInfo.infoCareers.careerId;

  loginInfo.infoCareers = {
    careerList: [],
    selectedCareer: SELECTED_CAREER,
    careerId: CAREER_ID,
  };

  loginInfo.infoContent = {
    listUserContent: [], 
  };

  const response = await AxiosConfig.get("/Account/GetInfoCareer");
  loginInfo.infoCareers.careerList.push(...response.data.data.listUserCareer);
  loginInfo.infoContent.listUserContent.push(
    ...response.data.data.listUserContent
  );

  appContext.dispatch(loginInfo);
  
  return response;
};
