export const config = {
  url: {
    server: {
      localHost: "https://localhost:44386/api",
      prod: "https://api.bemaster.com/Api",
      dev: "https://devapi.bemaster.com/Api",
    },
    images: {
    shared: "https://cdn.bemaster.com/media/images/shared/",
      homeIcons: "https://cdn.bemaster.com/media/icons/home/",
      homeBanner: "https://cdn.bemaster.com/media/images/home/",
      homeBannerMobile: "https://cdn.bemaster.com/media/images/home/BannerPrincipal-sm",
      powerclasses: "https://cdn.bemaster.com/media/images/powerclasses/",
      tools: "https://cdn.bemaster.com/media/images/tools/",
      defaultProfilePhoto:
        "https://cdn.bemaster.com/media/images/profile/user-icon.png",
      powerclassLogo:
        "https://cdn.bemaster.com/media/images/powerclasses/power-logo.svg",
      mentortalkLogo:
        "https://cdn.bemaster.com/media/images/mentortalks/mentor-logo.svg",
      mentorBanner:
        "https://cdn.bemaster.com/media/images/mentortalks/Banner.png",
      tourImages: "https://cdn.bemaster.com/media/images/tour",
      insignia: "https://cdn.bemaster.com/media/images/shared/Insignia_V2.png",
    },
    videos: {
      powerclasses: "https://cdn.bemaster.com/media",
      modal_pclass:
        "https://cdn.bemaster.com/media/videos/powerclasses/01_videoventa/pclass_",
      modal_mtalks:
        "https://cdn.bemaster.com/media/videos/mentortalks/01_videoventa/",
    },
  },
  regex: {
    // eslint-disable-next-line
    email:
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
    hasUppercase: /[A-Z]/g,
    hasLowercase: /[a-z]/g,
    hasNumber: /[0-9]/g,
  },
};
