import axios from "axios";
// import { config } from "../config";

const instance = axios.create({
  //..where we make our configurations
  baseURL: process.env.REACT_APP_URL_API,
});

if (localStorage.getItem("token") != null) {
  instance.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    localStorage.clear();
    window.location.replace("/");
    delete instance.defaults.headers.common["Authorization"];
  }
);

export default instance;
