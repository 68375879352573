import React from "react";
import { BsFacebook } from "react-icons/bs";
import "./facebook-button.styles.scss";

interface FacebookProps {
  urlCommunity: string;
}

const FacebookButton = ({ urlCommunity }: FacebookProps) => {
  return (
    <div
      className="facebook-button"
      onClick={() => {
        window.open(`${urlCommunity}`, "_blank");
      }}
    >
      <BsFacebook />
    </div>
  );
};

export default FacebookButton;
