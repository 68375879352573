import instance from "../../axios/axiosConfig";

export class AuthHelper {
  updateShowForm = async (email: string) => {
    let response;
    try {
      response = await instance.post("/Account/UpdateShowForm", {
        email: email,
      });
    } catch (error) {
      console.log(error);
    }

    return response;
  };
}
