import { useEffect, useState } from "react";
import AxiosConfig from "../../../axios/axiosConfig";
import SettingsContainer from "../settings-container/settings-container.component";
import "./settings.styles.scss";

type props = {
  value: Boolean;
  switchGlobalState: Function;
};

const Settings = ({ value, switchGlobalState }: props) => {
  const [urlPhoto, setUrlPhoto] = useState<string>("");

  useEffect(() => {
    getPhoto();
  }, []);

  const getPhoto = () => {
    AxiosConfig.get("/Account/GetInfoUser").then((result) => {
      if (result.data.result === "success") {
        setUrlPhoto(result.data.data.user.photo);
      }
    });
  };

  const handleClick = () => {
    switchGlobalState();
  };

  //Usar el bjeto infoCareers para obtener la información de las carreras del usuario

  return (
    <div className="parent-settings-container is-flex">
      <div className="child-settings-container" onClick={handleClick}>
        {urlPhoto ? (
          <img src={urlPhoto} alt="" />
        ) : (
          <i className="is-settings-icon icon"></i>
        )}
      </div>
      <div className="description-content">
        <div
          className="is-a-icon"
          onClick={handleClick}
          style={{ zIndex: value ? 101 : 0, opacity: value ? "1" : "0" }}
        ></div>
        <div
          className="click-away"
          onClick={handleClick}
          style={{
            opacity: value ? "1" : "0",
            pointerEvents: value ? "unset" : "none",
          }}
        ></div>
        <SettingsContainer showContent={value} />
      </div>
    </div>
  );
};

export default Settings;
