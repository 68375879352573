import React, { useContext, useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import { AppContext } from "../../context/context";
import MissionItem from "../auth/components/mission-item/mission-item.component";
import RewardPadlock from "../reward/reward-padlock.component";
import Rewards from "../reward/rewards.component";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";

import "./missions.styles.scss";
interface props {
  updateChildComponent: (mission: any, index: number) => void;
  classMission: Array<any>;
  clsAward: Array<any>;
  changeCheckedMission: (mission: any, index: number) => void;
  idCareerModule: number;
  loading: Boolean;
}

const Missions = ({
  updateChildComponent,
  classMission,
  clsAward,
  changeCheckedMission,
  idCareerModule,
  loading,
}: props) => {
  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;

  const infoCareer: any = appContext.loginInfo.infoCareers;
  const careerId = infoCareer.careerList[infoCareer.selectedCareer].idCareer;
  const eMMCareerId = 3;

  const [statePadlock, setStatePadlock] = useState(0);
  const [showLoader, setShowLoader] = useState<Boolean>(false);

  useEffect(() => {
    setShowLoader(loading);
  }, [loading]);

  const handleClick = (mission: any, index: number) => {
    updateChildComponent(mission, index);
  };

  const updateRewardPadlock = (state: number) => {
    setStatePadlock(state);
  };

  const missionChecked = () => {
    setShowLoader(true);
  };

  return (
    <>
      {showLoader && (
        <div className="loading-layout">
          <div className="bemaster-loader">
            <img src={BeMasterLoader} alt="BeMasterLoader" />
          </div>
        </div>
      )}
      <div
        className="mission-container"
        style={{
          zIndex:
            tourName === "MISIONES" ||
            tourName === "QUICES" ||
            tourName === "WORKBOOKS"
              ? 105
              : "unset",
          position:
            tourName === "MISIONES" ||
            tourName === "QUICES" ||
            tourName === "WORKBOOKS"
              ? "relative"
              : "unset",
        }}
      >
        <div className="top-mission-section">
          <div className={`is-flex mission-title`}>
            <h1 className="has-custom-color is-size-4 has-text-weight-bold">
              {careerId == eMMCareerId ? "AGENDA DEL DÍA" : "MISIONES"}
            </h1>
          </div>
          <div className="checks-section mt-2">
            <div className="mission-item is-flex">
              <Scrollbar style={{ width: 283, height: 275 }}>
                <div className="m-item-container">
                  {classMission
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((mission, i) => {
                      let [userCareerProgress] = [
                        ...mission.userCareerProgress,
                      ];
                      let completed =
                        userCareerProgress == undefined
                          ? false
                          : !!userCareerProgress.completed;
                      let checkable = mission.checkable;

                      return (
                        <div key={mission.idClassMission}>
                          <MissionItem
                            id={mission.idClassMission}
                            title={mission.action}
                            completed={completed}
                            checkable={statePadlock == 0 ? checkable : false}
                            careerId={careerId}
                            eMMCareerId={eMMCareerId}
                            onClick={() => handleClick(mission, i)}
                            changeCheckedMission={changeCheckedMission}
                            idCareerModule={idCareerModule}
                            idModuleClass={mission.idModuleClass}
                            mission={mission}
                            missionChecked={missionChecked}
                            indexMission={i}
                          />
                        </div>
                      );
                    })}
                </div>
              </Scrollbar>
            </div>
          </div>
        </div>
        <div
          className={`bottom-mission-section is-flex reward-${
            statePadlock ? "unclaimed" : "claimed"
          }`}
          style={{
            zIndex: tourName === "FUEGOS" ? 105 : "unset",
          }}
        >
          <div className="title-rewards-section is-flex">
            <h1 className="has-text-weight-bold has-text-white my-2 is-size-5 reward-title">
              RECOMPENSA
            </h1>
            <div className="r-state mr-3 is-size-4">
              <RewardPadlock rState={statePadlock} />
            </div>
          </div>
          {/* <Scrollbar style={{ width: 275, height: 68 }}> */}
          <div className="rewards-section">
            <Rewards
              clsAward={clsAward}
              updateRewardPadlock={updateRewardPadlock}
            />
          </div>
          {/* </Scrollbar> */}
        </div>
      </div>
    </>
  );
};

export default Missions;
