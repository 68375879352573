import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Fire } from "../../assets/images/profile/fire.svg";
import AxiosConfig from "../../axios/axiosConfig";
import { config } from "../../config";
import { AppContext } from "../../context/context";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import Settings from "../../shared/components/settings/settings.component";
import { BalanceAuthorization } from "../../shared/helpers/navbar.helper";
import Notification from "../notification/notification.component";
import CommunityItem from "./nav-items/community-item/community-item.component";
import "./navbar.styles.scss";

type props = {
  path: string;
  comVisible?: boolean;
  backgroundColor?: boolean;
  breadcrumb?: string;
  visible: boolean;
  collapse: boolean;
  sideBarCollapse: () => void;
};

const Navbar = ({
  path = "/home",
  comVisible = true,
  backgroundColor = false,
  breadcrumb = "",
  visible = false,
  collapse,
  sideBarCollapse,
}: props) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [compVisibility, setCompVisibility] = useState(false);
  const [balance, setBalance] = useState<any>(null);
  let history = useHistory();

  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const careerId = infoCareer.careerList[infoCareer.selectedCareer].idCareer;
  const eMMCareerId = 3;
  const screenSize = useMobileScreen();

  const homeLogo =
    "https://cdn.bemaster.com/media/images/login/Logo-BeMaster.png";
  const homeLogoWhite =
    "https://cdn.bemaster.com/media/images/login/Logo-BeMaster-Elite.png";
  const eliteLogo =
    "https://cdn.bemaster.com/media/images/classroom/elite-mastermind-logo.png";  

  const primeLogo =
    path === "/profile"
      ? `${config.url.images.powerclasses}bemaster-prime-black.svg`
      : `${config.url.images.powerclasses}bemaster-prime-white.svg`;

  const homeNavLogo =
    path === "/profile"
      ? `${config.url.images.homeIcons}home-black.svg`
      : `${config.url.images.homeIcons}home-gold.svg`;

  useEffect(() => {
    let isSubscribed = true;
    setCompVisibility(comVisible);
    careerId === eMMCareerId
      ? setCompVisibility(false)
      : setCompVisibility(true);

    if (
      path === "/home" ||
      path === "/shopping" ||
      path === "/mentortalks" ||
      path === "/powerclass" ||
      path === "/mentortalks/:id" ||
      path === "/powerclass/:id" ||
      path === "/firesShop"
    ) {
      const fetchData = async () => {
        await AxiosConfig.get("/Account/GetUserBalance").then((result) => {
          if (isSubscribed) {
            if (result.data.result === "success") {
              setBalance(result.data.data.balance);
            }
          }
        });
      };
      fetchData();
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (appContext.tourInfo.onTour) {
      setShowSettings(false);
    }
  }, [appContext.tourInfo]);

  const handleClickNotification = () => {
    setShowNotification(!showNotification);
    setShowSettings(false);
  };

  const handleClickSettings = () => {
    setShowSettings(!showSettings);
    setShowNotification(false);
  };

  return (
    <div
      className={`is-nav-section ${
        path == "/shopping" ? "shopping-nav-margins" : ""
      } 
            ${
              path == "/tools"
                ? "tools-theme"
                : path == "/lista-herramientas/:id"
                ? "tools-theme"
                : ""
            }
            ${path == "/calculator/:id" ? "custom-calculator-navbar" : ""}`}
      style={{
        display: visible ? "flex" : "none",
        backgroundColor: backgroundColor ? "#403a31" : "",
        justifyContent: (careerId === 10 && screenSize) ? "space-evenly" : screenSize ? "flex-end" : "",
      }}
    >
      <div
        className={`is-brand-section mr-4 ${
          path == "/shopping" ? "is-shopping-brand-section" : ""
        } ${careerId === 10 && "main-icon"}`}
        style={{ width: `${careerId !== 10 ? "120px" : ""}` }}
      >
        <figure>
          <img
            src={
              careerId === eMMCareerId
                ? eliteLogo
                : path == "/mentortalks/:id"
                ? homeLogoWhite
                : homeLogo
            }
            alt="Brand"
          />
        </figure>
      </div>
      <div className="navbar-content is-hidden-mobile">
        <a className="has-text-white dark" style={{ letterSpacing: 5 }}>
          {" "}
          {breadcrumb.toUpperCase().replace("/", "/   ")}{" "}
        </a>
      </div>
      <div
        className={`navbar-end is-flex ${
          path == "calculator/:id" ? "custom-calculator-navbar" : ""
        }`}
      >
        <div
          className="my-balance mr-4"
          style={{
            display: BalanceAuthorization(path) ? "flex" : "none",
            cursor: "pointer",
          }}
          onClick={() => history.push("/firesShop")}
        >
          <h1 className="balance-text is-size-6 mr-3">Mi saldo</h1>
          <div className="balance-pack">
            <Fire height={20} />
            <h1 className="is-size-6 has-text-weight-bold balance-label">
              {new Intl.NumberFormat("en-IN").format(balance)}
            </h1>
          </div>
        </div>

        {/* Community NavItem */}
        <Link
          to={"/community"}
          className="common-link mr-5 is-hidden-mobile"
          style={{ display: !compVisibility ? "none" : "flex" }}
        >
          <CommunityItem path={path} />
        </Link>

        {/* Notification NavItem */}
        <div className="notification-parent" style={{ margin: (careerId === 10 && screenSize) ? "0 0.6rem 0 0" : "" }}>
          <Notification
            value={showNotification}
            path={path}
            switchGlobalState={handleClickNotification}
          />
        </div>

        {/* Home NavItem */}
        {careerId === 10 && (
          <Link to={"/home"} className="common-link mx-4 is-hidden-mobile">
            <img style={{ width: "20px" }} src={homeNavLogo} alt="" />
          </Link>
        )}

        <div className="settings-parent mx-2">
          <Settings
            value={showSettings}
            switchGlobalState={handleClickSettings}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
