import { useState, useEffect } from "react";
import FiresStoreCard from "../fires-store-card/fires-store-card.component";
import AxiosConfig from "../../axios/axiosConfig";

import "./fires-store.styles.scss";

const FiresStore = () => {
  // const [activeModal, setActiveModal] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      await AxiosConfig.get("/Product/GetProducts/Fires").then((result) => {
        if (isSubscribed) {
          if (result.data.result === "success") {
            setProducts(result.data.data.listProducts);
          }
        }
      });
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className="main-store-container">
      {/* <ShopModal showModal={activeModal} onClick={handleModal} /> */}
      <div className="mega-banner productBanner">
        <div className="content-area ml-6"> </div>
      </div>
      <div className="bottom-shop-section">
        <FiresStoreCard fires={products} />
      </div>
    </div>
  );
};

export default FiresStore;
