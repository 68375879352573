import Reward from "../../assets/gifs/rewards/rewardAnim.gif";
import "./reward-cage.styles.scss";

interface props {
  visible: boolean;
}

const RewardCage = ({ visible }: props) => {
  return visible ? (
    <div className="reward-anim">
      <img src={Reward} />
    </div>
  ) : null;
};

export default RewardCage;
