import React from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import './form-input.styles.scss';

interface props {
    activeAlert?: boolean,
    color?: string,
    iHeight?: number,
    type?: string,
    placeHolder?: string,
    required?: boolean,
    iWidth?: number,
    value?: any,
    onChange?: React.ChangeEventHandler,
    readOnly?: boolean,
    validationMessage?: string,
    validate?: boolean,
    fullWidth?: boolean,
    backgroundColor?: string,
}

const FormInput = ({ iHeight = 35, type = 'text', placeHolder, required = false,
    iWidth = 150, value, onChange, readOnly = false, validationMessage = "Campo necesario",
    validate = false, activeAlert = false, fullWidth, backgroundColor, color }: props) => {

    if (validate) {
        if (required) {
            let valid = value.trim() !== "";
            activeAlert = !valid;
        }
    }

    return (
        <div>
            <input className={`input is-form-input`}
                type={type}
                min={type==='date'? '1950-01-01' : ''}
                placeholder={placeHolder}
                required={required}
                style={{height: iHeight, maxWidth: fullWidth? '100%' : iWidth,  backgroundColor: backgroundColor? backgroundColor : '#f0f0f0',
                color: color }}
                onChange={onChange}
                value={value}
                readOnly={readOnly}                
            />
            <div className="alert-area mt-1" style={{ display: activeAlert ? 'flex' : 'none' }}>
                <div className="alert-icon">
                    <AiOutlineWarning />
                </div>
                <h1 className="ml-1 is-size-7">{validationMessage}</h1>
            </div>
        </div>
    );
};

export default FormInput;