import { BsArrowLeft } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import "./back-button.styles.scss";
interface props {
    sleep?: boolean,
    visible: boolean,
}

const BackButton = ({ sleep, visible }: props) => {
    let history = useHistory();

    const handleBackClick = () => {
        history.goBack();
    }

    return (
        <div className="back-button is-hidden-mobile" onClick={handleBackClick}
        style={{ display: visible ? 'flex' : 'none', opacity: sleep ? '0' : '1' }} >
            <BsArrowLeft />
        </div>
    );
};

export default BackButton;