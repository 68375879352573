import { useContext, useEffect, useState } from "react";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import ClassButton from "../../shared/components/class-button/class-button.component";
import CalmModal from "../calm-modal/calm-modal.component";
import CongratsModal from "../congrats-modal/congrats-modal.component";
import VideoModal from "../video-modal/video-modal.component";
import { WarningModal } from "../warning-modal/warning-modal.component";
import VideoReact from "../video-react/video-react.component";
import { successToast } from "../../shared/helpers";
import {
  DirectCertificationRequest,
  UnlockBemasterPass,
} from "../../services/certification";
import "./certification-video.styles.scss";
import { useCareerMissionState } from "../../hooks/useCareerState";
import { useHistory } from "react-router-dom";
import { updateCareers } from "../../services/career.service";

const CertificationVideo = () => {
  const appContext = useContext(AppContext);
  const infoCareer = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  const idUserCareer = career.idUserCareer;
  const idCareer = career.idCareer;
  const urlVideoCert = career.career.urlVideoCert;

  const [requestModal, setRequestModal] = useState(false);
  const [calmModal, setCalmModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusButton, setStatusButton] = useState<string>("");

  const missionsCompleted = useCareerMissionState();
  let history = useHistory();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setStatusButton(career.statusCert ? "SOLICITUD ENVIADA" : "SOLICITAR DIPLOMA");
  }, [career.statusCert]);

  const handleCertRequest = () => {
    !missionsCompleted && history.push("/home"); 

    if (!career.statusCert) {
      setWarningModal(!warningModal);
    } else if (career.statusCert === "pendiente") {
      setCalmModal(!calmModal);
    } else if (career.statusCert === "rechazada") {
      setWarningModal(!warningModal);
    } else if (career.statusCert === "aprobada") {
      setRequestModal(!requestModal);
    }
  };

  const handleCertificationRequestByCareer = async () => {
    if (idCareer !== 11 && idCareer !== 12) {
      setActiveModal(!activeModal);
    } else {
      if (idCareer === 11) UnlockBemasterPass();
      const response = await DirectCertificationRequest(idUserCareer);

      if (response?.data.result === "success") {
        let loginInfo: any = appContext.loginInfo;
        const selectedCareer = loginInfo.infoCareers.selectedCareer;
        loginInfo.infoCareers.careerList[selectedCareer].statusCert =
          "pendiente";
        appContext.dispatch(loginInfo);

        if (idCareer === 11) updateCareers(appContext);

        successToast(
          "Tu diploma está en proceso de revisión, recibirás un correo electrónico cuando esté listo.",
          {
            duration: 8000,
            position: "bottom-center",
          }
        );
      }
    }
  };

  return (
    <div className="main-certification-video-container">
      {loading ? (
        <div className="bemaster-loader">
          <img src={BeMasterLoader} alt="BeMasterLoader" />
        </div>
      ) : (
        <>
          <WarningModal
            showModal={warningModal}
            action={handleCertificationRequestByCareer}
          />
          <CalmModal showModal={calmModal} />
          <CongratsModal showModal={requestModal} urlCert={career.urlCert} />
          <VideoModal showModal={activeModal} />
          <div className="mega-title">
            <h1 className="is-size-3">{career.career.certificationTitle}</h1>
          </div>
          <div className="bottom-video-certification-section">
            <h1 className="celebration-text has-text-white">
              {career.career.certificationDescription1}
            </h1>
            <div className="video-container">
              <VideoReact src={urlVideoCert} />
            </div>
            <div className="bottom-resume">
              <h3 className="last-text has-text-white">
                {career.career.certificationDescription2}
              </h3>
              <ClassButton
                disabled={career.statusCert !== null && career.statusCert !== ""}
                onClick={handleCertRequest}
                text={statusButton}
                colorButton={"brown"}
                fontSize={16}
                height={35}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CertificationVideo;
