import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import './fire-records.styles.scss';

interface props {
    historical: any,
}

const FireRecords = ({ historical }: props) => {

    let dateHist = new Date(historical.date);

    return (
        <div className="fire-records">
            <div className="has-specific-details is-flex mb-3 px-4" key={historical.idTransaction} >
                <div className="level-left">
                    <h1 className={historical.value < 0 ? "movements-red mr-3" : "movements-green mr-3"}>
                        {new Intl.NumberFormat("en-IN").format(historical.value)}
                    </h1>
                    <h1 className="details">{historical.description}</h1>
                </div>
                <div className="level-right">
                    <h1 className="date ml-2">{dateHist.toLocaleDateString()}</h1>
                </div>
            </div>
        </div>
    );
};

export default FireRecords;