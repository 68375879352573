
const url = "https://cdn.bemaster.com/media/images/tour";

export interface TourCard {
    title: string;
    content: string;

    xCard?: string;
    yCard?: string;

    xRCard?: string;
    yRCard?: string;

    rightCard?: string;
    bottomCard?: string;

    rightRCard?: string;
    bottomRCard?: string;

    src?: string;
    link?: string;
    video?: string;

    width?: string;
    height?: string;

    widthR?: string;
    heightR?: string;
}

export const tourdata: TourCard[] = [
    {
        title: "¡ESTO ES BEMASTER!",
        content: "Estás a punto de comenzar una fantástica experiencia de educación dónde podrás sumergirte en un mundo de posibilidades que hemos creado especialmente para ti. ¿Te parece si hacemos un recorrido?",
        xCard: "0",
        rightCard: "0",
        yCard: "13%",
        src: `${url}/1. Esto es Bemaster.mp4`,
        width: "50%",
        height: "350px",
        widthR: "80%",
        link: "/home"
    },{
        title: "NOTICIAS",
        content: "Queremos que estés bien informado, por eso encontrarás noticias sobre los acontecimientos que consideramos son importantes para ti. ¡Mantente actualizado!",
        rightCard: "20%",
        yCard: "20%",
        xRCard: "0",
        rightRCard: "0",
        yRCard: "0",
        src: `${url}/3. Triunfos.jpg`,
        link: "/home"
    },{
        title: "MENU DESPLEGABLE",
        content: "Al lado izquierdo encontrarás el menú desplegable con el que podrás navegar entre todas las opciones disponibles. ¡Revisemos algunas de ellas!",
        xCard: "30%",
        bottomCard: "16px",
        xRCard: "unset",
        rightRCard: "0",
        bottomRCard: "0",
        src: `${url}/5. Menu desplegable.mp4`,
        link: "/home"
    },{
        title: "MI PERFIL",
        content: "En “MI PERFIL” podrás completar todos los datos relacionados a tu cuenta, no olvides hacerlo, esto puede ayudarte a conectar con otras personas. Además encontrarás información relacionada a tu progreso, cómo los triunfos que has obtenido y los fuegos que has ganado. ¡Enseguida te explico cómo funciona!",
        xCard: "100px",
        bottomCard: "16px",
        xRCard: "0",
        rightRCard: "0",
        link: "/profile",
        src: `${url}/6. Mi perfil.jpg`
    },{
        title: "MI CARRERA",
        content: "En este apartado podrás encontrar y acceder a la información detallada de la carrera que escogiste anteriormente. Encontrarás información sobre la cantidad de módulos y las clases que la componen. Usa esta opción para acceder de manera sencilla a la carrera que estás cursando.",
        yCard: "30%",
        rightCard: "32px",
        link: "/career",
        xRCard: "0",
        rightRCard: "unset",
        yRCard: "0",
        widthR: "300px",
        src: `${url}/4. Mis carreras.mp4`
    },{
        title: "CLASSROOM",
        content: "¡Entra al classroom para ir de manera directa a tus clases!",
        xCard: "100px",
        yCard: "30%",
        link: "/classroom",
        src: `${url}/8. Classroom.jpg`
    },{
        title: "MISIONES",
        content: "Una vez ingreses a tu clase podrás encontrar en la parte derecha el apartado dónde están tus misiones diarias. Completa estas misiones antes de avanzar a tu siguiente clase, están diseñadas para ayudarte a alcanzar tu objetivo. ¡Además al completar las misiones obtendrás una recompensa!",
        yCard: "20%",
        rightCard: "40%",
        link: "/classroom",
        src: `${url}/9. Misiones.mp4`
    },{
        title: "FUEGOS",
        content: "Tenemos nuestra propia moneda virtual…Pero decidimos llamarle FUEGOS. Podrás obtenerlos al ir completando las misiones y con ellos puedes comprar contenido exclusivo dentro de la plataforma. ¡Entre más misiones completes más fuegos tendrás!",
        rightCard: "40%",
        yCard: "40%",
        link: "/classroom",
        src: `${url}/10. Fuegos.mp4`
    },{
        title: "MI PROGRESO",
        content: "Utiliza nuestra barra de progreso para ver cuánto has avanzado y cuánto te falta para culminar tu carrera. ",
        xCard: "100px",
        yCard: "16px",
        link: "/classroom",
        src: `${url}/11. Mi Progreso.mp4`
    },{
        title: "QUICES",
        content: "Válida si has comprendido la clase completando los quices. No te preocupes, no vamos a calificarte, los quices son para afianzar el conocimiento. ",
        rightCard: "30%",
        yCard: "40%",
        link: "/classroom",
        src: `${url}/12. Quices.mp4`,
    },{
        title: "WORKBOOKS",
        content: "Los workbooks son tu espacio de trabajo, en ellos podrás ir desarrollando cada una de las tareas que te llevarán paso a paso a completar tu objetivo. Recuerda que puedes descargarlos para ver todo tu trabajo de manera consolidada.",
        rightCard: "30%",
        yCard: "40%",
        link: "/classroom",
        src: "https://picsum.photos/id/9/250/100",
    },{
        title: "POWERCLASS",
        content: "Mira como lo hace un master... Las Powerclass son clases prácticas, donde los Masters, que son personas con experiencia, te muestran cómo llevan a la práctica todo lo aprendido en el Classroom. ¡Elige a tus masters favoritos y aprende de ellos!",
        xCard: "100px",
        yCard: "8px",
        src: `${url}/14. PowerClass.mp4`,
        link: "/powerclass"
    },{
        title: "MENTORTALKS",
        content: "Creemos en el desarrollo integral por eso creamos MENTORTALKS. Este es unn espacio especial donde te estaremos compartiendo conocimiento para que desarrolles habilidades complementarias a las de tu carrera. Cuándo sientas que estás atascado ven aquí, seguro te hará muy bien.",
        xCard: "100px",
        yCard: "8px",
        src: `${url}/15. Mentortalks.mp4`,
        link: "/mentortalks"
    },{
        title: "COMUNIDAD",
        content: "Aquí podrás enterarte de los eventos y clasificados más importantes de tu comunidad. ¡No olvides unirte al grupo en Facebook!",
        rightCard: "32px",
        yCard: "32px",
        src: `${url}/16. Comunidad.jpg`,
        link: "/community"
    },{
        title: "SOPORTE",
        content: "¡No estás solo! Si sientes que tienes alguna pregunta en el desarrollo de tu carrera, ven al soporte y explora las opciones que tenemos para ti. Estamos comprometidos en ayudarte a lograr  tu objetivo y queremos que te sientas apoyado durante todo el proceso.",
        xCard: "0",
        rightCard: "0",
        bottomCard: "16px",
        link: "/support",
        src: `${url}/17. Soporte.jpg`
    },{
        title: "FINAL DEL RECORRIDO",
        content: "Tu visita por nuestra plataforma ha terminado pero tu proceso de crecimiento apenas comienza. Deseamos que empieces con toda la energía. ¡Muchos éxitos!",
        xCard: "0",
        rightCard: "0",
        src: `${url}/18. Final del Recorrido.mp4`,
        bottomCard: "30%",
        link: "/home",
        width: "50%",
        height: "350px"
    }
]