import TriumphsList from "../triumphs-list/triumphs-list.component";
import "./triumphSlider.styles.scss";

interface TriumphSlidesProps {
    tourName: string;
    careerId: number;
    eMMCareerId: number;
}

const TriumphSlider = ({ tourName, careerId, eMMCareerId }: TriumphSlidesProps) => {
  return (
    <div
      className="is-triumph-section ml-6"
      id="b"
      style={{
        zIndex: tourName === "TRIUNFOS" ? 105 : 0,
        position: tourName === "TRIUNFOS" ? "relative" : "unset",
      }}
    >
      <div
        className="is-title-section mb-5"
        style={{ display: careerId === eMMCareerId ? "none" : "flex" }}
      >
        <h1
          className="has-text-weight-bold is-size-4"
          style={{ letterSpacing: 5 }}
        >
          TRIUNFOS
        </h1>
      </div>
      <div className="is-triumph-list">
        {careerId === 3 ? (
          <img
            src={`https://cdn.bemaster.com/media/images/home/home-icono.png`}
            alt="elite-home-logo"
          />
        ) : null}
        {careerId !== 3 ? <TriumphsList /> : null}
      </div>
    </div>
  );
};

export default TriumphSlider;
