import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";

import "./calm-modal.styles.scss";

interface props {
  showModal: boolean;
}

const CalmModal = ({ showModal }: props) => {
  const [calmModal, setCalmModal] = useState(true);

  useEffect(() => {
    setCalmModal(showModal);
  }, [showModal]);

  const handleModalBehavior = () => {
    setCalmModal(!calmModal);
  };

  return (
    <div className="calm-modal">
      <Modal isOpen={calmModal} portalClassName={"calm-modal"}>
        <div className="close-button" onClick={handleModalBehavior}>
          <MdClose onClick={() => handleModalBehavior} />
        </div>
        <h1 className="calm-text is-size-3 my-2">¡TÓMALO CON CALMA!</h1>
        <h1 className="content-text is-size-5 mb-5">
          Tu diploma está en proceso de revisión, recibirás un correo
          electrónico cuando esté listo.
        </h1>
      </Modal>
    </div>
  );
};

export default CalmModal;
