import { useState } from "react";
import { config } from "../../../../config";
import "./community-item.styles.scss";

interface CommunityItemProps {
  path: string;
}

const CommunityItem = ({ path }: CommunityItemProps) => {
  const [showText, setShowText] = useState<boolean>(false);
  const communityLogo =
  path === "/profile"
    ? `${config.url.images.homeIcons}community-black.svg`
    : `${config.url.images.homeIcons}community-gold.svg`;

  return (
    <div
      className="main-powerclass-item-container"
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <div className="icon-container">
        <img
          src={communityLogo}
          alt=""
        />
      </div>
      <div
        style={{ width: `${showText ? "130px" : "0"}` }}
        className="text-container"
      >
        <span>COMUNIDAD</span>
      </div>
    </div>
  );
};

export default CommunityItem;
