import React, { useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import ProgressResumeCard from '../progress-resume-card/progress-resume-card.component';

import './progress-resume.styles.scss';

const ProgressResume = () => {
    return (
        <div className="main-progress-container">
            <div className="top-progress-container">
                <h1 className="is-size-3">MI PROGRESO</h1>
            </div>
            <div className="down-side">
                <Scrollbar height={'100%'} >
                    <ProgressResumeCard />
                </Scrollbar>
            </div>
        </div>
    );
};

export default ProgressResume;