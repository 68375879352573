import { useEffect, useState } from "react";
import { IoMdSettings } from "react-icons/io";
import "./resolution-button.styles.scss";

interface props {
  order: number;
  resolutions: any[];
  setResolution: Function;
  video: any;
  setStartTime: Function;
}

const ResolutionButton = ({
  order,
  resolutions,
  setResolution,
  video,
  setStartTime,
}: props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openResolutions, setOpenResolutions] = useState(false);

  const parseResolution = (res: any) => {
    const { width, height } = res;
    return `${width}x${height}`;
  };

  const switchConfig = () => {
    if (openMenu) {
      setOpenResolutions(false);
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  const changeResolution = (n: number) => {
    setResolution(n);
    setStartTime(video.currentTime);
  };

  return (
    <div className="main-resolution-button">
      <div
        className="click-away"
        style={{ display: openMenu ? "block" : "none" }}
        onClick={() => {
          openMenu && switchConfig();
        }}
      ></div>
      <div
        className="resolution-options"
        style={{ display: openMenu ? "block" : "none" }}
      >
        <div
          className="resolution-option"
          onClick={() => setOpenResolutions(!openResolutions)}
        >
          Calidad
        </div>
      </div>
      <div
        className="available-resolutions"
        style={{ display: openResolutions ? "block" : "none" }}
      >
        {resolutions.length !== 0 &&
          resolutions
            .map((res, i) => {
              return (
                <div
                  className="resolution-option"
                  onClick={() => changeResolution(i)}
                  key={i}
                >
                  {parseResolution(res)}
                </div>
              );
            })
            .reverse()}
        <div className="resolution-option" onClick={() => changeResolution(-1)}>
          auto
        </div>
      </div>

      <IoMdSettings onClick={switchConfig} />
    </div>
  );
};

export default ResolutionButton;
