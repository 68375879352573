import Vimeo from "@vimeo/player";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCurrentCareerId } from "../../hooks/useCareer";
import { Video } from "../../interfaces/video";
import BackButton from "../back-button/back-button.component";
import RatingVideo from "../rating-video/rating-video.component";
import VideoReact from "../video-react/video-react.component";
import ContentDescription from "./content-description/content-description.component";
import { Sidebar } from "./sidebar/sidebar.component";

import { useMobileScreen } from "../../hooks/useMobileScreen";
import "./full-screen-video.styles.scss";

type props = {
  nextVideoName: string;
  video: Video;
  idModuleClass?: number;
  idToolCategory?: number;
  idCareerModule?: number;
  idClassMission?: number;
};

const FullScreenVideo = ({
  video,
  nextVideoName,
  idModuleClass,
  idToolCategory,
  idCareerModule,
  idClassMission,
}: props) => {
  const vimeoRef = useRef<HTMLIFrameElement>(null);
  const [videoProgress, setVideoProgress] = useState(false);
  const [videoPaused, setVideoPaused] = useState<boolean>(false);
  const [sleepArrow, setSleepArrow] = useState<boolean>(false);
  const [canSleep, setCanSleep] = useState<boolean>(false);

  let sleepTimer: ReturnType<typeof setTimeout>;
  let history = useHistory();
  const arrowDuration = 3000;
  const sleepDuration = 10000;

  useEffect(() => {
    if (vimeoRef.current !== null) {
      let vimeoPlayer = new Vimeo(vimeoRef.current);

      vimeoPlayer.on("progress", function (data: any) {
        if (video.type != "Onboarding" && data.percent * 100 > 98) {
          vimeoPlayer.off("progress");
          setVideoProgress(true);
        }
      });

      vimeoPlayer.on("ended", function (data: any) {
        if (video.type === "Onboarding") {
          vimeoPlayer.off("ended");
          history.push("/home");
        }
      });
    }
  }, [vimeoRef]);

  //back arrow feature
  useEffect(() => {
    if (sleepArrow) return;

    const timer = setTimeout(() => setSleepArrow(true), arrowDuration);
    return () => clearTimeout(timer);
  }, [sleepArrow]);

  //sleep content integration
  useEffect(() => {
    if (!videoPaused) return;
    
    sleepTimer = setTimeout(() => setCanSleep(true), sleepDuration);
    return () => clearTimeout(sleepTimer);
  }, [videoPaused, canSleep]);

  const onVideoEnd = () => {
    if (video.type === "Onboarding") {
      history.push("/careers");
    }
  };

  const onProgress = (percent: number) => {
    if (video.type !== "Onboarding" && percent > 98) {
      setVideoProgress(true);
    }
  };

  //on video paused!
  const onVideoPaused = () => {
    setVideoPaused(true);
    setCanSleep(false);
  };

  //on video played!
  const onVideoPlay = () => {
    setVideoPaused(false);
  };

  const handleMouseMovement = () => {
    setSleepArrow(false);
    setCanSleep(false);
  };

  switch (video.videoPlayer) {
    case "Vimeo":
      return (
        <div className="full-screen-video">
          <RatingVideo
            visible={videoProgress}
            video={video}
            nextVideoName={nextVideoName}
          />
          <iframe
            ref={vimeoRef}
            src={video.url}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
        </div>
      );
      break;
    case "Custom":
      return (
        <div
          className="full-screen-video"
          onMouseMove={() => handleMouseMovement()}
        >
          <BackButton
            visible={video.type !== "Onboarding"}
            sleep={sleepArrow}
          />
          <ContentDescription videoData={video} sleep={canSleep} />

          <RatingVideo
            nextVideoName={nextVideoName}
            visible={videoProgress}
            video={video}
          />
          <VideoReact
            src={video.url}
            onVideoEnd={onVideoEnd}
            onProgress={onProgress}
            onVideoPaused={onVideoPaused}
            onVideoPlayed={onVideoPlay}
          />
          <Sidebar
            video={video}
            nextVideoName={nextVideoName}
            idModuleClass={idModuleClass}
            idToolCategory={idToolCategory}
            idClassMission={idClassMission}
            idCareerModule={idCareerModule}
          />
        </div>
      );
      break;
    default:
      return (
        <div
          className="full-screen-video"
          onMouseMove={() => handleMouseMovement()}
        >
          <BackButton visible={video.type != "Onboarding"} sleep={sleepArrow} />
          <ContentDescription videoData={video} sleep={canSleep} />
          <RatingVideo
            nextVideoName={nextVideoName}
            visible={videoProgress}
            video={video}
          />
          <VideoReact
            onVideoPaused={onVideoPaused}
            onVideoPlayed={onVideoPlay}
            src={video.url}
            onVideoEnd={onVideoEnd}
            onProgress={onProgress}
            idNextVideo={video.idNextVideo}
            idContent={video.idContent}
            progress={
              video.userContent[0] ? video.userContent[0].progressVideo : 0
            }
          />
          <Sidebar
            video={video}
            nextVideoName={nextVideoName}
            idModuleClass={idModuleClass}
            idToolCategory={idToolCategory}
            idClassMission={idClassMission}
            idCareerModule={idCareerModule}
          />
        </div>
      );
      break;
  }
};

export default FullScreenVideo;
