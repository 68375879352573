import { useEffect, useState } from "react";
import { Card } from "../card/card.component";

import "./card-list.styles.scss";

interface props {
  careerList: any;
}

const CardList = ({ careerList }: props) => {
  const [orderedCareers, setOrderedCareers] = useState();
  const [politeCardList, setPoliteCardList] = useState<boolean>(false);

  useEffect(() => {
    let careers = careerList.sort(
      (a: any, b: any) => a.career.order - b.career.order
    );

    careers.length > 5 && setPoliteCardList(!politeCardList);
    setOrderedCareers(careers);
  }, [careerList]);

  return (
    <>
      <div
        className={`card-list ${politeCardList ? "carousel-card-list" : ""}`}
      >
        {orderedCareers && <Card cardState={orderedCareers} />}
      </div>
    </>
  );
};

export default CardList;
