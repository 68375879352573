import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ReactStars from "react-stars";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import { Video } from "../../interfaces/video";
import "./video-list.styles.scss";

type props = {
    videos: Video[];
    payment: boolean;
    boughtContent: boolean;
    prevComp: string | undefined;
}

type LocationState = {
    from?: string;
}

const VideoList = ({ videos, payment, boughtContent, prevComp }: props) => {

    const [orderedVideos, setOrderedVideos] = useState<any[]>();
    const [groupedVideos, setGroupedVideos] = useState<any[]>();
    const history = useHistory<LocationState>();

    const mobileScreen = useMobileScreen();
    let orderedVideoParts: any = [];

    let videoParts: any = [];

    useEffect(() => {
        if (prevComp === 'classroom') {
            const groupBy = (videoList: any, keyGetter: any) => {
                const map = new Map();
                videoList.forEach((item: any) => {
                    const key = keyGetter(item);
                    const collection = map.get(key);
                    if (!collection) {
                        map.set(key, [item]);
                    } else {
                        collection.push(item);
                    }
                });
                return map;
            }

            const grouped = groupBy(videos, (vid: any) => vid.category);

            setOrderedVideos([grouped]);
            let listTest: any = [];

            orderedVideos?.map((item: any) => {
                listTest.push(item);
            });

            if (listTest && listTest.lenght !== 0) {
                videoParts = listTest[0]?.entries();

                if (videoParts) {
                    for (let value of videoParts) {
                        value[1].map((row: any) => orderedVideoParts.push(row));
                    }
                }

                setGroupedVideos(orderedVideoParts);
            }
        } else {
            const orderedVideos = videos.sort((a: Video, b: Video)=> { return a.order - b.order });
            setGroupedVideos(orderedVideos);
        }

    }, [videos])

    const makeUrlImage = (id: string | number) => {
        return `url('https://cdn.bemaster.com/media/images/powerclasses/pclass0${id}.png')`;
    }

    const getBarProgressWidth = (progress: number) => {
        return `${progress}%`;
    }

    const viewVideo = (powerId: string | number) => {
        let cont = videos.filter((x: any) => x.idContent == powerId);
        if (!payment || boughtContent) {
            let item: Video = cont[0];
            const from = history.location.state?.from;
            history.push(`/video?id=${item.idContent}`, {
                from
            });
        }
    }

    return (
        <>
            {
                groupedVideos && groupedVideos.map((video: any) =>
                    <div className="video-container" key={video.idContent}>
                        <div className="video-description">
                            <div className="video-image-container">
                                <div className="video" style={{ backgroundImage: makeUrlImage(video.idContent) }} onClick={() => viewVideo(video.idContent)}>
                                    {
                                        payment && !boughtContent ?
                                            <div className="blocked">
                                                BLOQUEADO
                                            </div> :
                                            <div className="progress">
                                                <div className="progress-bar-back" />
                                                <div className="progress-bar-front"
                                                    style={{ width: video.userContent[0] ? getBarProgressWidth(video.userContent[0].progressVideo) : 0 }} />
                                            </div>
                                    }

                                </div>

                            </div>
                            <div className="description">
                                <div className="description-text">
                                    <h1>{video.name}</h1>
                                    {video.description}
                                </div>
                            </div>
                        </div>
                        <div className="video-rating">
                            <ReactStars
                                count={5}
                                value={video.ratingContent ? video.ratingContent.rating : 0}
                                size={mobileScreen ? 25 : 18}
                                half={true}
                                edit={false}
                                color1={"white"}
                                color2={'#c19656'}
                                className="video-stars"
                            />

                            <div className="button-container" style={{ display: payment && !boughtContent ? "none" : "block" }}>
                                <button onClick={() => viewVideo(video.idContent)}>VER AHORA</button>
                            </div>

                            {/* <div className="views">
                                {video.viewsQuantity} vistas
                            </div> */}
                        </div>
                    </div>)
            }
        </>
    )
}

export default VideoList;