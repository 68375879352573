import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/context";
import { Video } from "../../interfaces/video";
import Logo from "../../assets/images/login/Logo-BeMaster.png";
import AxiosConfig from "../../axios/axiosConfig";
import FullScreenVideo from "../full-screen-video/full-screen-video.component";

import "./visualize-video.styless.scss";

const VisualizeVideo = () => {
  const [data, setData] = useState<Video>();
  const [nextVideoName, setNextVideoName] = useState("");
  const [idToolCategory, setIdToolCategory] = useState();
  const [idModuleClass, setIdModuleClass] = useState();
  const [idClassMission, setIdClassMission] = useState();
  const [idCareerModule, setIdCareerModule] = useState();

  const paramsString = new URLSearchParams(useLocation().search);
  const id = paramsString.get("id");

  const appContext = useContext(AppContext);

  let loginInfo = appContext.loginInfo;
  let history = useHistory();
  let careers: number[] = [];

  const infoCareer: any = loginInfo?.infoCareers;
  careers = infoCareer.careerList?.map((career: any) => career.idCareer);
  
  const getData = async () => {
    try {
      const response = await AxiosConfig.post("/Content/GetContent", {
        filterBy: "Id",
        id: id,
        listInts: careers,
      });

      if (response.data.result === "error") {
        return history.push("/home");
      }

      if (response.data.data.idToolCategory !== -1) {
        setIdToolCategory(response.data.data.idToolCategory);
      }

      if (response.data.data.idModuleClass !== null) {
        setIdModuleClass(response.data.data.idModuleClass);
      }

      setIdCareerModule(response.data.data.idCareerModule);
      setIdClassMission(response.data.data.idClassMission);

      setData(response.data.data.listContent[0] as Video);
      setNextVideoName(response.data.data.nextVideoName);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!data) {
      getData();
    }
  }, [data]);

  return (
    <div className="main-video-container">
      {data && (
        <FullScreenVideo
          video={data}
          nextVideoName={nextVideoName}
          idModuleClass={idModuleClass}
          idToolCategory={idToolCategory}
          idClassMission={idClassMission}
          idCareerModule={idCareerModule}
        />
      )}
    </div>
  );
};

export default VisualizeVideo;
