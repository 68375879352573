import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/context";

import "./tour.styles.scss";
import { useMobileScreen } from "../../../hooks/useMobileScreen";

const TourLayout = () => {
  const appContext = useContext(AppContext);

  const history = useHistory();

  const index =
    appContext.tourInfo.index === -1 ? 0 : appContext.tourInfo.index;
  const tourLength = appContext.tourInfo.content.length;
  const card = appContext.tourInfo.content[appContext.tourInfo.index];
  const mobileScreen = useMobileScreen();

  const go = (n: number) => {
    const newTourInfo = appContext.tourInfo;
    if (index + n == tourLength) {
      newTourInfo.onTour = false;
      newTourInfo.index = 0;
    } else {
      newTourInfo.index += n;
    }
    appContext.dispatchTour(newTourInfo);
  };

  const exitTour = () => {
    const newTourInfo = appContext.tourInfo;
    newTourInfo.onTour = false;
    newTourInfo.index = 0;
    appContext.dispatchTour(newTourInfo);
    history.push("/home");
  };

  const getSourceType = (src: string) => {
    return src.includes(".mp4") ? ".mp4" : src.includes(".jpg") ? ".jpg" : "";
  };

  useEffect(() => {
    if (card && card.link) {
      history.push(card.link);
    }
  }, [card && card.link]);

  if (!appContext.tourInfo.onTour) {
    return <></>;
  }

  return (
    <>
      <div className="tour-layout"></div>
      <div
        className="tour-card"
        style={
          !mobileScreen
            ? {
                left: card.xCard,
                top: card.yCard,
                right: card.rightCard,
                bottom: card.bottomCard,
                width: card.width ? card.width : "250px",
                minHeight: card.height ? card.height : "280px",
              }
            : {
                left: 0,
                top: 0,
                width: "100vw",
                height: "100vh",
              }
        }
      >
        {card.src &&
          (getSourceType(card.src) === ".jpg" ? (
            <img src={card.src} alt="" />
          ) : getSourceType(card.src) === ".mp4" ? (
            <video autoPlay src={card.src} loop></video>
          ) : (
            <div>XD</div>
          ))}

        <div className="exit-tour" onClick={exitTour}>
          x
        </div>
        <div className="tour-content">
          <div className="card-title">{card.title}</div>
          <div className="card-content">{card.content}</div>
        </div>

        {index !== 0 && (
          <div className="card-backward" onClick={() => go(-1)}>
            Atrás
          </div>
        )}

        <div className="card-forward" onClick={() => go(1)}>
          {index == tourLength - 1 ? "Terminar" : "Siguiente"}
        </div>
{/* 
        <div className="card-index">
          {index + 1}/{tourLength}
        </div> */}
      </div>
    </>
  );
};

export default TourLayout;
