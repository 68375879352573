import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './routers/AppRouter';

import './index.scss';

//TODO: Uncomment this!
console.log('%c BEMASTER ', 'background-color: black; color: #C19656; font-weight: bolder; font-size: 4em;  text-shadow: #000 1px 1px;')
console.log('%c Esta es una plataforma hecha de emprendedores para emprendedores 🚀🔥 pero sobretodo, hecha con amor! 💖', 
'background-color: black; color: #C19656; font-weight: 900; padding: 10px;');

ReactDOM.render(
    <Router>
      <AppRouter />  
    </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
