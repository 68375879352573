export const NavbarAuthorization = (path: string) => {
  return (
    path === "/home" ||
    path === "/marketplace" ||
    path === "/powerclass" ||
    path === "/mentortalks" ||
    path === "/shopping" ||
    path === "/diploma" ||
    path === "/afiliacion" ||
    path === "/community" ||
    path === "/career" ||
    path === "/firesShop" ||
    path === "/powerclass/:id" ||
    path === "/mentortalks/:id" ||
    path === "/mail" ||
    path === "/tools" ||
    path === "/lista-herramientas/:id" ||
    path === "/calculator/:id" ||
    path === "/profile"
  );
};
