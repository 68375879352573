import ButtonPersonalized from '../../shared/components/buttons/button-personalized/button-personalized.component';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';

import './congrats-modal.styles.scss';

interface props {
    showModal: boolean,
    urlCert?: string
}

const CongratsModal = ({showModal, urlCert}: props) => {
    
    const [calmModal, setCalmModal] = useState(true);

    useEffect(()=> {
        setCalmModal(showModal);
    },[showModal])

    const handleModalBehavior = () => {
        setCalmModal(!calmModal);
    }
    
    const handleCertRequest = () => {
        window.open("https://cdn.bemaster.com/" + urlCert);
    }

    return (
        <div className="congrats-modal">
            <Modal isOpen={calmModal} portalClassName={'congrats-modal'}>
                <div className="close-button" onClick={handleModalBehavior}>
                    <MdClose onClick={() => handleModalBehavior} />
                </div>
                <h1 className="congrats-text is-size-2">¡FELICIDADES!</h1>
                <h1 className="content-text is-size-5 mb-5">Tu diploma se encuentra listo.</h1>
                <ButtonPersonalized label={'DESCARGAR MI DIPLOMA'} onClick={handleCertRequest} />
            </Modal>
        </div>
    );
};

export default CongratsModal;