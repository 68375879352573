import { useContext, useEffect } from "react";
import { config } from "../../config";
import { AppContext } from "../../context/context";

import "./progress-resume-card.styles.scss";

const ProgressResumeCard = () => {
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const infoTriumphs: any = appContext.loginInfo.infoTriumphs;

  return (
    <div className="is-progress-by-career-card">
      {infoCareer.careerList.map((progressByCareer: any) => {
        const catTriumphs = infoTriumphs.listUserTriumphs.filter(
          (f: any) => f.category === progressByCareer.career.name && f.earned
        ).length;

        const startDate = new Date(progressByCareer.startDate);
        // const studentLevel = progressByCareer.level.toLowerCase();
        const idCareer = progressByCareer.idCareer;

        if (
          idCareer === 1 ||
          idCareer === 2 ||
          idCareer === 6 ||
          idCareer === 8
        ) {
          return (
            <div
              className="bottom-progress-container"
              style={{
                backgroundImage: `url('https://cdn.bemaster.com/media/images/careers/Banner Mi progreso - ${idCareer}.png')`,
              }}
            >
              <div className="columns">
                <div className="column is-4 text-lef-side">
                  <div className="main-title">
                    <h1 className="is-size-3 mr-2">
                      {progressByCareer.career.name.toUpperCase()}
                    </h1>
                    <h1 className="is-size-4 mr-2 subtitle">MASTER</h1>
                  </div>
                  <h1 className="is-size-6 custom-grey">FECHA DE INICIO</h1>
                  <h1 className="is-size-6 has-text-weight-bold">
                    {startDate.toLocaleDateString()}
                  </h1>
                  <h1 className="is-size-6 custom-grey">NÚMERO DE TRIUNFOS</h1>
                  <h1 className="is-size-6 has-text-weight-bold">
                    {catTriumphs}
                  </h1>
                </div>
                {/* <div className="column is-8 visualize-level">
                  <div className="is-total-level-progress">
                    {studentLevel === "junior" && (
                      <div className="junior-level">
                        <h1 className="has-text-white has-text-weight-bold ml-6">
                          • JUNIOR{" "}
                          {progressByCareer.porcProgressLevel > 100
                            ? 100
                            : progressByCareer.porcProgressLevel}
                          %
                        </h1>
                        <div
                          className="internal-junior-level"
                          style={{
                            width: progressByCareer.porcProgressLevel + "%",
                          }}
                        ></div>
                      </div>
                    )}
                    {studentLevel === "senior" && (
                      <div className="senior-level">
                        <h1
                          className="has-text-white has-text-weight-bold ml-6"
                          style={{
                            display:
                              progressByCareer.level.toLowerCase() !== "junior"
                                ? "flex"
                                : "none",
                          }}
                        >
                          • SENIOR{" "}
                          {progressByCareer.porcProgressLevel > 100
                            ? 100
                            : progressByCareer.porcProgressLevel}
                          %
                        </h1>
                        <div
                          className="internal-senior-level"
                          style={{
                            width: progressByCareer.porcProgressLevel + "%",
                          }}
                        ></div>
                      </div>
                    )}
                    {studentLevel === "master" && (
                      <div className="master-level">
                        <h1
                          className="has-text-white has-text-weight-bold ml-6"
                          style={{
                            display:
                              progressByCareer.level.toLowerCase() === "master"
                                ? "flex"
                                : "none",
                          }}
                        >
                          • MASTER{" "}
                          {progressByCareer.porcProgressLevel > 100
                            ? 100
                            : progressByCareer.porcProgressLevel}
                          %
                        </h1>
                        <div
                          className="internal-master-level"
                          style={{
                            width: progressByCareer.porcProgressLevel + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          );
        }
        return <></>;
      })}
    </div>
  );
};

export default ProgressResumeCard;
