import React, { useState } from "react";
import { ReactComponent as Flame } from '../../../assets/images/profile/flame.svg';
import { Link } from "react-router-dom";

import "./card.styles.scss";
import { StudyCase } from "../../../interfaces/studyCase";

type props = {
    mcategory: StudyCase;
    width?: string;
}

const Card = ({ mcategory, width="195px" }: props) => {

    const [hover, setHover] = useState(false);
    const [isPayment, setIsPayment] = useState(false);

    return (
        <Link to={location => `${location.pathname}/${mcategory.idContentCategory}`}>
            <div className="mentortalk-card-container" style={{width}}>
                <div className="mentortalk-card"
                    style={{ backgroundImage: "url('https://cdn.bemaster.com/media/images/powerclasses/Card_casodeestudio.png')" }}
                    onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <div className="payment-content" style={{ display: (isPayment) ? "flex" : "none" }}>
                        <div className="price">
                            <Flame height={30} width={30} />
                            <div className="price-text">500</div>
                        </div>
                    </div>
                    <button>
                        VER AHORA
                </button>
                </div>
            </div>
        </Link>
    )
}

export default Card;