import { createRef, useEffect, useRef, useState, useContext } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../context/context";
import ButtonPersonalized from "../../shared/components/buttons/button-personalized/button-personalized.component";
import InputCalculator from "../calculator-input/input.component";
import formData from "./form-data";

import "./calculator.styles.scss";
import {
  CalculatorFormInterface,
  CalculatorTableInterface,
} from "../../interfaces/calculator";

const Calculator = () => {
  const input = useRef<any>([]);
  const tables = useRef<any>([]);
  const [validate, setValidate] = useState<boolean>(false);
  const options = { style: "currency", currency: "USD" };
  const numberFormat = new Intl.NumberFormat("en-US", options);
  const [currencyFields, setCurrencyFields] = useState<string[]>([]);
  const [access, setAccess] = useState<boolean>(false);

  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const careerIdSelected = infoCareer.careerId;

  let history = useHistory();
  const sectionToPrint = createRef<any>();

  const path = matchPath<any>(useLocation().pathname, {
    path: "/calculator/:id",
    exact: true,
    strict: true,
  });

  useEffect(() => {
    if (careerIdSelected === 2) {
      path?.params.id == 1 ? setAccess(true) : history.push("/tools");
    } else {
      history.push("/home");
    }
  }, []);

  // Input Values OnChange
  let results: any = [];
  let toolName = "GESTORES DE TRÁFICO";

  const handleClearClick = () => {
    input.current.map((input: any) => {
      input.value = null;
    });
  };

  const handleButtonClick = () => {
    setValidate(true);
    handleValidation();
  };

  const handlePrintClick = () => {
    const innerHtmlCalculator = sectionToPrint.current?.outerHTML;
  };

  const handleValidation = () => {
    let isValid = true;
    input.current.map((item: any) => {
      if (!item.value && !item.disabled) {
        isValid = false;
        return;
      }
    });

    if (isValid) {
      calculate();
    }
  };

  const calculate = () => {
    getFormulas();
    executeOperations();
    // prettier();
  };

  const [selectable, setSelectable] = useState<boolean>(false);

  //This method gives a format for every field
  const prettier = () => {
    currencyFields
      .filter(
        (value: any, index: number, self: any) => self.indexOf(value) === index
      )
      .map((currencyField: any) => {
        input.current.map((input: any) => {
          if (input.id == currencyField) {
            input.type = "text";
            let inputValue = numberFormat.format(input.value);
            input.value = inputValue;
          }
        });
      });
  };

  //execute the operations
  const executeOperations = () => {
    let newOperation: string;

    results &&
      results.map((res: any) => {
        const inputOperation = input.current.find(
          (element: any) => element?.id == res?.id
        );
        newOperation = res.formula.operation;
        res.formula.variables.map((vars: any) => {
          const inputValOperation = input.current.find(
            (element: any) => element?.id == vars.input
          );
          if (inputValOperation?.value) {
            newOperation = newOperation?.replace(
              `input${vars.input}`,
              inputValOperation.value != null ? inputValOperation.value : 0
            );
          }
        });

        if (inputOperation) {
          try {
            inputOperation.value = eval(newOperation);
          } catch (error) {
            console.error("Error description -> ", error);
          }
        }
      });

    setValidate(false);
  };

  //Get the formulas in JSON doc
  const getFormulas = () => {
    formData.map((toolData: any) => {
      toolData.table.map((dataTable: any) => {
        dataTable.fields.map((field: any) => {
          if (field.formula) {
            results.push({ id: field.id, formula: field.formula });
          }
        });
      });
    });
  };

  const addRefs = (item: any, id: string) => {
    input.current[id] = item;
  };

  const addTableRefs = (item: any, id: string) => {
    input.current[id] = item;
  };

  const [flag, setFlag] = useState<number[]>([]);

  const table1 = useRef<any>();
  const table2 = useRef<any>();

  return (
    <div id="section-to-print" ref={sectionToPrint}>
      {access && (
        <div className="main-calc-container">
          <div className="top-label">
            <h1>{toolName}</h1>
          </div>
          <div className="form" id="grid">
            {formData &&
              formData.map((form: CalculatorFormInterface, i: number) => {
                return (
                  <div className="form-data" key={i} id={form.id}>
                    {form.table &&
                      form.table.map(
                        (table: CalculatorTableInterface, j: number) => {
                          return (
                            <>
                              {table.title && (
                                <div
                                  className={`table-title ${table.group}`}
                                  style={{ display: selectable ? "" : "" }}
                                >
                                  {" "}
                                  <h1> {table.title} </h1>{" "}
                                </div>
                              )}

                              <div className="table">
                                {table.fields &&
                                  table.fields.map((field: any, k: number) => {
                                    field.format === "currency" &&
                                      currencyFields.push(field.id);

                                    return (
                                      <>
                                        <div className="label-section">
                                          <h2>{field.prevLabel}</h2>
                                        </div>
                                        <div className="input-pack" key={k}>
                                          <InputCalculator
                                            className={`input`}
                                            refs={addRefs}
                                            id={field.id}
                                            type={field.type}
                                            disabled={field.disabled}
                                            placeHolder={field.placeHolder}
                                            required={field.required}
                                            value={field.value}
                                            validate={validate}
                                            setValid={setValidate}
                                          />
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                );
              })}

            <div className="bottom-calc-section">
              <ButtonPersonalized
                label={"CALCULAR"}
                fontSize={22}
                height={45}
                onClick={handleButtonClick}
              />
              <ButtonPersonalized
                label={"LIMPIAR"}
                fontSize={22}
                height={45}
                onClick={handleClearClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calculator;
