import { config } from "../../../config"

const baseUrl = config.url.images.homeIcons;

export const SideBarData = [
    {
        title: 'INICIO',
        path: '/home',
        icon: `${baseUrl}Sitiobemaster35.svg`,
        cName: ''
    },
    {
        title: 'MI PERFIL',
        path: '/profile',
        icon: `${baseUrl}Sitiobemaster53.svg`,
        cName: ''
    },
    {
        title: 'MI CARRERA',
        path: '/career',
        icon: `${baseUrl}Sitiobemaster41.svg`,
        cName: ''
    },
    {
        title: 'CLASSROOM',
        path: '/classroom',
        icon: `${baseUrl}Sitiobemaster36.svg`,
        cName: ''
    },
    {
        title: 'POWERCLASSES',
        path: '/powerclass',
        icon: `${baseUrl}Sitiobemaster37.svg`,
        cName: ''
    },
    {
        title: 'COMUNIDAD',
        path: '/community',
        icon: 'https://cdn.bemaster.com/media/icons/home/Sitiobemaster38.svg',
        cName: ''
    },
    {
        title: 'HERRAMIENTAS',
        path: '/tools',
        icon: 'https://cdn.bemaster.com/media/icons/home/Sitiobemaster57.svg',
        cName: ''
    },
    {
        title: 'AFILIACIÓN',
        path: '/afiliacion',
        icon: 'https://cdn.bemaster.com/media/icons/home/Sitiobemaster56.svg',
        cName: ''
    },
    {
        title: 'TIENDA',
        path: '/shopping',
        icon: 'https://cdn.bemaster.com/media/icons/home/Sitiobemaster40.svg',
        cName: ''
    },
    {
        title: 'SOPORTE',
        path: '/support',
        icon: 'https://cdn.bemaster.com/media/icons/home/Sitiobemaster42.svg',
        cName: ''
    },
    {
        title: 'MARKETPLACE',
        path: '/marketplace',
        icon: 'https://cdn.bemaster.com/media/icons/home/Marketplace.svg',
        cName: ''
    },
]
