import { ReactComponent as Flame } from "../../assets/images/profile/flame.svg";
import { useEffect, useState } from "react";
import { BsArrowLeft, BsFillHeartFill } from "react-icons/bs";
import ReactStars from "react-stars";
import { GrPlayFill } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import { StudyCase } from "../../interfaces/studyCase";
import { GlobalStyles } from "../../shared/global-styles/global-styles";
import { config } from "../../config";

import "./powerclass-card-master.styles.scss";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useCurrentCareerId } from "../../hooks/useCareer";

type PowerClassCardMaster = {
  payed: boolean;
  category: StudyCase;
  tags?: string;
  totalClasses?: string;
  totalDuration?: string;
  width?: string;
  type?: string;
};

const PowerclassCardMaster = ({
  payed,
  category,
  tags,
  totalClasses,
  totalDuration,
  width = "195px",
  type = "powerclass",
}: PowerClassCardMaster) => {
  const [fav, setFav] = useState<boolean>(
    Boolean(
      category.userContentCategory[0] != undefined
        ? category.userContentCategory[0].isFavourite
        : false
    )
  );
  const [allTags, setAllTags] = useState<string[]>([]);
  const [minutes, setMinutes] = useState<number>();
  const [hours, setHours] = useState<number>();
  const [hover, setHover] = useState(false);
  const CURRENT_CAREER_ID = useCurrentCareerId();
  const mobileScreen = useMobileScreen();
  const careerId = ( CURRENT_CAREER_ID === 10 || CURRENT_CAREER_ID === 11 || CURRENT_CAREER_ID === 12);
  
  const history = useHistory();

  useEffect(() => {
    const timeFormat = () => {
      if (totalDuration) {
        let hours = parseInt(totalDuration) / 60;
        let shortHour = Math.floor(hours);
        let minutes = (hours + "").split(".")[1];
        let mins = minutes?.substring(0, 2);

        setHours(shortHour);
        setMinutes(parseInt(mins));
      }
    };

    timeFormat();
  }, [totalDuration]);

  useEffect(() => {
    if (tags != null) {
      setAllTags(tags.split(","));
    }
  }, [tags]);

  const handleClick = () => {
    const locationState =
      type === "powerclass"
        ? {
            from: "powerclass",
            fav,
          }
        : {
            fav,
          };
    category.externalUrl
      ? window.open(category.externalUrl, "_blank")
      : history.push(
          `${history.location.pathname}/${category.idContentCategory}`,
          locationState
        );
  };

  const size = mobileScreen ? "pcard-sm" : "pcard";
  const urlImgCard = `${config.url.images.powerclasses}${size}${category.idContentCategory}.png`;

  return (
    <div className="container-link" onClick={handleClick}>
      <div className="master-card-container" style={{ width }}>
        <div
          className="master-card"
          style={{ backgroundImage: `url(${urlImgCard})` }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {category.urlTrailer && hover && (
            <video autoPlay={true} loop={true} muted={!hover}>
              <source
                src={`${config.url.videos.powerclasses}${category.urlTrailer}`}
                type="video/mp4"
              />
            </video>
          )}

          <div
            className="default-layout"
            id="default"
            style={{
              display: !hover ? "flex" : "none",
              justifyContent: careerId ? "end" : "space-between",
            }}
          >
            {!careerId ? (
              <img
                src={
                  type === "powerclass"
                    ? config.url.images.powerclassLogo
                    : config.url.images.mentortalkLogo
                }
                alt="power-logo"
              />
            ) : null}
            <h1>
              {" "}
              {type === "powerclass"
                ? category.master.toUpperCase()
                : category.description}{" "}
            </h1>
          </div>

          <div
            className="hover-layout"
            style={{
              display:
                (hover && category.value == 0) ||
                (hover && category.value > 0 && payed)
                  ? "flex"
                  : "none",
            }}
          >
            <div className="content">
              <div className="card-actions">
                <div className="back-arrow"></div>
                <div className="play-icon">
                  <GrPlayFill />
                </div>
                <div className="rounded-container">
                  <BsFillHeartFill
                    className="heart"
                    style={{
                      color: fav
                        ? GlobalStyles.colors.red
                        : GlobalStyles.colors.grayLighter,
                    }}
                  />
                </div>
              </div>
              <h1>
                {type === "powerclass"
                  ? category.master.toUpperCase()
                  : category.description}{" "}
              </h1>
              {/* <AiOutlineArrowLeft size={40} /> */}
              <ReactStars
                count={5}
                value={
                  category.ratingContentCategory != null
                    ? category.ratingContentCategory.rating
                    : 0
                }
                size={15}
                half={true}
                edit={false}
                color1={"white"}
                color2={GlobalStyles.colors.brown}
              />
              <div className="classes-details">
                {totalClasses && totalClasses !== "0" && (
                  <div className="class-quantity">
                    <h2>
                      {totalClasses}
                      {totalClasses > "1" ? " Clases" : " Clase"}
                    </h2>
                  </div>
                )}
                {hours && minutes && (
                  <h2>
                    {hours}h {minutes}m
                  </h2>
                )}
              </div>

              <div className="mentor-data">
                {allTags.map((topic: string, i: number) => {
                  return (
                    <div className="data">
                      <img
                        src={`${config.url.images.powerclasses}thundersito-icon.svg`}
                        alt="thunder"
                      />
                      <h3>{topic}</h3>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="payment-content"
            style={{
              display: hover && category.value > 0 && !payed ? "flex" : "none",
            }}
          >
            <Flame className="price-coin" height={30} width={30} />
            <div className="price-text">{category.value}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerclassCardMaster;
