import React, { Context, createContext, useEffect, useReducer } from "react";
import { TourCard, tourdata } from "../components/layout/tour/tour.data";
import { User, userInitialState } from "../interfaces/user";
import { CareerContext, CareerReducer, CAREER_INFO } from "./careerContext";

export interface ToastInfo {
  toasts: string[];
}

export interface Tourinfo {
  content: TourCard[];
  index: number;
  onTour: boolean;
}

export interface InfoCareer {
  careerList: any; 
  selectedCareer: number; 
  careerId: number;
} 
export interface LoginInfo {
  authenticated: boolean;
  infoCareers: InfoCareer;
  infoTriumphs: { listUserTriumphs: any };
  showOnboarding: boolean;
  idProfile: number;
  infoContent: { listUserContent: any };
  idUserAff?: number;
  urlProfilePhoto: string;
  idUserMasterTools?: number;
  initialForm: any;
  initialFormId: string;
  showForm: boolean;
}
export interface UserInfo {
  userInfo?: User;
  preferences?: any[] | undefined;
}

export const startInfo = (): LoginInfo => ({
  authenticated: false,
  infoCareers: { careerList: [], selectedCareer: 0, careerId: 0 },
  infoTriumphs: { listUserTriumphs: [] },
  showOnboarding: false,
  idProfile: 1,
  infoContent: { listUserContent: [] },
  idUserAff: undefined,
  urlProfilePhoto: "",
  idUserMasterTools: undefined,
  initialForm: "",
  initialFormId: "",
  showForm: false,
});

export const START_INFO: LoginInfo = {
  authenticated: false,
  infoCareers: { careerList: [], selectedCareer: 0, careerId: 0 },
  infoTriumphs: { listUserTriumphs: [] },
  showOnboarding: false,
  idProfile: 1,
  infoContent: { listUserContent: [] },
  idUserAff: undefined,
  urlProfilePhoto: "",
  idUserMasterTools: undefined,
  initialForm: "",
  initialFormId: "",
  showForm: false,
};

export const TOUR_INFO: Tourinfo = {
  content: tourdata,
  index: 0,
  onTour: false,
};

export const USER_INFO: UserInfo = {
  userInfo: userInitialState,
  preferences: [],
};

export interface AuthContext {
  loginInfo: LoginInfo;
  dispatch: React.Dispatch<any>;
  tourInfo: Tourinfo;
  dispatchTour: React.Dispatch<any>;
  userInfo: UserInfo;
  dispatchUserInfo: React.Dispatch<any>;
}

const AuthReducer = (loginInfo: LoginInfo, newLoginInfo: LoginInfo) => {
  if (newLoginInfo === null) {
    localStorage.removeItem("loginInfo");
    return START_INFO;
  }
  return { ...loginInfo, ...newLoginInfo };
};

const TourReducer = (tourInfo: Tourinfo, newTourInfo: Tourinfo) => {
  return {
    ...tourInfo,
    ...newTourInfo,
  };
};

const UserInfoReducer = (userInfo: UserInfo, newUserInfo: UserInfo) => {
  return {
    ...userInfo,
    ...newUserInfo,
  };
};

const AppContext: Context<AuthContext> = createContext({
  loginInfo: START_INFO,
  tourInfo: TOUR_INFO,
} as AuthContext);

const initialState =
  JSON.parse(localStorage.getItem("loginInfo") as string) || START_INFO;

const AppProvider: React.FC = ({ children }) => {
  const [loginInfo, dispatch] = useReducer(AuthReducer, initialState);
  const [tourInfo, dispatchTour] = useReducer(TourReducer, TOUR_INFO);
  const [userInfo, dispatchUserInfo] = useReducer(UserInfoReducer, USER_INFO);
  const [careerInfo, dispatchCareerInfo] = useReducer(
    CareerReducer,
    CAREER_INFO
  );

  useEffect(() => {
    localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
  }, [loginInfo]);

  return (
    <AppContext.Provider
      value={{
        loginInfo,
        dispatch,
        tourInfo,
        dispatchTour,
        userInfo,
        dispatchUserInfo,
      }}
    >
      <CareerContext.Provider value={{ careerInfo, dispatchCareerInfo }}>
        {children}
      </CareerContext.Provider>
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
