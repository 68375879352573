import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/context";
import { UserInfo } from "../../interfaces/userInfo";
import { User } from "../../interfaces/user";
import { LoginInfo } from "../../context/context";

declare const window: any;

const CrispChatBox = () => {
  const appContext = useContext(AppContext);
  const dataUser: User | undefined = appContext?.userInfo?.userInfo;
  const loginInfo: LoginInfo = appContext?.loginInfo;

  const userId = dataUser?.idUser;
  const username = dataUser?.firstName;
  const lastName = dataUser?.lastName;
  const email = dataUser?.email;
  const phone = dataUser?.phone;
  const cantFires = dataUser?.cantFires;
  const commission = dataUser?.commission;
  const facebook = dataUser?.facebook;
  const idUserAff = loginInfo.idUserAff;
  const status = dataUser?.status;
  const userCareer = dataUser?.userCareer;

  const location = useLocation();

  useEffect(() => {
    window.$crisp = [];

    // USER DATA
    window.$crisp.push(["set", "user:nickname", [`${username}`]]);
    window.$crisp.push(["set", "user:email", [`${email}`]]);
    window.$crisp.push(["set", "user:phone", [`${phone}`]]);
    window.$crisp.push(["set", "session:data", [[["Fuegos", `${cantFires}`]]]]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Comision", `${commission}`]]],
    ]);

    //SUBSCRIPTION DATA
    window.$crisp.push([
      "set",
      "session:data",
      [[["Facebook", `${facebook}`]]],
    ]);
    idUserAff &&
      window.$crisp.push([
        "set",
        "session:data",
        [[["ID_User_Affiliation", `${idUserAff}`]]],
      ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Estado", `${status ? "Activo" : "Inactivo"}`]]],
    ]);
    userCareer &&
      window.$crisp.push([
        "set",
        "session:data",
        [[["User_Career", `${userCareer}`]]],
      ]);

    window.CRISP_WEBSITE_ID = "50f8e81f-ea27-4473-aa43-97149c031776";

    (async () => {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.id = "crispScript";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    return () => {
      window.$crisp.push(["do", "chat:hide"]);
    };
  }, [location]);

  return <></>;
};

export default CrispChatBox;
