import { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import { ClassAward } from "../../interfaces/classAward";
import { classInitialState, ClassInterface } from "../../interfaces/classes";
import { ClassMission } from "../../interfaces/classMission";
import { ListCareerContent } from "../../interfaces/listCareerContent";
import { ModuleClass } from "../../interfaces/moduleClass";
import InternalClassroomLayout from "../internal-classroom-layout/internal-classroom-layout.component";
import Missions from "../missions/missions.component";
import RewardAnimation from "../reward-animation/reward-animation";
import RewardModal from "../reward-modal/reward-modal.component";

import "./classroom.styles.scss";

const Classroom = (props: any) => {
  const history = useHistory();

  const [currentModule, setCurrentModule] = useState<any>([]);
  const [currentClass, setCurrentClass] = useState<ModuleClass>();
  const [currentMission, setCurrentMission] = useState([]);
  const [indexCurrentMission, setIndexCurrentMission] = useState(0);
  const [classMissions, setClassMissions] = useState<ClassMission[]>([]);
  const [classAwards, setClassAwards] = useState<ClassAward[]>([]);
  const [nameModule, setNameModule] = useState("");
  const [nameClassModule, setNameClassModule] = useState("");
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [pclass, setPclass] = useState<ClassInterface>(classInitialState);
  const [nclass, setNclass] = useState<ClassInterface>(classInitialState);
  const [loading, setLoading] = useState<Boolean>(false);
  const appContext = useContext(AppContext);

  // const tourIndex = appContext.tourInfo.index;

  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idUserCareer = career.idUserCareer;
  let idCareer = career.career.idCareer;

  let idCurrentModule =
    career.idCurrentModule == null ? 0 : career.idCurrentModule;
  let idCurrentClass =
    career.idCurrentClass == null ? 0 : career.idCurrentClass;
  let idCurrentClassMission =
    career.idCurrentClassMission == null || career.idCurrentClassMission == 0
      ? -1
      : career.idCurrentClassMission;

  if (props.location.state != undefined) {
    idCurrentModule = props.location.state.idModule;
    idCurrentClass = props.location.state.idClass;
  }

  const updateClassAward = (classAwards: any) => {
    setClassAwards(classAwards);
  };

  const handleReward = async () => {
    let idModuleClass = currentClass?.idModuleClass;
    changeAnimState();

    await AxiosConfig.post("/Career/ClaimReward", {
      listInts: [idUserCareer, idModuleClass],
    }).then((response) => {
      if (response.data.result === "success") {
        let classAwards = response.data.data.classAwards;
        setClassAwards(classAwards);
      }
    });
  };

  const saveCurrentMission = (mission: any, index: number) => {
    setCurrentMission(mission);
    setIndexCurrentMission(index);
    setMissionsCollapsed(!missionsCollapsed);
  };

  const changeCheckedMission = (mission: any, index: number) => {
    setLoading(true);
    let lisMissions: any = [...classMissions];
    lisMissions[index] = mission;
    setClassMissions(lisMissions);

    reviewClassCompleted([...lisMissions], [...classAwards]);
  };

  // Con esta función y los dos estados controlamos el popup de reclamar recompesas
  const reviewClassCompleted = (lisMissions: any, listAwards: any) => {
    let incompletedMissions = lisMissions.filter(
      (item: any) =>
        item.userCareerProgress != undefined &&
        (item.userCareerProgress.length <= 0 ||
          item.userCareerProgress[0].completed == 0)
    ).length;

    // incompletedMissions <= 0 ?? setCompletedClass(true);

    let unClaimedRewards = listAwards.filter(
      (item: any) => item.award.userAward.length <= 0
    ).length;

    if (incompletedMissions <= 0 && unClaimedRewards > 0) {
      if (idCareer != 3) {
        setLoading(false);
        setShowRewardModal(true);
        saveReward();
      }
    }

    setLoading(false);
  };

  const saveReward = async () => {
    let idModuleClass = currentClass?.idModuleClass;

    await AxiosConfig.post("/Career/ClaimReward", {
      listInts: [idUserCareer, idModuleClass],
    }).then((response) => {
      if (response.data.result === "success") {
        let classAwards = response.data.data.classAwards;
        setClassAwards(classAwards);
      }
    });
  };

  useEffect(() => {
    let isSubscribed = true;

    fetchData(isSubscribed);

    return () => {
      isSubscribed = false;
    };
  }, []);

  const fetchData = async (isSubscribed: boolean) => {
    setShowRewardModal(false);

    await AxiosConfig.post("/Career/GetCareerContent/", {
      listInts: [idCareer, idCurrentModule, idCurrentClass],
    }).then((result) => {
      if (isSubscribed) {
        if (result.data.result === "success") {
          let listCareerContent: ListCareerContent[] =
            result.data.data.listCareerContent.sort(
              (a: any, b: any) => a.order - b.order
            );

          let module: ListCareerContent;
          let moduleClass: ModuleClass;
          let missionClass: ClassMission;

          let indexModule = 0;
          let indexModuleClass = 30;
          let indexMissionClass = 0;

          if (idCurrentClassMission !== -1) {
            listCareerContent.map(
              (itModule: ListCareerContent, ixModule: number) => {
                itModule.moduleClass
                  .sort((a: any, b: any) => a.order - b.order)
                  .map((itModuleClass: any, ixModuleClass: number) => {
                    itModuleClass.classMission
                      .sort((a: any, b: any) => a.order - b.order)
                      .map((itClassMission: any, ixClassMission: number) => {
                        if (
                          itClassMission.idClassMission == idCurrentClassMission
                        ) {
                          indexModule = ixModule;
                          indexModuleClass = ixModuleClass;
                          indexMissionClass = ixClassMission;
                        }
                      });
                  });
              }
            );
          }

          module = listCareerContent[indexModule];

          moduleClass =
            module.moduleClass.length > 1
              ? module.moduleClass.sort((a: any, b: any) => a.order - b.order)[
                  indexModuleClass
                ]
              : module.moduleClass[0];

          if (moduleClass) {
            missionClass = moduleClass.classMission.sort(
              (a: any, b: any) => a.order - b.order
            )[indexMissionClass];

            setPclass(result.data.data.pclass);
            setNclass(result.data.data.nclass);

            setCurrentModule(module);
            setNameModule(module.description);

            setCurrentClass(moduleClass);
            setNameClassModule(moduleClass.class);

            setClassMissions(moduleClass.classMission);
            setClassAwards(moduleClass.classAward);

            saveCurrentMission(missionClass, indexMissionClass);
            reviewClassCompleted(
              moduleClass.classMission,
              moduleClass.classAward
            );
          }
        }
      }
    });
  };

  const handlePrevClass = async () => {
    if (!isFirstClass()) {
      idCurrentClassMission = -1;
      idCurrentModule = pclass.idModule;
      idCurrentClass = pclass.idClass;

      updateCurrentVars(pclass.idModule, pclass.idClass, 0);
      await fetchData(true);
    }
  };

  const handleNextClass = async () => {
    if (!isLastClass()) {
      idCurrentClassMission = -1;
      idCurrentModule = nclass.idModule;
      idCurrentClass = nclass.idClass;

      updateCurrentVars(nclass.idModule, nclass.idClass, 0);
      await fetchData(true);
    }
  };

  const updateCurrentVars = (
    idCurrentModule: number,
    idCurrentClass: number,
    idCurrentClassMission: number
  ) => {
    let loginInfo: any = appContext.loginInfo;

    loginInfo.infoCareers.careerList[selectedCareer].idCurrentModule =
      idCurrentModule;
    loginInfo.infoCareers.careerList[selectedCareer].idCurrentClass =
      idCurrentClass;
    loginInfo.infoCareers.careerList[selectedCareer].idCurrentClassMission =
      idCurrentClassMission;
    appContext.dispatch(loginInfo);
  };

  const isFirstClass = (): Boolean => {
    let p = { ...pclass };
    return p.idModule == -1 && p.idClass == -1;
  };

  const isLastClass = (): Boolean => {
    let n = { ...nclass };
    return n.idModule == -1 && n.idClass == -1;
  };

  const [missionsCollapsed, setMissionsCollapsed] = useState(false);

  const handleMissionsModalCollapsed = () => {
    setMissionsCollapsed(!missionsCollapsed);
  };

  const handleLoader = (state: boolean) => {
    setLoading(state);
  };

  const [anim, setAnim] = useState<boolean>(false);

  const changeAnimState = () => {
    setShowRewardModal(!showRewardModal);
  };

  return (
    <div
      className="main-container"
      style={{ marginLeft: idCareer !== 4 ? "3rem" : "unset" }}
      onClick={() => setAnim(true)}
    >
      <>
        <RewardModal
          showModal={showRewardModal}
          currentClass={currentClass}
          updateClassAward={updateClassAward}
        />
        <div className="toggle-button" onClick={handleMissionsModalCollapsed}>
          <div className="collapsed-arrow">
            <MdKeyboardArrowLeft
              style={{ display: missionsCollapsed ? "flex" : "none" }}
            />
          </div>
          <div className="uncollapsed-arrow">
            <MdKeyboardArrowRight
              style={{ display: !missionsCollapsed ? "flex" : "none" }}
            />
          </div>
        </div>
        {/* </div> */}
        <div className="class-title-section is-flex ml-6">
          <div className="class-title" onClick={() => history.push("/career")}>
            <h1 className="class-link">MI CARRERA</h1>
          </div>
          <div className="separator is-flex is-size-3">
            <MdKeyboardArrowRight />
          </div>
          <div
            className="class-title"
            onClick={() =>
              history.push("/career", {
                from: currentModule,
              })
            }
          >
            <h1 className="class-link">{nameModule.toUpperCase()}</h1>
          </div>
          <div className="separator is-flex is-size-3">
            <MdKeyboardArrowRight />
          </div>
          <div className="topic">
            <h1>{nameClassModule}</h1>
          </div>
        </div>
        <div className="main-content-section is-flex mb-6">
          <Scrollbar
            className={"custom-scroll"}
            noScrollX={true}
            width={"76%"}
            height={"98%"}
          >
            <InternalClassroomLayout
              mission={currentMission}
              indexMission={indexCurrentMission}
              changeCheckedMission={changeCheckedMission}
              idCareerModule={idCurrentModule}
              handleLoader={handleLoader}
            />
          </Scrollbar>

          <div
            className={`right-area ${
              !missionsCollapsed ? "missions-collapsed" : "missions-uncollapsed"
            }`}
          >
            <div
              className="missions-panel"
              style={{
                maxHeight: window.innerHeight - window.innerHeight * 0.8,
              }}
            >
              <div className={`missions-section ml-3`}>
                <div className="missions">
                  <Missions
                    updateChildComponent={saveCurrentMission}
                    classMission={classMissions}
                    clsAward={classAwards}
                    changeCheckedMission={changeCheckedMission}
                    idCareerModule={idCurrentModule}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="prev-next-buttons ml-3 mt-2">
                <div
                  className={`prev-button mr-4 ${loading ? "disabled" : ""} ${
                    isFirstClass() ? "disabled" : ""
                  }`}
                  onClick={() => handlePrevClass()}
                >
                  <div className="has-text-weight-bold is-size-5 mr-2">
                    <MdKeyboardArrowLeft />
                  </div>
                  <a className={`prev-button`}>CLASE ANTERIOR</a>
                </div>
                <div
                  className={`next-button ml-4 ${loading ? "disabled" : ""}  ${
                    isLastClass() ? "disabled" : ""
                  }`}
                  onClick={() => handleNextClass()}
                >
                  <a className={`next-button`}>SIGUIENTE CLASE</a>
                  <div className="has-text-weight-bold is-size-5 ml-2">
                    <MdKeyboardArrowRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Classroom;
