export const floatSidebarData = [
    {
        id: 0,
        name: 'home',
        icon: 'home-white.svg',
        url: '/home'
    },
    {
        id: 1,
        name: 'classroom',
        icon: 'classroom.svg',
        url: '/classroom'
    },
    {
        id: 2,
        name: 'Mi carrera',
        icon: 'pensum.svg',
        url: '/career'
    },
    {
        id: 3,
        name: 'content',
        icon: 'content-white.svg',
        url: '/powerclass'
    },
    {
        id: 4,
        name: 'marketplace',
        icon: 'marketplace-white.svg',
        url: '/marketplace'
    },
    {
        id: 5,
        name: 'support',
        icon: 'support.svg',
        url: '/support'
    },
]