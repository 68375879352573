import { useContext, useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../../context/context";
import { useCurrentCareerId } from "../../../hooks/useCareer";
import { useMobileScreen } from "../../../hooks/useMobileScreen";
import { ISidebar } from "../../../interfaces/sidebar";
import { SideBarData } from "./sidebar.data";

import "./sidebar.styles.scss";

interface Props {
  handlecollapse: Function;
  collapse: boolean;
}

const Sidebar = ({ handlecollapse, collapse }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const mobileScreen = useMobileScreen();

  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;

  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  const careerId = useCurrentCareerId();

  const [filteredSidebarData, setFilteredSidebarData] =
    useState<ISidebar[]>(SideBarData);

  let canToBeAffiliate = career.canToBeAffiliate;

  useEffect(() => {
    if (tourName === "MENU DESPLEGABLE") {
      handlecollapse(false);
    } else {
      handlecollapse(true);
    }
  }, [appContext.tourInfo.index]);

  const openSidebar = (collapse: boolean) => {
    handlecollapse(collapse);
  };

  const path = matchPath<any>(useLocation().pathname, {
    path: "/mentortalks/:id",
    exact: true,
    strict: true,
  });

  // Setting Tools Item Available ONLY For Traffick Master Career
  useEffect(() => {
    let cloneSideBarData = [...SideBarData];
    let cloneSideBarDataElite = [];

    if (career.idCareer !== 2 && career.idCareer !== 9) {
      const toolsItemIndex = SideBarData.findIndex(
        (item) => item.title === "HERRAMIENTAS"
      );
      cloneSideBarData.find((tools) => tools.title === "HERRAMIENTAS") &&
        cloneSideBarData.splice(toolsItemIndex, 1);

      setFilteredSidebarData(cloneSideBarData);
    } else if (career.idCareer === 9) {
      cloneSideBarDataElite.push(
        SideBarData[0],
        SideBarData[1],
        SideBarData[2],
        SideBarData[3],
        SideBarData[6]
      );

      setFilteredSidebarData(cloneSideBarDataElite);
    } else {
      setFilteredSidebarData(SideBarData);
    }
  }, [selectedCareer]);

  return (
    <div
      className="sidebar-container"
      style={{
        width: !collapse ? "300px" : mobileScreen ? "0px" : "64px",
        zIndex: appContext.tourInfo.index >= 2 ? 106 : 90,
        background: mobileScreen
          ? collapse
            ? "transparent"
            : "#161616"
          : "#161616",
      }}
    >
      <div
        className="sidebar-content"
        style={{ width: !collapse ? "300px" : "64px" }}
      >
        <div
          className="is-header-section"
          onClick={() => openSidebar(!collapse)}
        >
          <div
            className={`is-sidebar-brand-icon ${
              path?.params.id ? "white-icon" : "brown-icon"
            }`}
            style={{ width: !collapse ? "" : "0px" }}
          ></div>
          <div
            className={
              path?.params.id
                ? "is-sidebar-white-arrow-icon"
                : "is-sidebar-arrow-icon"
            }
            style={{
              transform: !collapse ? "rotate(-180deg)" : "rotate(0deg)",
              display: "flex",
              marginLeft: collapse ? "0px" : "",
            }}
          ></div>
        </div>

        {filteredSidebarData
          ? filteredSidebarData.map((item: ISidebar, i) => (
              <div
                key={i}
                className="is-sidebar-item"
                style={{
                  display:
                    (item.path === "/afiliacion" && canToBeAffiliate !== 1) ||
                    (item.path === "/marketplace" && careerId !== 4)
                      ? "none"
                      : "flex",
                  pointerEvents: mobileScreen
                    ? collapse
                      ? "none"
                      : "unset"
                    : "unset",
                }}
                onClick={() => {
                  history.push(item.path);
                  openSidebar(true);
                }}
              >
                <div
                  id="itemIcon"
                  className="is-sidebar-icon"
                  style={{
                    opacity: mobileScreen ? (collapse ? "0" : "1") : "1",
                    backgroundImage: `url(${item.icon})`,
                    filter:
                      item.path === location.pathname
                        ? "sepia(100%) saturate(200%) brightness(100%)"
                        : "",
                  }}
                ></div>
                <div
                  id="itemName"
                  className="is-sidebar-name"
                  style={{
                    opacity: mobileScreen ? (collapse ? "0" : "1") : "1",
                  }}
                >
                  {item.title}
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default Sidebar;
