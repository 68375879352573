import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/context";
import ClassButton from "../../shared/components/class-button/class-button.component";
import ReminderMessage from "../reminder-message/reminder-message.component";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";
import AxiosConfig from "../../axios/axiosConfig";
import { CareerContext } from "../../context/careerContext";
import "./level-finished-message.styles.scss";

interface props {
  mission: any;
}

const LevelFinishedMessage = ({ mission }: props) => {
  const [showModalPending, setShowModalPending] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  let history = useHistory();
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];

  const careerContext = useContext(CareerContext);
  const missions = careerContext.careerInfo;

  let idUserCareer = career.idUserCareer;
  let idCareer = career.idCareer;

  const handleCloseButton = (active: boolean) => {
    setShowModalPending(active);
  };

  const handleClick = () => {
    setShowLoader(true);
    (async () => {
      try {
        const response = await AxiosConfig.post(
          "/Career/GetPermissionMCompleted/",
          {
            listInts: [idCareer, idUserCareer],
          }
        );
        if (response.data.result === "success") {
          missions.missionsCompleted = true;
          history.push("/diploma");
        } else if (response.data.result === "incomplete") {
          missions.missionsCompleted = false;
          setShowModalPending(true);
        }

        careerContext.dispatchCareerInfo(missions);
        setShowLoader(false);
      } catch (error) {
        console.error(error);
      }
    })();
  };

  let title = "¡TU TRABAJO MERECE RECONOCIMIENTO!";

  return (
    <div className="level-finished-message-container">
      {showLoader ? (
        <div className="bemaster-loader">
          <img src={BeMasterLoader} alt="BeMasterLoader" />
        </div>
      ) : (
        <>
          <ReminderMessage
            showModal={showModalPending}
            handleCloseButton={handleCloseButton}
          />
          <div className="title-area">
            <h1 className="is-size-4"> {title.toUpperCase()} </h1>
          </div>
          <div className="certification-area">
            <h1 className="is-size-6 mainMessage">
              {" "}
              {mission.title.toUpperCase()}{" "}
            </h1>
            <h1 className="is-size-6 secondMessage"> {mission.description} </h1>
            <img src={mission.url} alt="diploma" />
          </div>
          <div className="button-area">
            <ClassButton
              text={"RECLAMAR MI DIPLOMA"}
              colorButton={"green-petroleum"}
              height={40}
              fontSize={25}
              onClick={handleClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LevelFinishedMessage;
