import { useContext } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { AppContext } from '../../context/context';
import AwardCard from '../award-card/award-card.component';

import './triumph-resume.styles.scss';

const TriumphResume = () => {

    const appContext = useContext(AppContext);
    let listUserTriumphs = [...appContext.loginInfo.infoTriumphs.listUserTriumphs];
    listUserTriumphs = [...listUserTriumphs.sort((a: any,b: any) => a.categoryOrder - b.categoryOrder)];
    
    let listCategory = listUserTriumphs       
        .reduce((r, a, i) => {
            r[a.category] = [...r[a.category] || [], a];
            return r;
        }, {});

    return (
        <div className="triumphs-resume-container is-mobile">
            <Scrollbar style={{ paddingBottom: '100px', height: '100%', width: '115%' }} >
                {Object.keys(listCategory)
                    .map((key, i) => {
                        let awards = listUserTriumphs.filter(x => x.category === key);
                        return (
                            <div className="triumphs-resume" key={i}>
                                <div className="category-title-section">
                                    <h1 className="is-size-3 mb-4 mr-2"> {key.toLocaleUpperCase()} </h1>
                                    <h1 className="is-size-3 mb-4 subtitle">{i > 0 ? 'MASTER' : ''}</h1>
                                </div> 
                                <div className="awards-section">
                                    <AwardCard awards={awards} />
                                </div>
                            </div>
                        )
                    })}
            </Scrollbar>
        </div>
    );
};

export default TriumphResume;

// Note: 'AwardsByCareer' -> All the careers that users have, where 'General' is definitely persistent for every user.