import React, { useContext, useState } from 'react';
import { AiFillSetting } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { MdAttachMoney, MdPublic } from 'react-icons/md';
import { FaMedal } from "react-icons/fa";
import { ImFire } from "react-icons/im";
import { FaFlagCheckered } from 'react-icons/fa';
import { AppContext } from '../../context/context';
import { CareerReducer } from '../../context/careerContext';
import './navbar-profile.styles.scss';

interface props {
    handleLinks: (child: number) => void,
    collapse:any
}

const NavbarProfile = ({ handleLinks, collapse }: props) => {

    const appContext = useContext(AppContext);

    const careerId = appContext.loginInfo.infoCareers.careerId;
    
    const [itemSeleted, setItemSelected] = useState(0);

    const handleClick = (comp: number) => {
        setItemSelected(comp);
        handleLinks(comp);
    }
    
    return (
        <div className="navbar-profile-container is-flex is-mobile" >
            <div className={ `basic-info mr-3 ${careerId === 3 && 'change-color'} ${ collapse ? 'icon-z-index' : '' }` } onClick={() => handleClick(0)}
                style={{ backgroundColor: itemSeleted === 0  ? '#5e5e5e' : '', color: itemSeleted === 0 ? 'white' : '' }} >
                <h1 className="mx-4 is-hidden-mobile">INFO BÁSICA</h1>
                <BsFillInfoCircleFill className="is-hidden-desktop is-hidden-tablet" />
            </div>
            <div className={ `public-profile mx-3 ${careerId === 3 && 'change-color'}` } onClick={() => handleClick(1)}
                style={{ backgroundColor: itemSeleted === 1 ? '#5e5e5e' : '', color: itemSeleted === 1 ? 'white' : '' }} >
                <h1 className="mx-4 is-hidden-mobile">PERFIL PÚBLICO</h1>
                <MdPublic className="is-hidden-desktop is-hidden-tablet" />
            </div>
            <div className={ `preferences mx-3 ${careerId === 3 && 'change-color'}` } onClick={() => handleClick(2)}
                style={{ backgroundColor: itemSeleted === 2 ? '#5e5e5e' : '', color: itemSeleted === 2 ? 'white' : '' }} >
                <h1 className="mx-4 is-hidden-mobile">PREFERENCIAS</h1>
                <AiFillSetting className="is-hidden-desktop is-hidden-tablet" />
            </div>
            {careerId !== 10 ? <div className={ `triumphs mx-3 ${careerId === 3 && 'change-color'}` } onClick={() => handleClick(3)}
                style={{ backgroundColor: itemSeleted === 3 ? '#5e5e5e' : '', color: itemSeleted === 3 ? 'white' : '' }} >
                <h1 className="mx-4 is-hidden-mobile">TRIUNFOS</h1>
                <FaMedal className="is-hidden-desktop is-hidden-tablet" />
            </div> : null}
            <div className={ `fires mx-3 ${careerId === 3 && 'change-color'}` } onClick={() => handleClick(4)}
                style={{ backgroundColor: itemSeleted === 4 ? '#5e5e5e' : '', color: itemSeleted === 4 ? 'white' : '' }} >
                <h1 className="mx-4 is-hidden-mobile">FUEGOS</h1>
                <ImFire className="is-hidden-desktop is-hidden-tablet" />
            </div>
            {careerId !== 10 ? <div className={ `my-progress mx-3 ${careerId === 3 && 'change-color'}` } onClick={() => handleClick(5)}
                style={{ backgroundColor: itemSeleted === 5 ? '#5e5e5e' : '', color: itemSeleted === 5 ? 'white' : '' }} >
                <h1 className="mx-4 is-hidden-mobile">MI PROGRESO</h1>
                <FaFlagCheckered className="is-hidden-desktop is-hidden-tablet" />
            </div> : null}
            {careerId === 10 ? <div className={ `my-progress mx-3` } onClick={() => handleClick(6)}
                style={{ backgroundColor: itemSeleted === 6 ? '#5e5e5e' : '', color: itemSeleted === 6 ? 'white' : '' }} >
                <h1 className="mx-4 is-hidden-mobile">MI SUSCRIPCIÓN</h1>
                <MdAttachMoney className="is-hidden-desktop is-hidden-tablet" />
            </div> : null}
        </div>
    );
};

export default NavbarProfile;