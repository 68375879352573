import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClassCard from '../class-card/class-card.component';

import './modules-card.styles.scss';

type props = {
    listCareerContent: any,
    idCurrentModule: number,
    idCurrentClass: number
}

const ModulesCard = ({ listCareerContent, idCurrentModule, idCurrentClass }: props) => {

    const history: any = useHistory();
    const [startOpen, setStartOpen] = useState(history.location.state ? (history.location.state.from) : null);
    const [collapsed, setCollapsed] = useState("");

    useEffect(() => {
        if (startOpen) {
            setCollapsed(startOpen.idCareerModule);
        }
    }, [startOpen])

    let active = true;
    const ixCurrentModule = listCareerContent.findIndex((module: any) => module.idCareerModule === idCurrentModule);

    const handleCollapsed = (id: string) => {
        if (id == collapsed) {
            setCollapsed('');
        } else {
            setCollapsed(id);
        }
    }
    return (
        <div className="module-card">
            {listCareerContent.map((module: any, i: number) => {  
                
                if(i > ixCurrentModule)
                {
                    active = false;
                }

                let urlImg = 'https://cdn.bemaster.com/media/images/careers/BannerModule-'+module.idCareerModule+'.png';

                
                return (
                    <div className="module" key={i} >
                        <div className="main-module-container my-3" onClick={() => handleCollapsed(module.idCareerModule)} 
                            style={{backgroundImage: `url(${urlImg})`}}>
                            <h1 className="module-title is-size-3" > {module.description} </h1>
                            <h1 className="module-name is-size-5" > MODULO {i + 1} </h1>
                        </div>
                        <div className="wrap-container" style={{ 
                            display: ((collapsed == module.idCareerModule) ? 'flex' : 'none')
                        }}
                        >
                            <div className="modules-resume" >
                                <ClassCard moduleClass={module.moduleClass} idCurrentClass={idCurrentClass} activeModule={active} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

//style={{ display: (collapsed == module.idCareerModule) ? 'flex' : 'none' }}

export default ModulesCard;