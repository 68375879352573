import { useEffect } from "react";
import { Video } from "../../../interfaces/video";
import "./content-description.styles.scss";

interface ContentDescriptionProps {
  videoData: Video;
  sleep: boolean;
}

const ContentDescription = ({ videoData, sleep }: ContentDescriptionProps) => {
  
  const convertToPascalCase = (str: string) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\w+/g, (w: string) => {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
  };

  return (
    <div className={`${sleep ? 'sleep-container' : ''} main-content-description-container`} >
      <div className="inner-description-container">
        <span className="p-font">Estás viendo</span>
        <h1 className="mt-0 title-font chapter-title">
          {convertToPascalCase(videoData.name)}
        </h1>
        <h5 className="title-font">
          {convertToPascalCase(videoData.category)}
        </h5>
        <h5 className="my-3 title-font">Descripción</h5>
        <span className="p-font main-description">{videoData.description}</span>
      </div>
      <div className="state-description-container">
        <h6 className="title-font">Pause</h6>
      </div>
    </div>
  );
};

export default ContentDescription;
