import React from "react";
import { Toaster } from "react-hot-toast";
import { Switch } from "react-router-dom";
import Login from "../components/auth/components/login/login.component";
import { Register } from "../components/auth/components/register/register.component";
import Begining from "../components/begining/begining.component";
import Calculator from "../components/calculator/calculator.component";
import CareerPensum from "../components/career-pensum/career-pensum.component";
import Career from "../components/career/career.component";
import CertificationVideo from "../components/certification-video/certification-video.component";
import Classroom from "../components/classroom/classroom.component";
import Community from "../components/community/community.component";
import FiresStore from "../components/fires-store/fires-store.component";
import Home from "../components/home/home.component";
import Marketplace from "../components/marketplace/marketplace.component";
import MentortalksList from "../components/mentortalks/list/mentortalks-list.component";
import Mentortalks from "../components/mentortalks/mentortalks.component";
import NavbarClassroom from "../components/navbar-classroom/navbar-classroom.component";
import Navbar from "../components/navbar/navbar.component";
import PowerclassMasterList from "../components/powerclass-master-list/powerclass-master-list.component";
import PowerClass from "../components/powerclass/powerclass.component";
import Profile from "../components/profile/profile.component";
import RequestAffiliation from "../components/request-affiliation/request-affiliation.component";
import Shopping from "../components/shopping/shopping.component";
import Mail from "../components/support/mail/mail.component";
import Support from "../components/support/support.component";
import ToolList from "../components/tool-list/tool-list.component";
import Tools from "../components/tools/tools.component";
import VisualizeVideo from "../components/visualize-video/visualize-video.component";
import { AppProvider } from "../context/context";
import { GlobalStyles } from "../shared/global-styles/global-styles";
import AppAuth from "./AppAuth";

const AppRouter = () => {
  return (
    <AppProvider>
      <Toaster
        position="bottom-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: GlobalStyles.colors.greenPetroleum,
              secondary: "white",
            },
          },
        }}
      />
      <Switch>
        <AppAuth
          path="/login"
          Comp={Login}
          hasLayout={false}
          NavbarComponent={Navbar}
          backgroundClass={"has-login-background"}
        />
        <AppAuth
          path="/begin"
          Comp={Begining}
          hasLayout={false}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
        />
        <AppAuth
          path="/home"
          Comp={Home}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
          breadcrumb={"INICIO"}
        />
        <AppAuth
          path="/diploma"
          Comp={CertificationVideo}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
          breadcrumb={"DIPLOMA"}
        />
        <AppAuth
          path="/afiliacion"
          Comp={RequestAffiliation}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
          breadcrumb={"AFILIACIÓN"}
        />
        <AppAuth
          path="/register"
          Comp={Register}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
        />
        <AppAuth
          path="/careers"
          Comp={Career}
          hasLayout={false}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
        />
        <AppAuth
          path="/career"
          Comp={CareerPensum}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
          breadcrumb={"MI CARRERA"}
        />
        <AppAuth
          path="/powerclass/:id"
          Comp={PowerclassMasterList}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-powerclass-background"}
          breadcrumb={"POWERCLASSES"}
        />
        <AppAuth
          path="/powerclass"
          Comp={PowerClass}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-powerclass-background"}
          breadcrumb={"POWERCLASSES"}
        />
        <AppAuth
          path="/marketplace"
          Comp={Marketplace}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"is-support-layer"}
          breadcrumb={"Marketplace"}
        />
        {/* <AppAuth
          path="/mentortalks/:id"
          Comp={MentortalksList}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-mentortalks-videos-background"}
          breadcrumb={"MENTORTALKS"}
        />
        <AppAuth
          path="/mentortalks"
          Comp={Mentortalks}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-mentortalks-background"}
          breadcrumb={"MENTORTALKS"}
        /> */}
        <AppAuth
          path="/tools"
          Comp={Tools}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"is-support-layer"}
          breadcrumb={"HERRAMIENTAS"}
        />
        <AppAuth
          path="/lista-herramientas/:id"
          Comp={ToolList}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"tools-layer"}
          breadcrumb={"HERRAMIENTAS"}
        />
        <AppAuth
          path="/calculator/:id"
          Comp={Calculator}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"is-support-layer"}
          breadcrumb={"CALCULADORA"}
        />
        <AppAuth
          path="/video"
          Comp={VisualizeVideo}
          hasLayout={false}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
        />
        <AppAuth
          path="/shopping"
          Comp={Shopping}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
          comVisible={false}
          breadcrumb={"SHOP"}
        />
        <AppAuth
          path="/firesShop"
          Comp={FiresStore}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
          comVisible={false}
          breadcrumb={"COMPRA TUS FUEGOS"}
        />
        <AppAuth
          path="/profile"
          Comp={Profile}
          hasLayout={true}
          NavbarComponent={NavbarClassroom}
          backgroundClass={"has-custom-background"}
          breadcrumb={"MI CUENTA"}
        />
        <AppAuth
          path="/community"
          Comp={Community}
          hasLayout={true}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
          navbarColor={false}
          comVisible={false}
          breadcrumb={"COMUNIDAD"}
        />
        <AppAuth
          path="/classroom"
          Comp={Classroom}
          hasLayout={true}
          backgroundClass={"has-custom-background"}
          NavbarComponent={NavbarClassroom}
          breadcrumb={"CLASSROOM"}
        />
        <AppAuth
          path="/support"
          Comp={Support}
          hasLayout={true}
          backgroundClass={""}
          NavbarComponent={<></>}
          breadcrumb={"SOPORTE"}
        />
        <AppAuth
          path="/mail"
          Comp={Mail}
          hasLayout={true}
          backgroundClass={"is-support-layer"}
          NavbarComponent={NavbarClassroom}
          breadcrumb={"SOPORTE"}
        />
        <AppAuth
          path="/"
          Comp={Login}
          hasLayout={false}
          NavbarComponent={Navbar}
          backgroundClass={"has-background-image"}
        />
      </Switch>
    </AppProvider>
  );
};

export default AppRouter;
