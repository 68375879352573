import React, { useContext, useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import ModulesCard from "../modules/modules-card.component";
import ProgressBar from "../progress-bar/progress-bar.component";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";

import "./career-pensum.styles.scss";
import { useHistory } from "react-router-dom";

const CareerPensum = (props: any) => {
  const history: any = useHistory();
  const [startY, setStartY] = useState<number | undefined>(
    history.location.state
      ? (history.location.state.from.order - 1) * 164
      : undefined
  );

  const [contentCareer, setContentCareer] = useState([]);

  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;

  const infoCareer: any = appContext.loginInfo.infoCareers;

  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];

  let idCurrentModule =
    career.idCurrentModule == null || career.idCurrentModule == 0
      ? -1
      : career.idCurrentModule;
  let idCurrentClass =
    career.idCurrentClass == null || career.idCurrentClass == 0
      ? -1
      : career.idCurrentClass;
  let idCareer = career.career.idCareer;
  let level: string = career.level;
  let eMMCareerId = 3;

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = () => {
      AxiosConfig.get("/Career/GetCareerPensum/" + idCareer).then((result) => {
        if (isSubscribed) {
          if (result.data.result == "success") {
            let listCareerContent = result.data.data.listCareerContent.sort(
              (a: any, b: any) => a.order - b.order
            );

            setContentCareer(listCareerContent);
          }
        }
      });
    };

    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className="career-pensum-section">
      <div className="superior-section">
        <div className="career-header">
          <h1 className="is-size-3 has-text-weight-bold">
            {`${idCareer === eMMCareerId ? "CRONOGRAMA" : "MI CARRERA"}`}
          </h1>
        </div>
        <div className="progress-state mt-2">{/* <ProgressBar /> */}</div>
      </div>
      <div
        className="inferior-section py-5 mt-5"
        style={{
          zIndex: tourName === "MI CARRERA" ? 105 : "unset",
        }}
      >
        <Scrollbar scrollTop={startY}>
          {/* <Scrollbar> */}
          {contentCareer.length !== 0 && (
            <ModulesCard
              listCareerContent={contentCareer}
              idCurrentModule={idCurrentModule}
              idCurrentClass={idCurrentClass}
            />
          )}
        </Scrollbar>
      </div>
    </div>
  );
};

export default CareerPensum;
