import React, { useState } from 'react';
import ClassButton from '../../shared/components/class-button/class-button.component';
import { useHistory } from 'react-router-dom';

import './class-card.styles.scss';
import { GoCheck } from 'react-icons/go';

interface props {
    moduleClass: any,
    idCurrentClass: number,
    activeModule: boolean
}

const ClassCard = ({ moduleClass, idCurrentClass, activeModule }: props) => {

    let history = useHistory();
    let active = true;

    let ixCurrentClass = moduleClass.findIndex((classData: any) => classData.idModuleClass === idCurrentClass);

    if (activeModule && ixCurrentClass == -1) {
        ixCurrentClass = moduleClass.length;
    }
    else if (activeModule && ixCurrentClass != -1) {
        //Con esto habilita la siguiente clase
        //ixCurrentClass = ixCurrentClass + 1;
    }

    const handleClick = (idModule: number, idClass: number) => {
        history.push('/classroom/', { idModule, idClass });
    }

    return (
        <div className="class-card" >
            <div className="is-card" >
                {moduleClass
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((classData: any, j: number) => {

                        // if (j > ixCurrentClass) {
                        //     active = false;
                        // }

                        return (

                            <div key={j} className="class-card-container" style={{ backgroundColor: classData.completed === 0 ? '#161616' : '#5e5e5e' }}>
                                <h2 className="has-text-white" >{classData.class}</h2>
                                <div className="button-area" style={{ display: active ? 'flex' : 'none' }}>
                                    <ClassButton text={'VER CLASE'}
                                        colorButton={'red'} height={22} marginTop={5}
                                        onClick={() => handleClick(classData.idCareerModule, classData.idModuleClass)} />
                                </div>
                                {
                                    classData.completed !== 0 && <GoCheck className="check"/>
                                }
                            </div>

                        )
                    })}
            </div>
        </div>
    );
};

export default ClassCard;