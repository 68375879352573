import '../../shared/sass/icons.styles.scss';
import './rewards.styles.scss';

interface props {
    clsAward: Array<any>,
    updateRewardPadlock: (state: number) => void,
}

const Rewards = ({ clsAward, updateRewardPadlock }: props) => {
    return (
        <div className="has-text-white">
            {clsAward.map((reward) => {
                let statePadlock: number = (reward.award.userAward.length > 0 ? 1 : 0);
                updateRewardPadlock(statePadlock);

                let urlIcon = 'https://cdn.bemaster.com/media/images/profile/flame.svg'
                let plus = ""

                if (reward.award.type === "Fires") {
                    plus = " + ";
                }
                else if (reward.award.type === "Triumphs") {
                    urlIcon = 'https://cdn.bemaster.com/media/icons/triumph/white/triumph-' + reward.award.idAward + '.svg';
                }
                else if (reward.award.type === "Mentortalk") {
                    urlIcon = 'https://cdn.bemaster.com/media/icons/home/Sitiobemaster39.svg';
                }
                else if (reward.award.type === "Powerclass") {
                    urlIcon = 'https://cdn.bemaster.com/media/icons/home/Sitiobemaster37.svg';
                }

                return (
                    <div className="rewards-container mb-2" key={reward.award.idAward} >
                        <h1 className="is-size-3">{plus}</h1>
                        <div className="icons-section">
                            <div className={`is-reward-icon`} style={{ backgroundImage: `url(${urlIcon})` }}> </div>
                        </div>
                        <div className="rewards-description">
                            <h1 className="r-text is-size-4">{reward.award.name}</h1>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default Rewards;