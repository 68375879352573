import "./marketplace.styles.scss";

const Marketplace = () => {
    return (
        <div className="main-marketplace-container">
            <iframe src="https://work.bemaster.com/" width={"100%"} height={"100%"} ></iframe>
        </div>
    );
};

export default Marketplace;