import AxiosConfig from "../axios/axiosConfig";
import { AuthContext } from "../context/context";

export const updateCancellationState = async (appContext: AuthContext) => {
    const userInfo = appContext.userInfo.userInfo;
    const response = await AxiosConfig.post("/Career/UpdateStatusPlan");

    if (response.data?.result === "success" && userInfo) {
        userInfo.status_plan = "cancellation";
        appContext.dispatchUserInfo(userInfo);
    }
}