import { useEffect, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { Tool } from '../../interfaces/tool';
import ToolCard from '../tool-card/tool-card.component';
import AxiosConfig from '../../axios/axiosConfig';

import './tool-list.styles.scss';

import toast from "react-hot-toast";

const ToolList = () => {

    const [toolsData, setToolsData] = useState<Tool[]>([]);
    let history = useHistory();

    const path = matchPath<any>(useLocation().pathname, {
        path: "/lista-herramientas/:id",
        exact: true,
        strict: true
    });

    useEffect(() => {
        let urlEndpoint = `/Tool/GetToolsByCategoryId`;
        const getToolsById = async () => {
            const apiResponse = await AxiosConfig.post(urlEndpoint, {
                id: parseInt(path?.params.id)
            });

            if (!apiResponse || !apiResponse.data.data.randomTools?.length) {
                history.push('/tools');
                toast.error("No existen herramientas para la categoria seleccionada", {
                    duration: 6000,
                    style: {
                        color: "#966c2e",
                    },
                    position: "bottom-left",
                });
            }
            setToolsData(apiResponse.data.data.randomTools);
        };

        getToolsById();
    }, [])

    return (
        <div className="main-tool-list-container">
            <h1>HERRAMIENTAS</h1>
            <div className="grid">
                {toolsData?.map((tool: Tool, i: number) => {
                    return (
                        <ToolCard
                            key={i}
                            id={tool.idTool}
                            toolName={tool.name}
                            description={tool.description}
                            toolUrl={tool.toolUrl}
                            target={tool.target}
                            toolType={tool.toolType}
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default ToolList;