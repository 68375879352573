import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import ClassButton from "../../shared/components/class-button/class-button.component";
import RewardCage from "./reward-cage.component";

import "./reward-modal.styles.scss";

interface props {
  showModal: boolean;
  currentClass: any;
  updateClassAward: (classAwards: any) => void;
}

const RewardModal = ({
  showModal = false,
  currentClass,
  updateClassAward,
}: props) => {
  const [activeModal, setActiveModal] = useState(false);
  const [visible, setVisible] = useState(false);

  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idUserCareer = career.idUserCareer;
  const audio = new Audio("./assets/reward-audio.mp3");

  const handleRewardCage = () => {
    setTimeout(() => {
      audio.play();
    }, 500);

    setVisible(!visible);
    setTimeout(() => {
      setActiveModal(false);
      setVisible(false);
    }, 7250);
  };

  useEffect(() => {
    setActiveModal(showModal);
  }, [showModal]);

  const handleClick = async () => {
    let idModuleClass = currentClass.idModuleClass;
    handleRewardCage();

    await AxiosConfig.post("/Career/ClaimReward", {
      listInts: [idUserCareer, idModuleClass],
    }).then((response) => {
      if (response.data.result === "success") {
        let classAwards = response.data.data.classAwards;
        updateClassAward(classAwards);
      }
    });
  };

  return (
    <div className="reward-main-modal-container">
      <div className="internal-content">
        <Modal isOpen={activeModal} portalClassName={"reward-modal"}>
          <h1 className="congrats-text is-size-3">FELICIDADES</h1>
          <h1 className="has-text-white is-size-5 mb-5 mess">
            ¡CUMPLISTE TUS MISIONES DEL DIA!
          </h1>
          <div className="btn" style={{ display: visible ? "none" : "flex" }}>
            <ClassButton
              text={"TU RECOMPENSA"}
              height={40}
              width={190}
              onClick={() => handleClick()}
            />
          </div>
          <RewardCage visible={visible} />
        </Modal>
      </div>
    </div>
  );
};

export default RewardModal;
