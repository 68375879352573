import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";
import AxiosConfig from "../../axios/axiosConfig";
import { config } from "../../config";
import { AppContext } from "../../context/context";
import { useCurrentCareerId } from "../../hooks/useCareer";
import { StudyCase } from "../../interfaces/studyCase";
import SearchInput from "../search-input/search-input.component";
import VideoClassification from "../video-classification/video-classification.component";
import "./powerclass.styles.scss";

const PowerClass = () => {
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;

  const [classifications, setClassifications] = useState<string[]>([]);
  const [totalDuration, setTotalDuration] = useState<string[]>([]);
  const [totalClasses, setTotalClasses] = useState<string[]>([]);
  const [pclassesCat, setPclassesCat] = useState([]);
  const [data, setData] = useState<StudyCase[]>([]);
  const [pclasses, setPclasses] = useState([]);
  const [powerId, setPowerId] = useState("");
  const scrollable = useRef<any>([]);
  let idCareer = useCurrentCareerId();
  let history = useHistory();
  scrollable.current = [];

  const [favoriteContent, setFavoriteContent] = useState<any[]>([]);

  const mainPowerImg = `${
    (idCareer === 10 || idCareer === 11 || idCareer === 12)
      ? `${config.url.images.powerclasses}Logo_mnv.png`
      : `${config.url.images.powerclasses}Logo_POWERCLASS.png`
  }`;

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getData = async () => {
    const response = await AxiosConfig.post("/Content/GetCategoryContent", {
      typeContent: "Powerclass",
      idCareer,
    });
    setTotalClasses(response.data.data.totalClasses);
    setTotalDuration(response.data.data.totalDuration);
    setData(response.data.data.listContentCategory);
    let categoryList = response.data.data.listContentCategory
      .sort((a: StudyCase, b: StudyCase) => {
        return a.classificationOrder - b.classificationOrder;
      })
      .map((c: StudyCase) => c.classification)
      .filter(
        (value: any, index: number, self: any) => self.indexOf(value) === index
      );

    setClassifications(categoryList);
    setPclassesCat(response.data.data.listContentCategory);

    let favContent: any = [];
    response.data.data.listContentCategory.map((content: any, i: number) => {
      content.userContentCategory.map((userContent: any, j: number) => {
        userContent.isFavourite === 1 && favContent.push(content);
      });
    });

    favContent && setFavoriteContent(favContent);
  };

  const handlePurchase = (powerId: string) => {
    let cont = pclasses.filter((x: any) => x.idContent == powerId);
    if (cont.length > 0) {
      let item: any = cont[0];
      let bought: boolean =
        item.userContent.length > 0 &&
        item.userContent[0].acquiredBy == "Shopping";
      if (!bought && item.value > 0) {
        setPowerId(powerId);
      } else {
        history.push("/video", {
          type: item.type,
          url: item.url,
          content: item,
        });
      }
    }
  };

  return (
    <div className="main-powerclass-container">
      <div className="powerclass-banner" style={{ marginTop: `${(idCareer === 10 || idCareer === 11 || idCareer === 12) ? '3rem' : ''}` }}>
        <img src={mainPowerImg} alt="" />
      </div>
      <div className="search-section">
        <SearchInput
          iWidth={780}
          listContent={data}
          handlePurchase={handlePurchase}
        />
      </div>
      {classifications.length !== 0 ? (
        <VideoClassification
          classifications={classifications}
          totalClasses={totalClasses}
          totalDuration={totalDuration}
          pclassesCat={pclassesCat}
          favoriteContent={favoriteContent}
        />
      ) : (
        <div style={{ margin: "0 auto", width: "60px", paddingTop: "70px" }}>
          <img src={BeMasterLoader} alt="Loader" />
        </div>
      )}
    </div>
  );
};

export default PowerClass;
