import React from 'react';
import { TiLockOpen, TiLockClosed } from "react-icons/ti";

type props = {
    rState: number
}

const RewardPadlock = ({rState = 0}: props) => {
    switch (rState) {
        case 0: 
            return (
                <div>
                    <TiLockClosed /> 
                </div>
            );
        break;
    
        case 1:
            return (
                <div>    
                    <TiLockOpen />
                </div>
            );
        break;

        default:
            return (
                <div>
                    <TiLockClosed /> 
                </div>
            );
        break;
    }    
};

export default RewardPadlock;