import React from 'react';
import '../login/login.styles.scss';

export const Register = () => {
    return (
        <div className="section center">
            <h1 className="title has-text-white is-size-2">Register Screen</h1>
        </div>
    );
};
