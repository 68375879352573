import { render } from "@testing-library/react";
import { useContext } from "react";
import { RiArrowDropRightLine } from "react-icons/ri";
import Slider from "react-slick";
import { AppContext } from "../../context/context";
import Triumph from "../triumph/triumph.component";
import "./global-slider.styles.scss";



const NextArrow = () => {
  return (
    <div className="custom-next-arrow">
      <RiArrowDropRightLine />
    </div>
  );
};
const GlobalSlider = () => {
  const appContext = useContext(AppContext);
  let listUserTriumphs = [
    ...appContext.loginInfo.infoTriumphs.listUserTriumphs,
  ];
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];

  const sliderSetup = {
    autoplay: true,
    autoplaySpeed:5000,
    useCSS: true,
    className: "center",
    arrows: true,
    rows: 3,
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <div className="custom-slider">
      <div className="child-slider">
        <Slider {...sliderSetup}>
          {listUserTriumphs
            .filter(
              (item: any) =>
                item.category === career.career.name ||
                item.category === "General"
            )
            .sort((a: any, b: any) => {
              return a.earned === b.earned ? 0 : a.earned ? -1 : 1;
            })
            .map((item: any, i: number) => {
              return (
                <div key={i} className="cell">
                  <Triumph tData={item} />
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};
export default GlobalSlider;
