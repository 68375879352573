import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/context";
import AxiosConfig from "../../axios/axiosConfig";

import "./workbook.styles.scss";
import toast from "react-hot-toast";

import { CgTrash } from "react-icons/cg";
import { IoMdExit } from "react-icons/io";
import { FiDownload } from "react-icons/fi";

import axios from "axios";
import { MdModeEdit } from "react-icons/md";

import { format, isValid } from "date-fns";
import locale from "date-fns/locale/es";

import Loader from "../../assets/gifs/loader/bemaster_loader.gif";
import { Workbook } from "../../interfaces/workbook";

type props = {
  mission: any;
  indexMission: number;
  changeCheckedMission: (mission: any, index: number) => void;
  idCareerModule: number;
  handleLoader: (state: boolean) => void;
};

const Media = ({
  mission,
  indexMission,
  changeCheckedMission,
  idCareerModule,
  handleLoader,
}: props) => {
  const [editing, setEditing] = useState<Workbook | null>(null);
  const [workbooks, setWorkbooks] = useState<Workbook[]>([]);
  const [loading, setLoading] = useState(false);
  const [defaultHtml, setDefaultHtml] = useState("");

  const iFrame = useRef<HTMLIFrameElement>(null);

  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idUserCareer = career.idUserCareer;

  let [userCareerProgress] = [...mission.userCareerProgress];
  let completed =
    userCareerProgress == undefined ? false : !!userCareerProgress.completed;
  let urlWorkBookCompleted =
    completed &&
    userCareerProgress != undefined &&
    userCareerProgress.urlWorkBookCompleted
      ? userCareerProgress.urlWorkBookCompleted +
        userCareerProgress.idUserCareerProgress
      : "";
  let urlWorkBook = urlWorkBookCompleted ? urlWorkBookCompleted : mission.url;

  const deleteWorkbook = async (id: number, name: string) => {
    setLoading(true);
    try {
      await AxiosConfig.delete(`/WorkBook/DeleteWorkBook/${id}`);
      editing && setEditing(null);
      await getWorkbooks();
      toast.success(`"${name}" eliminado correctamente`, {
        style: {
          color: "#966c2e",
        },
        position: "bottom-right",
      });
    } catch (error) {
      toast.error(`Hubo un error al intentar eliminar "${name}"`, {
        duration: 6000,
        style: {
          color: "#966c2e",
        },
        position: "bottom-right",
      });
    }
    setLoading(false);
  };

  const getWorkbooks = async () => {
    try {
      const response = await AxiosConfig.post("/WorkBook/GetUserWorkbooks", {
        idUserCareer,
        idClassMission: mission.idClassMission,
      });

      const html = await axios.get(mission.url);
      setDefaultHtml(html.data);

      if (response.data.data.userWorkBooks.length === 0) {
        setEditing({
          dateEdit: "",
          html: html.data,
          name: "WORKBOOK # 1",
          idClassMission: -1,
          idUserCareer: -1,
          idUserWorkBook: -1,
        });
      }
      setWorkbooks(response.data.data.userWorkBooks);
    } catch (error) {
      console.error(error);
      toast.error("Hubo un error al obtener los workbooks", {
        duration: 6000,
        style: {
          color: "#966c2e",
        },
        position: "bottom-right",
      });
    }
  };

  const editWorkbook = async (workbook: Workbook) => {
    setEditing(workbook);
  };

  const createWorkbook = async () => {
    setEditing({
      dateEdit: "",
      html: defaultHtml,
      name: `WORKBOOK #${workbooks.length + 1}`,
      idClassMission: -1,
      idUserCareer: -1,
      idUserWorkBook: -1,
    });
  };

  const saveWorkbook = async () => {
    handleLoader(true);
    setLoading(true);
    (iFrame.current?.contentWindow as any).chequearRadioButtons();
    (iFrame.current?.contentWindow as any).chequearCheckbox();
    (iFrame.current?.contentWindow as any).rellenarValueTextArea();
    (iFrame.current?.contentWindow as any).chequearCombos();
    const html = iFrame.current?.contentDocument?.documentElement.innerHTML;
    if (editing && html) {
      if (editing.idUserWorkBook !== -1) {
        try {
          await AxiosConfig.put("/WorkBook/EditWorkBook", {
            idUserWorkBook: editing.idUserWorkBook,
            html,
            name: editing.name,
          });
          await getWorkbooks();

          toast.success(`"${editing.name}" guardado con éxito`, {
            style: {
              color: "#966c2e",
            },
            position: "bottom-right",
          });
        } catch (error) {
          console.error(error);
          toast.error(
            `No se ha podido guardar ${editing.name}, por favor intente mas tarde.`,
            {
              duration: 6000,
              style: {
                color: "#966c2e",
              },
              position: "bottom-right",
            }
          );
        }
      } else {
        try {
          const response = await AxiosConfig.post("/WorkBook/CreateWorkBook", {
            idUserCareer,
            idClassMission: mission.idClassMission,
            html,
            name: editing.name,
          });

          if (response.data.result == "success") {
            mission.userCareerProgress = [
              response.data.data.userCareerProgress,
            ];
            changeCheckedMission(mission, indexMission);

            let loginInfo = appContext.loginInfo;
            infoCareer.careerList[selectedCareer].porcProgressLevel =
              response.data.data.porcProgressLevel;

            infoCareer.careerList[selectedCareer].idCurrentModule =
              idCareerModule;
            infoCareer.careerList[selectedCareer].idCurrentClass =
              mission.idModuleClass;
            infoCareer.careerList[selectedCareer].idCurrentClassMission =
              mission.idClassMission;
            appContext.dispatch(loginInfo);
          }
        } catch (error) {
          console.error(error);
        }
        try {
          await getWorkbooks();
          setEditing(workbooks[0]);
        } catch (error) {
          console.error(error);
        }
        toast.success(`Workbook "${editing.name}" guardado con éxito`, {
          style: {
            color: "#966c2e",
          },
          position: "bottom-right",
        });
      }
    }
    setEditing(null);
    setLoading(false);
    handleLoader(false);
  };

  const getWorkbookPdf = async (workbook: Workbook) => {
    setLoading(true);
    try {
      const response = await AxiosConfig.post("/WorkBook/GetPdfWorkBook", {
        idUserCareer,
        idUserWorkBook: workbook.idUserWorkBook,
      });
      if (response.data.result === "success") {
        window.open(response.data.data.urlPdf, "_blank");
      }
      toast.success(`Workbook "${workbook.name}" descargado correctamente`, {
        style: {
          color: "#966c2e",
        },
        position: "bottom-right",
      });
    } catch (error) {
      toast.error(
        `No se ha podido descargar "${workbook.name}", por favor intente mas tarde.`,
        {
          duration: 6000,
          style: {
            color: "#966c2e",
          },
          position: "bottom-right",
        }
      );
    }
    setLoading(false);
  };

  const onChangeDocName = (event: React.ChangeEvent<HTMLInputElement>) => {
    editing &&
      setEditing({
        ...editing,
        name: event.target.value,
      });
  };

  useEffect(() => {
    getWorkbooks();
  }, []);

  return (
    <div className="workbook-container">
      <div className="title-area">
        <div className="workbook-title">
          {editing ? (
            <>
              <div className="editable-title">
                <MdModeEdit size={24} color={"white"} />
                <input
                  type="text"
                  defaultValue={editing.name}
                  onChange={(e) => onChangeDocName(e)}
                />
              </div>
              <div className="completed-at-title" style={{ color: "white" }}>
                {isValid(new Date(editing.dateEdit)) &&
                  format(
                    new Date(editing.dateEdit),
                    "'Completado el ' dd 'de' MMMM 'de' yyyy",
                    { locale }
                  )}
              </div>
            </>
          ) : (
            <div className="has-text-weight-bold has-text-white">
              MIS WORKBOOKS
            </div>
          )}
        </div>
        {editing && (
          <div className="workbook-tools">
            <div className="save" onClick={() => !loading && saveWorkbook()}>
              Guardar
            </div>
            <IoMdExit
              className="workbook-tool"
              size={24}
              onClick={() => workbooks.length > 0 && setEditing(null)}
              style={{
                color: workbooks.length === 0 || loading ? "gray" : "white",
                cursor: workbooks.length === 0 || loading ? "unset" : "pointer",
                transform: "rotate(180deg)",
              }}
            />
            <CgTrash
              className="workbook-tool"
              size={24}
              onClick={() =>
                !loading &&
                workbooks.length > 0 &&
                deleteWorkbook(editing.idUserWorkBook, editing.name)
              }
              style={{
                color: workbooks.length === 0 || loading ? "gray" : "white",
                cursor: workbooks.length === 0 || loading ? "unset" : "pointer",
              }}
            />
            <FiDownload
              className="workbook-tool"
              size={24}
              onClick={() =>
                !loading && workbooks.length > 0 && getWorkbookPdf(editing)
              }
              style={{
                color: workbooks.length === 0 || loading ? "gray" : "white",
                cursor: workbooks.length === 0 || loading ? "unset" : "pointer",
              }}
            />
          </div>
        )}
      </div>
      {loading ? (
        <div className="loader-container">
          <img src={Loader} alt="loader" />
        </div>
      ) : editing ? (
        <iframe
          id="myIframe"
          srcDoc={editing.html}
          width="100%"
          height="80%"
          frameBorder="0"
          allow="autoplay; fullscreen"
          ref={iFrame}
        ></iframe>
      ) : (
        <div className="workbooks-list">
          {loading ? (
            <div className="loader-container">
              <img src={Loader} alt="loader" />
            </div>
          ) : (
            workbooks.map((workbook: Workbook, i: number) => (
              <div className="workbook" key={i}>
                <div className="workbook-content">
                  <div className="workbook-name">{workbook.name}</div>
                  <div
                    className="workbook-mini"
                    dangerouslySetInnerHTML={{
                      __html: workbook.html.slice(
                        workbook.html.indexOf("<form"),
                        workbook.html.indexOf("</form>") + 7
                      ),
                    }}
                  ></div>
                  <div className="workbook-options">
                    <button onClick={() => editWorkbook(workbook)}>
                      Editar
                    </button>
                    <div className="workbook-tools">
                      <CgTrash
                        className="workbook-tool"
                        size={24}
                        onClick={() =>
                          deleteWorkbook(workbook.idUserWorkBook, workbook.name)
                        }
                      />
                      <FiDownload
                        className="workbook-tool"
                        size={24}
                        onClick={() => getWorkbookPdf(workbook)}
                      />
                    </div>
                    <div className="completed-at">
                      {isValid(new Date(workbook.dateEdit)) &&
                        format(
                          new Date(workbook.dateEdit),
                          "'Completado el ' dd 'de' MMMM 'de' yyyy",
                          { locale }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
          {!loading && workbooks.length !== 0 && (
            <div className="workbook">
              <div className="workbook-create" onClick={() => createWorkbook()}>
                +
              </div>
            </div>
          )}
        </div>
      )}

      {/* <Alert /> */}
    </div>
  );
};

export default Media;
