export const NavData = [
    {
        id: 1,
        name: 'home',
        icon: 'home-gold.svg',
        url: '/home'
    },
    {
        id: 2,
        name: 'community',
        icon: 'community-gold.svg',
        url: '/community'
    },
    {
        id: 3,
        name: 'content',
        icon: 'content-gold.svg',
        url: '/powerclass'
    },
    {
        id: 4,
        name: 'marketplace',
        icon: 'marketplace-gold.svg',
        url: '/marketplace'
    },
]