import { useContext, useEffect, useState } from "react";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import { BrandLogo } from "../../shared/components/brand/brand.component";
import CardList from "../card-list/card-list.component";

import "../../shared/styles/essentials.css";
import "./career.styles.scss";
import { useCurrentCareerId } from "../../hooks/useCareer";
import { updateCareers } from "../../services/career.service";

const Career = () => {
  const appContext = useContext(AppContext);
  const [cardState, setCardState] = useState([]);
  const typeFormUrls = appContext.loginInfo.initialForm;

  useEffect(() => {
    // let isSubscribed = true;
    
    (async () => {
      let loginInfo = appContext.loginInfo;
      const response = await updateCareers(appContext);

      // const response = await AxiosConfig.get("/Account/GetInfoCareer");

      //has logged user careers 10, 11 or 12?
      const canShowAds =
        response.data.data.listUserCareer.find(
          (item: any) =>
            item.career.idCareer === 10 || item.career.idCareer === 11 || item.career.idCareer === 12
        ) !== undefined && response.data.data.listUserCareer.length > 2;

      const showCCM =
        response.data.data.listUserCareer.find(
          (item: any) => item.career.idCareer === 6
        ) === undefined;

      const showAM3 =
        response.data.data.listUserCareer.find(
          (item: any) => item.career.idCareer === 8
        ) === undefined;

      const hasTrafficMaster =
        response.data.data.listUserCareer.find(
          (item: any) => item.career.idCareer === 2
        ) === undefined;

      if (showCCM && canShowAds) {
        loginInfo.infoCareers.careerList.push({
          career: {
            idCareer: 6,
            url: "https://bemaster.com/creador-contenido-master/?utm_source=app&utm_medium=bemaster_app&utm_campaign=mis_carreras",
          },
        });
      }

      if (showAM3 && canShowAds) {
        loginInfo.infoCareers.careerList.push({
          career: {
            idCareer: 8,
            url: "https://bemaster.com/afiliado-master/?utm_source=app&utm_medium=bemaster_app&utm_campaign=mis_carreras",
          },
        });
      }

      if (hasTrafficMaster && canShowAds) {
        loginInfo.infoCareers.careerList.push({
          career: {
            idCareer: 2,
            url: "https://bemaster.com/traffic-master-apertura/?utm_source=app&utm_medium=bemaster_app&utm_campaign=mis_carreras",
          },
        });
      }

      // loginInfo.infoCareers.careerList.push(
      //   ...response.data.data.listUserCareer
      // );
      // loginInfo.infoContent.listUserContent.push(
      //   ...response.data.data.listUserContent
      // );

      // appContext.dispatch(loginInfo);
      setCardState(loginInfo.infoCareers.careerList);
    })();

    // const fetchCareers = async () => {

    // };

    // loginInfo.infoCareers.careerList.length !== 0
    //   ? setCardState(loginInfo.infoCareers.careerList)
    //   : fetchCareers();

    // return () => {
    //   isSubscribed = false;
    // };
  }, []);

  return (
    <div>
      <div className="is-back-layer hero is-fullheight has-background-image">
        {" "}
      </div>
      <div className="is-front-layer hero is-fullheight">
        <div className="is-brand-section is-hidden-mobile">
          <BrandLogo />
        </div>
        <div className="is-main-container">
          <div className="section is-cards-section has-cards-inside">
            <div className="is-title-section mb-6">
              <h1
                className="title has-text-white is-size-4"
                style={{ letterSpacing: 5 }}
              >
                MIS CARRERAS
              </h1>
            </div>
            <CardList careerList={cardState} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
