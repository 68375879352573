import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/context";
import { useCurrentCareerId } from "../../hooks/useCareer";
import CrispChatBox from "../crisp-chat-box/crispChatBox";
import FacebookButton from "../facebook-button/facebook-button";
import "./support.styles.scss";

const Support = () => {
  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;

  const history = useHistory();
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  const idCareer = useCurrentCareerId() === 10;

  return (
    <div className="iframe">
      <iframe src={`${career.career.urlFAQ}`} height="100%" width="100%" />
      <CrispChatBox />
      {!idCareer ? (
        <FacebookButton urlCommunity={career.career.communityUrl} />
      ) : null}
    </div>
  );
};

export default Support;
