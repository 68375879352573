import { useContext, useEffect, useState } from "react";
import { BsFillHeartFill, BsFillPersonFill } from "react-icons/bs";
import ReactStars from "react-stars";
import VideoList from "../video-list/video-list.component";
import AxiosConfig from "../../axios/axiosConfig";
import { matchPath, useHistory, useLocation } from "react-router";
import PowerPurchase from "../power-purchase/power-purchase.component";
import { Video } from "../../interfaces/video";
import { GlobalStyles } from "../../shared/global-styles/global-styles";
import { config } from "../../config";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";
import ButtonPersonalized from "../../shared/components/buttons/button-personalized/button-personalized.component";
import { AppContext } from "../../context/context";
import Scrollbar from "react-scrollbars-custom";
import toast from "react-hot-toast";
import ButtonBack from "../button-back/button-back.component";
import { useMobileScreen } from "../../hooks/useMobileScreen";

import _ from "lodash";

import "./powerclass-master-list.styles.scss";
import { formatInt, intApproach } from "../../shared/helpers";
import { AiOutlineArrowLeft } from "react-icons/ai";

type LocationState = {
  from?: string;
  fav?: boolean;
};

const PowerclassMasterList = (props: any) => {
  const history = useHistory<LocationState>();

  const [videos, setVideos] = useState<Video[]>([]);
  const [balance, setBalance] = useState(0);
  const [ratingAmount, setRatingAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [boughtContent, setBoughtContent] = useState<boolean>(false);

  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idCareer = career.career.idCareer;

  const path = matchPath<any>(useLocation().pathname, {
    path: "/powerclass/:id",
    exact: true,
    strict: true,
  });

  const prevRoute = history.location.state
    ? history.location.state.from
    : "powerclass";
  const [favorite, setFavorite] = useState<Boolean>(
    history.location.state?.fav || false
  );

  useEffect(() => {
    if (videos.length === 0) {
      getData();
    }
  }, []);

  const getData = async () => {
    const getContentBody =
      prevRoute === "powerclass"
        ? {
            filterBy: "Category",
            idContentCategory: path?.params.id,
          }
        : {
            filterBy: "Class",
            idModuleClass: path?.params.id,
          };
    let response: any;
    try {
      response = await AxiosConfig.post("/Content/GetContent/", getContentBody);
      setIsPayment(response.data.data.valueCategory);
      setVideos(response.data.data.listContent);
      setBoughtContent(response.data.data.boughtContent);
      setShowModal(
        !response.data.data.boughtContent && response.data.data.valueCategory
      );
      setBalance(response.data.data.balance);

      const rating = _.sumBy(
        response.data.data.listContent.map(
          (content: any) => content.ratingContent
        ),
        "ratingAmount"
      );
      rating ? setRatingAmount(rating) : setRatingAmount(0);
    } catch (error) {
      console.error(error);
    }
  };

  const getRatingAverage = (videos: any[]) => {
    let average = 0;
    let length = 0;
    videos.forEach((video) => {
      average += video.ratingContent !== null ? video.ratingContent.rating : 0;
      length += video.ratingContent !== null ? 1 : 0;
    });
    return length === 0 ? "0" : (average / length).toFixed(1);
  };

  const handleCloseButton = (active: boolean) => {
    setShowModal(active);
  };

  const updateContentBought = (bought: boolean) => {
    setBoughtContent(bought);
  };

  const handleFav = async () => {
    setFavorite(!favorite);
    let favState = favorite;

    !favorite &&
      toast.success("¡Marcado como favorito!", {
        style: {
          color: "#966c2e",
        },
        position: "bottom-left",
      });

    await AxiosConfig.post("/Content/SetFavoriteContentCategory", {
      idContentCategory: path?.params.id,
      boolParam: !favState,
    });
  };

  const urlImg =
    config.url.images.powerclasses + "pbanner_" + path?.params.id + ".png";
  const urlSmImg =
    config.url.images.powerclasses + "pbanner_sm" + path?.params.id + ".png";
  const mobileScreen = useMobileScreen();

  return (
    <div
      className={`video-content ${
        prevRoute === "powerclass" ? "from-powerclass" : ""
      }`}
    >
      {videos && videos.length !== 0 ? (
        <>
          {prevRoute === "powerclass" ? (
            <div
              className="banner"
              style={{
                backgroundImage: `url(${mobileScreen ? urlSmImg : urlImg})`,
              }}
            >
              <div className="darkened-layout"> </div>

              <div className="back-button">
                <ButtonBack collapse={props.collapse} />
              </div>

              <PowerPurchase
                powerId={videos[0].idContent}
                showModal={showModal}
                onClick={handleCloseButton}
                listContent={videos}
                balance={balance}
                updateContentBought={updateContentBought}
                idCareer={idCareer}
                idContentCategory={path?.params.id}
                video={true}
              />

              {isPayment && !boughtContent ? (
                <ButtonPersonalized
                  onClick={() => setShowModal(true)}
                  label={"COMPRAR"}
                  hasShadow={true}
                  width={145}
                />
              ) : (
                <>
                  <BsFillHeartFill
                    onClick={handleFav}
                    style={{
                      color: favorite
                        ? GlobalStyles.colors.red
                        : GlobalStyles.colors.grayLighter,
                    }}
                    className="heart"
                    color="black"
                  />
                </>
              )}
              <div className="stars-rating">
                <ReactStars
                  count={5}
                  value={parseInt(getRatingAverage(videos))}
                  size={mobileScreen ? 25 : 32}
                  half={true}
                  edit={false}
                  color1={"white"}
                  color2={"#c19656"}
                  className="rating-stars"
                />
                <div className="rating-text">
                  <h2>
                    {getRatingAverage(videos)}/5 | {formatInt(ratingAmount)}
                  </h2>
                  <BsFillPersonFill size={20} />
                </div>
              </div>
            </div>
          ) : (
            <div className="banner-classroom" />
          )}
          <Scrollbar
            style={{ height: "50%" }}
            thumbYProps={{ className: "thumbY" }}
            trackYProps={{ className: "trackY" }}
          >
            <VideoList
              videos={videos}
              payment={isPayment}
              boughtContent={boughtContent}
              prevComp={prevRoute}
            />
          </Scrollbar>
        </>
      ) : (
        <div className="has-loader">
          <img src={BeMasterLoader} alt="BeMasterLoader" />
        </div>
      )}
    </div>
  );
};

export default PowerclassMasterList;
