import React from 'react';

import './triumph.styles.scss';

type props = {
    tData: any
}

const Triumph = ({ tData }: props) => {

    let folder = tData.earned ? "active" : "inactive";
    let urlImg = 'https://cdn.bemaster.com/media/icons/triumph/' + folder + '/triumph-' + tData.idAward + '.svg';
    
    return (
        <div>
            <div className="has-top-section">
                <li className="is-flex">
                    <div className="triumph-class" style={{ backgroundImage: `url(${urlImg})` }}></div>
                </li>
            </div>
        </div>
    );
};

export default Triumph;