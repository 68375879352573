export interface User {
    about?: string | null;
    accept_policies: string;
    address?: null | string;
    alias?: null | string;
    birthday?: string;
    cantFires: number;
    city?: null | string;
    commission: number;
    country?: null | string;
    current_plan?: null | string;
    date_next_charge?: null | string;
    email: string;
    facebook?: null | string;
    firstName: string;
    gender?: null | string;
    idUser: number;
    idUserAff?: null | string;
    instagram?: null | string;
    ip: string;
    lastEdit?: null | string;
    lastLogin: string;
    lastName: string;
    loginStreak: number;
    notification: any[];
    password: string;
    phone?: null | string;
    photo?: null | string;
    showOnboarding: number;
    state?: null | string;
    status: number;
    status_plan?: null | string;
    subscriber_code?: null | string;
    transaction: any[];
    userAward: any[];
    userCareer: any[];
    userContent: any[] // OJO XD
    userContentCategory: any[];
    userPreference: any[];
    userProfile: any[];
    userWorkBook: any[];
    youtube?: null | string;
}

export const userInitialState: User = {
    accept_policies: "",
    cantFires: 0,
    commission: 0,
    email: "",
    firstName: "",
    idUser: 0,
    ip: "",
    lastLogin: "",
    lastName: "",
    loginStreak: 0,
    notification: [],
    password: "",
    birthday: "",
    showOnboarding: 0,
    status: 0,
    transaction: [],
    userAward: [],
    userCareer: [],
    userContent: [], 
    userContentCategory: [],
    userPreference: [],
    userProfile: [],
    userWorkBook: []
}