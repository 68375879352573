import { useContext, useEffect, useState } from "react";
import { FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import { AdContent } from "../../interfaces/adContent";
import CommunityCard from "../community-card/community-card.component";

import "./community.styles.scss";

const Community = () => {
  const mobileScreen = useMobileScreen();

  const [events, setEvents] = useState([]);
  const [classified, setClassified] = useState<any>({});
  const [urlClassified, setUrlClassified] = useState("");

  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];

  let idCareer = career.career.idCareer;
  let accessType = career.accessType;
  let eMMCareerId = 3;

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      await AxiosConfig.get("/Post/GetCommunityContent/" + idCareer).then(
        (result) => {
          if (isSubscribed) {
            if (result.data.result == "success") {
              setEvents(result.data.data.listCareerEvents);
              setClassified(result.data.data.classified);

              if (result.data.data.classified != null) {
                let url =
                  "https://cdn.bemaster.com/media/images/community/classified-" +
                  result.data.data.classified.idAd +
                  ".png";
                setUrlClassified(url);
              }
            }
          }
        }
      );
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  const getTime = (date: Date) => {
    let d = new Date(date);
    return d != null ? d.getTime() : 0;
  };

  const handleGroupClick = () => {
    window.open(career.career.communityUrl, "_blank");
  };

  const handleClassifiedClick = () => {
    if (
      classified.url !== null &&
      classified.url !== "#" &&
      classified.url !== ""
    ) {
      window.open(classified.url, classified.urlTarget);
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
  };

  //TODO: Review this extreme longer code!
  return (
    <>
      <div
        className="community-container mt-3"
        style={{ display: mobileScreen ? "none" : "flex" }}
        id="grid"
      >
        <div className="columns">
          <div className="com-content-section column is-8" id="c">
            <div className="events-section ml-4">
              <div className="is-events-title">
                <h2 className="has-text-white has-text-weight-bold is-size-4">
                  {`${idCareer === eMMCareerId ? "AGENDA" : "EVENTOS /"}`}
                </h2>
                <h2
                  className="has-text-white ml-2 b-title"
                  style={{
                    display: idCareer === eMMCareerId ? "none" : "block",
                  }}
                >
                  BE
                </h2>
                <h2
                  className="has-text-white has-text-weight-bold b-title"
                  style={{
                    display: idCareer === eMMCareerId ? "none" : "block",
                  }}
                >
                  MASTER
                </h2>
              </div>
              <Scrollbar
                noScrollX={true}
                style={{
                  height: 450,
                  width: "100%",
                  zIndex: tourName === "COMUNIDAD" ? 105 : "unset",
                }}
              >
                <div className="has-card-list-section mt-3">
                  {events
                    .sort((a: any, b: any) => getTime(a.date) - getTime(b.date))
                    .sort((a: AdContent, b: AdContent) => a.order - b.order)
                    .map((event, i) => {
                      return <CommunityCard eventData={event} key={i} />;
                    })}
                </div>
              </Scrollbar>
            </div>
          </div>
          <div className="classified-section column is-4" id="d">
            <div
              className="button-area"
              onClick={() => handleGroupClick()}
              style={{
                display:
                  idCareer === eMMCareerId && accessType === "virtual"
                    ? "none"
                    : "block",
              }}
            >
              <button className={`${idCareer === 9 ? 'custom-button-telegram' : 'button'}`}>
                <div className={`${idCareer === 9 ? 'telegram-icon' : 'face-icon'} mr-2`}>
                  {idCareer === 9 ? <FaTelegramPlane /> : <FaFacebookF />}
                </div>
                <h1 className="has-text-weight-bold is-size-6">
                  GRUPO PRIVADO
                </h1>
              </button>
            </div>
            <div className="classified mt-5">
              <h1 className="has-text-weight-bold is-size-5">CLASIFICADOS</h1>
            </div>
            <div
              className="classified-card mt-2 p-3"
              style={{
                display: classified != null ? "" : "none",
                backgroundImage: urlClassified ? `url(${urlClassified})` : "",
                zIndex: tourIndex === 8 ? 105 : "unset",
              }}
              onClick={() => handleClassifiedClick()}
            ></div>
            {/* <Slider className="slider" {...sliderSettings}>
                            <div>
                                <div className="classified-card mt-2 p-3"
                                    style={{
                                        display: classified != null ? '' : 'none',
                                        backgroundImage: urlClassified ? `url(${urlClassified})` : '',
                                        zIndex: tourIndex === 8 ? 105 : "unset"
                                    }}
                                    onClick={() => handleClassifiedClick()}>
                                </div>
                            </div>
                            <div>
                                <div className="classified-card mt-2 p-3"
                                    style={{
                                        display: classified != null ? '' : 'none',
                                        backgroundImage: `url(${urlClassified})`,
                                        zIndex: tourIndex === 8 ? 105 : "unset"
                                    }}
                                    onClick={() => handleClassifiedClick()}>
                                </div>
                            </div>
                        </Slider> */}
          </div>
        </div>
      </div>

      <Scrollbar
        className={"community-mobile-container"}
        style={{ display: mobileScreen ? "flex" : "none", height: "100vh" }}
      >
        <div className="community-container mobile-content mt-6">
          <div className="columns">
            <div className="com-content-section column is-8">
              <div className="events-section ml-4">
                <div className="is-events-title">
                  <h2 className="has-text-white has-text-weight-bold is-size-3">
                    EVENTOS /{" "}
                  </h2>
                  <h2 className="has-text-white ml-2">BE </h2>
                  <h2 className="has-text-white has-text-weight-bold ml-1">
                    {" "}
                    MASTER
                  </h2>
                </div>
                <Scrollbar
                  noScrollX={true}
                  style={{ height: 450, width: "100%" }}
                >
                  <div className="has-card-list-section mt-3">
                    {events
                      .sort(
                        (a: any, b: any) => getTime(a.date) - getTime(b.date)
                      )
                      .map((event, i) => {
                        return <CommunityCard eventData={event} key={i} />;
                      })}
                  </div>
                </Scrollbar>
              </div>
            </div>
            <div className="classified-section column is-4">
              <div
                className="button-area"
                onClick={() => handleGroupClick()}
                style={{
                  display:
                    idCareer === eMMCareerId && accessType === "virtual"
                      ? "none"
                      : "block",
                }}
              >
                <button className="button">
                  <div className="face-icon mr-2">
                    <FaFacebookF />
                  </div>
                  <h1 className="has-text-weight-bold is-size-6">
                    GRUPO PRIVADO
                  </h1>
                </button>
              </div>
              <div className="classified mt-5">
                <h1 className="has-text-weight-bold is-size-5">Clasificados</h1>
              </div>
              <div
                className="classified-card mt-2 p-3"
                style={{
                  display: classified != null ? "" : "none",
                  backgroundImage: `url(${urlClassified})`,
                  height: "auto",
                }}
                onClick={() => handleClassifiedClick()}
              ></div>
              {/* <Slider className="slider" {...sliderSettings}>
                                <div className="classified-card mt-2 p-3"
                                    style={{ display: classified != null ? '' : 'none', backgroundImage: `url(${urlClassified})`, height: "400px" }}
                                    onClick={() => handleClassifiedClick()}>
                                </div>
                                <div className="classified-card mt-2 p-3"
                                    style={{ display: classified != null ? '' : 'none', backgroundImage: `url(${urlClassified})`, height: "400px" }}
                                    onClick={() => handleClassifiedClick()}>
                                </div>
                            </Slider> */}
            </div>
          </div>
        </div>
      </Scrollbar>
    </>
  );
};

export default Community;
