import { useContext, useEffect } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Redirect } from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import VideoComponent from "../main-video/video.component";
import News from "../news/news.component";
import TriumphSlider from "../triumph-slider/triumphSlider.component";
import "./home.styles.scss";
import { useMobileScreen } from "../../hooks/useMobileScreen";

const Home = (props: any) => {
  const appContext = useContext(AppContext);
  const tourIndex = appContext.tourInfo.index;
  const tourName = appContext.tourInfo.content[tourIndex].title;
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  const careerId = infoCareer.careerList[infoCareer.selectedCareer].idCareer;
  const mobileScreen = useMobileScreen();
  const eMMCareerId = 3;

  useEffect(() => {
    (async () => {
      if (appContext.loginInfo.showOnboarding) {
        try {
          await AxiosConfig.put("/Account/CompleteOnboarding").then(
            (result) => {
              if (result.data.result === "success") {
                let loginInfo = appContext.loginInfo;
                loginInfo.showOnboarding = false;
                appContext.dispatch(loginInfo);
              }
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);

  if (career === undefined) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Scrollbar
        noScrollX
        scrollTop={
          tourName === "NOTICIAS" || tourName === "TRIUNFOS" ? 500 : undefined
        }
      >
        <div className="home-container" id="grid">
          <VideoComponent
            careerName={career.career.name}
            careerDetails={career.career.description}
          />
          <div
            className="columns is-content-section home-bottom-content mt-4 has-text-weight-bold"
            id="grid"
            style={{justifyContent: mobileScreen ? 'unset' : 'start'}}
          >
            <div className="column is-6" id="a">
              <div
                className={`${(careerId === 10 || careerId === 11 || careerId === 12) ? 'news-parent-container' : '' }`}
                style={{
                  zIndex: tourName === "NOTICIAS" ? 105 : "unset",
                  position: tourName === "NOTICIAS" ? "relative" : "unset",
                }}
              >
                <News />
              </div>
            </div>

            {(careerId !== 10 && careerId !== 11 && careerId !== 12) ? (
              <TriumphSlider
                tourName={tourName}
                careerId={careerId}
                eMMCareerId={eMMCareerId}
              />
            ) : null}
          </div>
        </div>
      </Scrollbar>
    );
  }
};

export default Home;
