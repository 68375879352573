import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { BsPaperclip } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import ButtonPersonalized from "../../shared/components/buttons/button-personalized/button-personalized.component";
import RequestSentModal from "../request-sent-modal/request-sent-modal.component";

import "./video-modal.styles.scss";

interface props {
  showModal: boolean;
}

const VideoModal = ({ showModal }: props) => {
  const [activeModal, setActiveModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [validFiles, setValidFiles] = useState<boolean>(true);
  const [attachments, setAttachments] = useState<any>([]);
  const UploadFileRef = useRef<HTMLInputElement>(null);
  const appContext = useContext(AppContext);
  const infoCareer = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  const idUserCareer = career.idUserCareer;

  useEffect(() => {
    setActiveModal(showModal);
  }, [showModal]);

  const handleModalBehavior = () => {
    setActiveModal(!activeModal);
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != undefined && event.target.files?.length > 0) {
      setValidFiles(true);
      let files = event.target.files;
      var items = Array.from(files).slice(0, 3);
      setAttachments(items);

      Array.from(items).map((file: any, i: number) => {
        if (file.size / 1024 > 2000) {
          setValidFiles(false);
        }
      });
    }
  };

  const diplomaRequest = async () => {
    if (attachments.length > 0) {
      setValidFiles(true);
      // Create an object of formData
      const formData = new FormData();
      formData.append("idUserCareer", idUserCareer);

      Array.from(attachments).forEach((file: any) => {
        formData.append("formFile", file);
      });
      await AxiosConfig.post("/Career/RequestCert", formData).then(
        (response) => {
          if (response.data.result === "success") {
            let loginInfo: any = appContext.loginInfo;
            const selectedCareer = loginInfo.infoCareers.selectedCareer;
            loginInfo.infoCareers.careerList[selectedCareer].statusCert =
              "pendiente";
            appContext.dispatch(loginInfo);
          }
          setActiveModal(!activeModal);
          setRequestModal(!requestModal);
        }
      );
    } else {
      setValidFiles(false);
    }
  };

  return (
    <div className="video-modal">
      <RequestSentModal showModal={requestModal} />
      <Modal isOpen={activeModal} portalClassName={"video-modal"}>
        <div className="close-button" onClick={handleModalBehavior}>
          <MdClose onClick={handleModalBehavior} />
        </div>
        <div className="modal-title">
          <h1 className="video-modal-text is-size-3 my-2">
            {career.career.evidencePopupTitle}
          </h1>
        </div>
        <h1 className="content-text is-size-6 mb-1">
          {career.career.evidencePopupDescription}
        </h1>
        <h1 className="has-text-weight-bold is-size-5 my-2">
          Archivos adjuntos:{" "}
          {attachments !== undefined && attachments?.length > 0
            ? attachments?.length
            : ""}
        </h1>
        {Array.from(attachments).map((file: any, i: number) => {
          return (
            <span
              key={i}
              style={{ color: file.size / 1024 > 2000 ? "#b73f39" : "" }}
            >
              {file.name + " "}
            </span>
          );
        })}
        <div
          className="attachment-area my-3"
          onClick={() => UploadFileRef.current?.click()}
        >
          <input
            accept="image/png, image/jpeg"
            ref={UploadFileRef}
            type="file"
            style={{ display: "none" }}
            onChange={onFileChange}
            multiple={false}
          />
          <BsPaperclip style={{ fontSize: 25 }} />
          <h1 className="is-size-6">Adjuntar archivos</h1>
        </div>
        <div
          className="alert-area mt-1"
          style={{ display: !validFiles ? "flex" : "none" }}
        >
          <div className="alert-icon">
            <AiOutlineWarning />
          </div>
          {attachments.length > 0 && (
            <h1 className="ml-1 is-size-7">
              Hay archivos que pensan más de 2 MB
            </h1>
          )}
          {attachments.length <= 0 && (
            <h1 className="ml-1 is-size-7">Debes adjuntar una imagen</h1>
          )}
        </div>
        <ButtonPersonalized
          label={"ENVIAR"}
          width={100}
          onClick={diplomaRequest}
        />
      </Modal>
    </div>
  );
};

export default VideoModal;
