import React from "react";
import { User } from "../../interfaces/user";
import BasicInfo from "../basic-info/basic-info.component";
import Fires from "../fires/fires.component";
import Preferences from "../preferences/preferences.component";
import Subscription from "../profile/subscription/subscription.component";
import ProgressResume from "../progress-resume/progress-resume.component";
import PublicProfile from "../public-profile/public-profile.component";
import TriumphResume from "../triumph-resume/triumph-resume.component";

type props = {
  childComponent: number;
  infoUser: User;
  updateInfoUserState: (user: any) => void;
  preferences: any;
  updatePreferenceState: (preference: any) => void;
};

const ProfileRouter = ({
  childComponent,
  infoUser,
  updateInfoUserState,
  preferences,
  updatePreferenceState,
}: props) => {
  switch (childComponent) {
    case 0:
      return (
        <div className="component-section mr-5">
          <BasicInfo
            infoUser={infoUser}
            updateInfoUserState={updateInfoUserState}
          />
        </div>
      );
    case 1:
      return (
        <div className="component-section is-public-profile-mobile mr-5">
          <PublicProfile
            infoUser={infoUser}
            updateInfoUserState={updateInfoUserState}
          />
        </div>
      );
    case 2:
      return (
        <div className="component-section mr-5">
          <Preferences
            updatePreferenceState={updatePreferenceState}
            preferences={preferences}
          />
        </div>
      );
    case 3:
      return (
        <div className="component-section mr-5">
          <TriumphResume />
        </div>
      );
    case 4:
      return (
        <div className="component-section">
          <Fires />
        </div>
      );
    case 5:
      return (
        <div className="component-section">
          <ProgressResume />
        </div>
      );
    case 6:
      return (
        <div className="component-section">
          <Subscription />
        </div>
      );
      
    default:
      return (
        <div className="component-section mr-5">
          {/* <UploadImage /> */}
          <BasicInfo
            infoUser={infoUser}
            updateInfoUserState={updateInfoUserState}
          />
        </div>
      );
  }
};

export default ProfileRouter;
