import { useHistory } from 'react-router-dom';
import { config } from '../../config';
import ButtonPersonalized from '../../shared/components/buttons/button-personalized/button-personalized.component';

import './tool-card.styles.scss';

interface props {
    id: number,
    toolName: string,
    description: string,
    toolUrl: string,
    target: string,
    toolType: string
}

const ToolCard = ({ id, toolName, description, toolUrl, target, toolType }: props) => {
 
    let history = useHistory();

    const HandleToolClick = () => {
        if(toolType === 'calculator') {
            history.push('/calculator/' + toolUrl);
        }
        else {
            window.open(toolUrl, target);
        }
    }
 
    return (
        <div className="main-card-tool">
            <div className="top-card-section"
                style={{ backgroundImage: `url(${config.url.images.tools}ToolCard${id}.PNG)` }}>
                <h1>{toolName}</h1>
            </div>
            <div className="bottom-card-section">
                <p> {description} </p>
                <ButtonPersonalized label={'UTILIZAR'} width={200} onClick={() => HandleToolClick()} />
            </div>
        </div>
    );
};

export default ToolCard;