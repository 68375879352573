import React, { useContext, useEffect, useState } from "react";
import ReactStars from "react-stars";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import ClassVideo from "../class-video/class-video.component";

import "./video-container-styles.scss";

let ratingTitle = "Valoraciones";

type props = {
  mission: any;
  indexMission: number;
  changeCheckedMission: (mission: any, index: number) => void;
  idCareerModule: number;
};

const VideoContainer = ({
  mission,
  indexMission,
  changeCheckedMission,
  idCareerModule,
}: props) => {
  const appContext = useContext(AppContext);
  const infoCareer: any = appContext.loginInfo.infoCareers;
  const selectedCareer = infoCareer.selectedCareer;
  const career = infoCareer.careerList[selectedCareer];
  let idUserCareer = career.idUserCareer;

  const [reviewClass, setReviewClass] = useState(0);
  const [ratingAmount, setRatingAmount] = useState(1);
  const [rating, setRating] = useState(5);

  useEffect(() => {
    setReviewClass(
      mission.userCareerProgress.length > 0
        ? mission.userCareerProgress[0].reviewClass
        : 0
    );
    setRatingAmount(
      mission.ratingClassMission == null
        ? 0
        : mission.ratingClassMission.ratingAmount
    );
    setRating(
      mission.ratingClassMission == null ? 0 : mission.ratingClassMission.rating
    );
  }, [mission]);

  const ratingChanged = async (newValue: any) => {
    await AxiosConfig.post("/Career/SetReviewClassMission", {
      idUserCareer,
      idClassMission: mission.idClassMission,
      reviewClass: newValue,
    }).then((response) => {
      if (response.data.result === "success") {
        mission.userCareerProgress = [response.data.data.userCareerProgress];
        let ra = ratingAmount;
        setReviewClass(newValue);
        setRatingAmount(ra++);
      }
    });
  };

  return (
    <div>
      <div className="video">
        <ClassVideo
          mission={mission}
          indexMission={indexMission}
          changeCheckedMission={changeCheckedMission}
          idCareerModule={idCareerModule}
        />
      </div>
      <div className="class-info">
        <div className="titles-section columns pb-1 pl-4 has-text-weight-bold">
          <div className="info-title column is-9">
            {mission.title.toUpperCase()}
          </div>
          <div className="rating-title column is-3 is-hidden-mobile">
            {ratingTitle.toUpperCase()}
          </div>
        </div>
        <div className="descriptions-section columns pb-1 pl-4">
          <div className="class-description column is-8 has-custom-size">
            {mission.description}
          </div>
          <div className="rating-section column is-4">
            <h1>CALIFICA ESTA CLASE</h1>
            <div className="stars-section">
              <ReactStars
                count={5}
                value={reviewClass}
                onChange={(newValue) => {
                  ratingChanged(newValue);
                }}
                size={40}
                half={false}
                color2={"#c19656"}
              />
            </div>
            <div className="rating-title-section is-flex ml-1">
              <h1 className="class-rating-prom mr-2 is-size-5 has-text-weight-bold">
                {rating.toFixed(2)} de 5
              </h1>
              <h1>{`${ratingAmount} Calificaciones`}</h1>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="comments-area">
                <CommentsSection />
            </div> */}
    </div>
  );
};

export default VideoContainer;
