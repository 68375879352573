import { useContext, useState } from "react";
import { config } from "../../../config";
import { AppContext } from "../../../context/context";
import { updateCancellationState } from "../../../services/subscription.service";
import ButtonPersonalized from "../../../shared/components/buttons/button-personalized/button-personalized.component";
import Typeform from "../../typeform/typeform.component";
import "./subscription.styles.scss";
import { successToast } from "../../../shared/helpers";

const Subscription = () => {
  const [beginCancelation, setBeginCancelation] = useState<boolean>(false);
  const primeLogo = `${config.url.images.shared}logo-bemaster-negro.svg`;

  const appContext = useContext(AppContext);
  const userInfo = appContext.userInfo;
  const STATUS_PLAN = userInfo.userInfo?.status_plan;
  const CURRENT_PLAN = userInfo.userInfo?.current_plan;
  let desc_plan = CURRENT_PLAN || "";

  const onCancellationSubmit = (showCancellation: boolean) => {
    setBeginCancelation(showCancellation);
    updateCancellationState(appContext);
    
    successToast("Tu solicitud de cancelación se ha realizado con éxito!", {
      position: "bottom-center",
      duration: 5000,
    });
  }

  return (
    <div className="main-subscription-container">
      <div className="left-side-container">
        <div className="icon-area">
          <img src={primeLogo} alt="prime" />
        </div>
        <div className="price-area">
          <span>{desc_plan}</span>
        </div>
      </div>
      <div className="right-side-container">
        <ButtonPersonalized
          onClick={() => setBeginCancelation(true)}
          borderRadius="2rem"
          label={STATUS_PLAN === "active" ? "Cancelar suscripción" : STATUS_PLAN === "cancellation" ? "Cancelación en proceso" : "Suscripción cancelada"}
          padding={"0 1rem"}
          width={"100%"}
          disabled={STATUS_PLAN !== "active"}
        />
      </div>

      {beginCancelation ? (
        <div className="cancelation-form">
          <Typeform idTypeForm={"Rh1sJjLJ"} origin="/profile" onFormSubmit={onCancellationSubmit} />
        </div>
      ) : null}
    </div>
  );
};

export default Subscription;
