import React from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../../config';
import { useMobileScreen } from '../../hooks/useMobileScreen';
import { StudyCase } from '../../interfaces/studyCase';
import { GlobalStyles } from '../../shared/global-styles/global-styles';


import './card-search-input.styles.scss';

interface props {
    category: StudyCase,
    width?: string
}

const CardSearchInput = ({category, width}: props) => {
 
    const history = useHistory();
    const mobileScreen = useMobileScreen();

    const Redirect = () => {
        history.push(`${history.location.pathname}/${category.idContentCategory}?from=powerclass&fav=${category}`);
    }
    
    let size = mobileScreen ? 'pcard-sm' : 'pcard';
    const urlImgCard = (`${config.url.images.powerclasses}${size}${category.idContentCategory}.png`);
 
    return (
        <div className="main-card-container" onClick={Redirect}
            style={{ backgroundImage: `url(${urlImgCard})` }}>
             <h1> {category.master.toUpperCase()} </h1>
        </div>
    );
};

export default CardSearchInput;