import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import AxiosConfig from "../../axios/axiosConfig";
import ButtonPersonalized from "../../shared/components/buttons/button-personalized/button-personalized.component";
import ButtonLogoDefault from "../../shared/components/buttons/button-default/button-logo-default.component";
import { useHistory } from "react-router";
import { IoWarningOutline } from "react-icons/io5";

import "./warning-modal.styles.scss";

type props = {
  showModal: boolean;
  action: Function;
};

export const WarningModal = ({ showModal, action }: props) => {
  const history = useHistory();

  const [activeModal, setActiveModal] = useState(false);
  const [fullname, setFullname] = useState("");

  useEffect(() => {
    setActiveModal(showModal);
  }, [showModal]);

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosConfig.get("/Account/GetInfoUser");
        const { firstName, lastName } = response.data.data.user;
        setFullname(`${firstName} ${lastName}`);
      } catch (error) {
        console.error(error);
        setActiveModal(false);
      }
    })();
  }, []);

  const handleModalBehavior = () => {
    setActiveModal(!activeModal);
  };

  const modalAction = () => {
    handleModalBehavior();
    action();
  };

  return (
    <div className="warning-modal">
      <Modal isOpen={activeModal} portalClassName="warning-modal">
        <div className="close-button" onClick={handleModalBehavior}>
          <MdClose onClick={handleModalBehavior} />
        </div>
        <IoWarningOutline size={30} />
        <div className="warning-title mb-4">¡ADVERTENCIA!</div>
        <p className="mb-5">
          El nombre y apellido: <span className="fullname">"{fullname}"</span>{" "}
          que tienes actualmente en la configuración de tu perfil son los que
          aparecerán en tu diploma, por favor verifica que sean los correctos
          antes de continuar.{" "}
        </ p>

        <div className="calls-to-action">
          <ButtonPersonalized
            label={"Continuar solicitud"}
            onClick={modalAction}
            margin={"0"}
          />
          <ButtonLogoDefault
            label={"Actualizar perfil"}
            handleClick={() => history.push("/profile")}
            buttonHeight={"100%"}
          />
        </div>
      </Modal>
    </div>
  );
};
