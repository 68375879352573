import { useHistory } from "react-router-dom";
import { config } from "../../../config";
import { NavData } from "./navbar-data";
import "./navbar-mobile.styles.scss";

const NavbarMobile = () => {
    const iconUrl = config.url.images.homeIcons;
    let history = useHistory();

    return (
        <div className="main-navbar-mobile">
            {NavData?.map((item, i) => {
              return(
                <div className="bottom-nav-icon" key={i} onClick={() => history.push(item.url)}>
                    <img src={`${iconUrl}${item.icon}`} alt="" />
                </div>
              )  
            })}
        </div>
    );
};

export default NavbarMobile;