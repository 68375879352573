import { useState } from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import { MdClose } from 'react-icons/md';
import CardSearchInput from '../card-search-input/card-search-input.component';
import Card from '../mentortalks/card/card.component';

import './search-input.styles.scss';

interface props {
    iWidth: number,
    icon?: boolean,
    handleChange?: () => void,
    listContent: any,
    handlePurchase: (powerId: string) => void,
    typeContent?: string;
}

const SearchInput = ({iWidth = 300, icon = true, handleChange, listContent, handlePurchase, typeContent="powerclass"}: props) => {

    const [results, setResults] = useState('');

    const handleChanges = (value: string) => {
        setResults(value);
    }

    return (
        <div className="main-input-container mentor-searchbox" style={{height: 40, width: iWidth}}>
            <div className="input-area">
                <div className="search-icon" style={{display: icon ? 'flex' : 'none'}}>
                    <AiOutlineSearch />
                </div>
                <input placeholder='¿Qué quieres ver hoy?' type="input" className="search-area ml-2" maxLength={60} value={results} onChange={(e) => handleChanges(e.target.value)} />
                <MdClose className="close-icon" onClick={() => handleChanges('')} style={{display: results!=''? 'flex' : 'none'}} />
            </div>
            <div style={{ display: `${results ? 'flex' : 'none'}` }} className="dark-click-away" onClick={() => handleChanges('')} />
            <div className="results-container" style={{display: results ? 'flex' : 'none' }}>
                <div className="results-title mb-3">
                    <p>Resultados...</p>
                </div>
                <div className="results-visualize-container">
                    <div className="data-filtered my-3">
                        {listContent.filter((content: any) => 
                            content.master.toLowerCase().includes(results.toLowerCase())
                        ).map((data: any, i: number) => {
                            return (
                                <div key={i} className="col-generated mb-3">
                                    {
                                        typeContent === "powerclass" 
                                            ? <CardSearchInput category={data}></CardSearchInput>
                                            : <Card width={"160px"} mcategory={data}></Card>
                                    }
                                </div>
                            )
                        })} 
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SearchInput;