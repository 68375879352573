import { useState } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import "./basic-popup.styles.scss";

interface IModal {
  modalData: { type: string, title: string; description: string };
  onClick: (active: boolean) => void;
  showModal?: boolean;
  title?: string;
}

const BasicPopUp = ({ modalData, onClick, showModal = false }: IModal) => {
  const [visible, setVisible] = useState<boolean>();

  const handleExit = () => {
    onClick(!visible);
    setVisible(!visible);
  };

  return (
    <div className="dark-layer">
      <Modal isOpen={showModal} className={"basic-modal-container"}>
        <div className={`basic-child-container ${modalData.type}-container`}>
          <div className="close-button-section">
            <span className={`close-icon ${modalData.type}`} onClick={handleExit}>
              <MdClose />
            </span>
          </div>
          <div className="main-title">
            <h1 className={modalData.type}>{modalData.title}</h1>
          </div>
          <div className="main-description">
            <p className={modalData.type}>{modalData.description}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BasicPopUp;
