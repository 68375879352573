import { useState } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import NotificationContainer from "../notification-container/notification-container.component";
import "./notification.styles.scss";

type props = {
  value: Boolean;
  path: string;
  switchGlobalState: Function;
};

const Notification = ({ value, path, switchGlobalState }: props) => {
  const [newNotification, setNewNotification] = useState(false);
  const [notificationAmount, setNotificationAmount] = useState<number>();

  const handleClick = () => {
    switchGlobalState();
  };

  const showNewNot = (show: boolean) => {
    setNewNotification(show);
  };

  return (
    <div className="parent-container">
      <div className="child-container">
        <div className="icons-section">
          <IoNotificationsOutline
            className="notification-icon"
            onClick={handleClick}
            size={22}
            style={{ color: path === "/profile" ? "black" : "" }}
          />
          <div
            className="notification-amount"
            style={{ display: newNotification ? "flex" : "none" }}
          >
            {notificationAmount && notificationAmount}
          </div>
        </div>
      </div>
      <div className="description-content">
        <div
          className="click-away"
          onClick={handleClick}
          style={{ display: value ? "block" : "none" }}
        ></div>
        <div
          className="is-a-icon"
          style={{ opacity: value ? "1" : "0" }}
          onClick={handleClick}
        >
          {" "}
        </div>
        <NotificationContainer
          allowShowComp={value}
          showNewNot={showNewNot}
          notAmount={setNotificationAmount}
        />
      </div>
    </div>
  );
};

export default Notification;
