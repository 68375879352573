import { BrandLogo } from '../../../../shared/components/brand/brand.component';
import LoginCredentials from '../login-credentials/login-credentials-section.component';
import '../../../../shared/styles/essentials.css';
import './login.styles.scss';

const Login = () => {
    return ( 
        <div>
            <div className="is-back-layer hero is-fullheight has-background-image center"> </div>
            <div className="is-front-layer hero is-fullheight login-component">
                <div className="is-brand-section is-hidden-mobile"> 
                    <BrandLogo />
                </div>
                <LoginCredentials />
            </div>
        </div>
    );
};

export default Login;