import { useContext } from "react";
import { AppContext, InfoCareer, LoginInfo } from "../context/context";


const useCareer = () => {
    const appContext = useContext(AppContext);
    const careerData = appContext.loginInfo.infoCareers;
    const currentCareerId = careerData.careerId;
    const currentCareerData = careerData.careerList.find((career: InfoCareer) => career.careerId === currentCareerId);

    return currentCareerData;
}

const useCurrentCareerId = () => {
    const appContext = useContext(AppContext);
    const careerId = appContext.loginInfo.infoCareers.careerId;

    return careerId;
}

export { useCareer, useCurrentCareerId };