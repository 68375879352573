import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BeMasterLoader from "../../assets/gifs/loader/bemaster_loader.gif";
import { AppContext } from "../../context/context";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import "./card.styles.scss";
interface CardProps {
  cardState: any;
}

export const Card = ({ cardState }: CardProps) => {
  const appContext = useContext(AppContext);
  const typeFormUrls = appContext.loginInfo.initialForm;
  const [hasBemasterPass, setHasBemasterPass] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>(true);
  const mobileScreen = useMobileScreen();
  let loginInfo = appContext.loginInfo;
  let history = useHistory();

  useEffect(() => {
    const existsCareer = cardState.find((career:any) => career.idCareer === 10)
    setHasBemasterPass(existsCareer !== undefined);
  }, [cardState]);

  function handleClick(item: any, i: number) {

    if (item.career.url !== undefined) {
      window.open(item.career.url, "_blank");
      return;
    }

    if (
      item.career.enabled === 1 &&
      item.idCareer !== 3 &&
      item.idCareer !== 7 &&
      item.idCareer !== 10 &&
      item.idCareer !== 11 &&
      item.idCareer !== 12
    ) {
      localStorage.setItem("theme", "light");
      document.documentElement.className = "";
      document.documentElement.classList.add(`theme-light`);
      loginInfo.infoCareers = {
        careerList: cardState,
        selectedCareer: i,
        careerId: item.idCareer,
      };
      appContext.dispatch(loginInfo);
      history.push("/home");
    } else if (item.idCareer === 3) {
      localStorage.setItem("theme", "emm");
      document.documentElement.className = "";
      document.documentElement.classList.add(`theme-emm`);
      loginInfo.infoCareers = {
        careerList: cardState,
        selectedCareer: i,
        careerId: item.idCareer,
      };
      appContext.dispatch(loginInfo);
      history.push("/career");
    } else if (item.idCareer === 7) {
      window.open(
        "https://mastertools.com/lite?utm_source=app&utm_medium=bemaster_app&utm_campaign=mis_carreras",
        "_blank"
      );
    } else if (item.idCareer === 10) {
      localStorage.setItem("theme", "prime");
      document.documentElement.className = "";
      document.documentElement.classList.add(`theme-prime`);
      loginInfo.infoCareers = {
        careerList: cardState,
        selectedCareer: i,
        careerId: item.idCareer,
      };
      appContext.dispatch(loginInfo);
      history.push("/powerclass");
    } else if (item.idCareer === 11 || item.idCareer === 12) {
      localStorage.setItem("theme", "prime");
      document.documentElement.className = "";
      document.documentElement.classList.add(`theme-prime`);

      loginInfo.infoCareers = {
        careerList: cardState,
        selectedCareer: i,
        careerId: item.idCareer,
      };
      appContext.dispatch(loginInfo);

      if (hasBemasterPass) {
        history.push("/home");
      } else {
        history.push("/classroom");
      }
      
    }
  }

  return (
    <div className="card-main-container">
      {cardState.map((item: any, index: number) => {
        loader && index === cardState.length - 1 && setLoader(false);
        let urlImg = `https://cdn.bemaster.com/media/images/careers/Imagencarreras-${item.career.idCareer}${(item.career.idCareer === 4 && item.career.url) ? 'Temp' : ''}.png`
        let urlSmImg = `https://cdn.bemaster.com/media/images/careers/Imagencarreras-sm-${item.career.idCareer}${(item.career.idCareer === 4 && item.career.url) ? 'Temp' : ''}.png`

        return (
          <div
            key={index}
            className={`card-container career-card is-mobile`}
            onClick={() => handleClick(item, index)}
            style={{
              backgroundImage: `url(${mobileScreen ? urlSmImg : urlImg})`,
            }}
          ></div>
        );
      })}
      <div
        className="has-gif-loader"
        style={{ display: loader ? "grid" : "none" }}
      >
        <img src={BeMasterLoader} alt="BeMasterLoader" />
      </div>
    </div>
  );
};
