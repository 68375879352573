import { useContext, useEffect, useState } from 'react';
import { VscTools } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import AxiosConfig from '../../axios/axiosConfig';
import { config } from '../../config';
import { AppContext } from '../../context/context';
import { ToolCategory } from '../../interfaces/toolCategory';
import ButtonPersonalized from '../../shared/components/buttons/button-personalized/button-personalized.component';

import './tools.styles.scss';

const Tools = () => {

    const appContext = useContext(AppContext);
    const infoCareer: any = appContext.loginInfo.infoCareers;
    const selectedCareer = infoCareer.selectedCareer;
    const career = infoCareer.careerList[selectedCareer];
    const [toolCatData, setToolCatData] = useState<ToolCategory[]>([]);
    const idCareer = career.idCareer;
    const history = useHistory();

    useEffect(() => {
        let urlEndpoint = `/Tool/GetToolCategory`;
        const getToolsData = async () => {
            const response = await AxiosConfig.post(urlEndpoint, {
                idCareer: idCareer
            });

            if (response.data.data.listToolCategory.length > 0) {
                let toolDataResponse = (response.data.data.listToolCategory)
                    .sort((a: ToolCategory, b: ToolCategory) => a.orderToolCategory - b.orderToolCategory);

                setToolCatData(toolDataResponse);
            } else{
                history.push('/home');
            }
        }

        getToolsData();
    }, [history, idCareer]);

    const Redirect = (i: number) => {
        history.push(`/lista-herramientas/${toolCatData[i].idToolCategory}`);
    }

    return (
        <div className="tools-container">
            <div className="tools-header">
                <VscTools />
                <h1>HERRAMIENTAS</h1>
            </div>
            <Scrollbar>
                <div className="tools-list">
                    {toolCatData.map((tool, i: number) => {
                        return (
                            <div key={i} className="tool-card" onClick={() => Redirect(i)}
                            style={{ backgroundImage: `url(${config.url.images.tools}Tools${tool.idToolCategory}.PNG)` }}>
                                <div className="bottom-area">
                                    <h1>{(tool.name).toUpperCase()}</h1>
                                    <ButtonPersonalized height={16} label={'UTILIZAR'} fontSize={10} width={70} onClick={() => Redirect(i)} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Scrollbar>
        </div>
    );
};

export default Tools;