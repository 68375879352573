import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AxiosConfig from "../../axios/axiosConfig";
import { AppContext } from "../../context/context";
import ButtonPersonalized from "../../shared/components/buttons/button-personalized/button-personalized.component";
import InputBox from "../../shared/components/input-box/input-box.component";

import { InputPassword } from "../../shared/components/input-password/input-password.component";
import "./password-assignment.styles.scss";

type props = {
  showEmail: boolean;
};

const PasswordAssignment = ({ showEmail }: props) => {
  const SITE_KEY = "6LfrIQQaAAAAAMk-s1eb8LciigoUIYM9PPs9xILm";
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");

  const [message, setMessage] = useState("Las contraseñas no coinciden");
  const [passValidMessage, setPassValidMessage] = useState(
    "La contraseña es necesaria"
  );
  const [confValidMessage, setConfValidMessage] = useState(
    "La confirmación es necesaria"
  );
  const [invalidPass, setInvalidPass] = useState<boolean>(false);
  const [invalidConf, setInvalidConf] = useState<boolean>(false);
  const [isCredInvalid, setIsCredInvalid] = useState<boolean>(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  //Google reCaptcha
  useEffect(() => {
    const loadScriptByURL = (id: string, url: string, callback: Function) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {}
    );
  }, []);

  const handleClick = () => {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    const expNum = /[0-9]/;
    const expMin = /[a-z]/;
    const expMay = /[A-Z]/;
    let isEmailEmpty = email.trim() == "";
    let isEmailInvalid = !expression.test(String(email).toLowerCase());

    let isPassEmpty = password.trim() == "";
    let isConfEmpty = confPassword.trim() == "";

    setInvalidPass(isPassEmpty);
    setInvalidConf(isConfEmpty);
    setIsEmailEmpty(isEmailEmpty);
    setIsEmailInvalid(isEmailInvalid);

    if (
      (!isPassEmpty && !isConfEmpty && !showEmail) ||
      (showEmail &&
        !isPassEmpty &&
        !isConfEmpty &&
        !isEmailEmpty &&
        !isEmailInvalid)
    ) {
      setInvalidPass(false);
      setInvalidConf(false);
      setIsEmailEmpty(false);
      setIsEmailInvalid(false);

      if (password.length < 8) {
        setIsCredInvalid(true);
        setMessage("La contraseña debe tener mínimo 8 caractares");
      } else if (password != confPassword) {
        setIsCredInvalid(true);
        setMessage("Las contraseñas no coinciden");
      } else if (!expNum.test(password)) {
        setIsCredInvalid(true);
        setMessage("La contraseña debe contener al menos un número");
      } else if (!expMin.test(password)) {
        setIsCredInvalid(true);
        setMessage("La contraseña debe contener al menos una letra minúscula");
      } else if (!expMay.test(password)) {
        setIsCredInvalid(true);
        setMessage("La contraseña debe contener al menos una letra mayúscula");
      } else {
        setIsCredInvalid(false);
        setDisableButton(true);

        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(SITE_KEY, { action: "submit" })
            .then((token: any) => {
              if (!showEmail) {
                AxiosConfig.put("/Account/CreatePassword", {
                  newPassword: password,
                  token: token,
                })
                  .then((response) => {
                    if (response.data.result == "success") {
                      setDisableButton(false);
                      history.push("/");
                    } else if (response.data.result == "invalidRecaptcha") {
                      setDisableButton(true);
                    } else if (response.data.result == "error") {
                      setDisableButton(false);
                      setIsCredInvalid(true);
                      setMessage(response.data.message);
                    }
                  })
                  .catch((e) => {
                    setDisableButton(false);
                  });
              } else {
                AxiosConfig.put("/Account/Activate", {
                  email: email,
                  newPassword: password,
                  token: token,
                })
                  .then((response) => {
                    if (response.data.result == "success") {
                      setDisableButton(false);
                      history.push("/");
                    } else if (response.data.result == "invalidRecaptcha") {
                      setDisableButton(true);
                    } else if (response.data.result == "error") {
                      setDisableButton(false);
                      setIsCredInvalid(true);
                      setMessage(response.data.message);
                    }
                  })
                  .catch((e) => {
                    setDisableButton(false);
                  });
              }
            });
        });
      }
    }
  };

  return (
    <div className="is-login-sect recovery">
      <div className="is-title-section mb-3">
        <p className="has-text-white has-text-weight-bold is-size-4">
          {" "}
          {showEmail ? "ACTIVA TU CUENTA" : "CREA TU CONTRASEÑA"}{" "}
        </p>
      </div>
      <div className="is-separator-section mb-3">
        <span style={{ display: isCredInvalid ? "block" : "none" }}>
          <p className="wrong-pass is-size-7 has-text-weight-bold">{message}</p>
        </span>
      </div>
      <div className="is-credentials-section">
        <p
          className="is-credential-title has-text-white is-size-7 has-text-weight-bold"
          style={{ display: showEmail ? "block" : "none" }}
        >
          Correo electrónico
        </p>
        <InputBox
          value={email}
          type="email"
          placeHolder="ejemplo@correo.com"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
          styles={{ display: showEmail ? "block" : "none" }}
        />

        <span
          className="mb-2"
          style={{
            display:
              showEmail && isEmailInvalid && !isEmailEmpty ? "block" : "none",
          }}
        >
          <p className="email-required is-size-7 has-text-weight-bold">
            Correo inválido
          </p>
        </span>

        <span
          className="mb-2"
          style={{ display: showEmail && isEmailEmpty ? "block" : "none" }}
        >
          <p className="email-required is-size-7 has-text-weight-bold">
            El correo es necesario
          </p>
        </span>

        <p className="is-credential-title has-text-white is-size-7 has-text-weight-bold">
          Contraseña
        </p>

        <InputPassword
          placeholder="••••••••"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
        />
        <span
          className="mb-2"
          style={{ display: invalidPass ? "block" : "none" }}
        >
          <p className="wrong-pass is-size-7 has-text-weight-bold">
            {passValidMessage}
          </p>
        </span>
        <p className="is-credential-title has-text-white is-size-7 has-text-weight-bold mt-2">
          Confirmar contraseña
        </p>

        <InputPassword
          placeholder="••••••••"
          value={confPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setConfPassword(e.target.value);
          }}
        />
        <span
          className="mb-2"
          style={{ display: invalidConf ? "block" : "none" }}
        >
          <p className="wrong-pass is-size-7 has-text-weight-bold">
            {confValidMessage}
          </p>
        </span>

        <div className="is-custom-button mt-3">
          <ButtonPersonalized
            label={showEmail ? "ACTIVAR CUENTA" : "CREAR CONTRASEÑA"}
            disabled={disableButton}
            onClick={() => handleClick()}
          />
        </div>

        <div className="has-account">
          <p>
            ¿Ya tienes una cuenta?<span onClick={() => history.push("/login")}>Inicia sesión aquí</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordAssignment;
