export const GlobalStyles = {
    colors: {
        brown: '#C19656',
        red: '#b73f39',
        grayLighter: '#f2f2f2',
        greenPetroleum: "#2f5f55"
    }, 
    screenSize: {
        mobile: 756
    }
}

